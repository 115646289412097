import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { StyledTableCell2, TeacherBenchmarkDashboardStylesCSS } from '../../../teacherbenchmarkdashboard.styled';
import {
  StyledTableDataColumn,
  StyledTableSort3,
  tableWithNoDataStyles,
} from '../../../../../components/Common/Common.styled';
import { BenchmarkType, OrderBy, OverallPerformanceLevelSlideOutSortType } from '../../../../../generated/graphql';
import { useTeacherBenchmarksDashboardContext } from '../../../teacherbenchmarks-dashboard-hook';
import { ArrowDownIcon } from '../../../../../components/Icons';
import WavingLadyFrame from '../../../../../components/Icons/WavingLadyFrame';
import NoResults from '../../../../../components/NoResults';
import TruncateStudentName from '../../../../AdminBenchmarks/List/StudentListSlideout/TruncateStudentName';
import { SxProps } from '@mui/system';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getColorsForScore } from '../../../../../theme/palette';

type ListProps = {
  order: OrderBy;
  overallPerformanceLevelSlideOutDataLoading: boolean;
  slideOutData: any;
  sort: OverallPerformanceLevelSlideOutSortType;
  handleSort: Function;
  benchmarkType: BenchmarkType | undefined;
  page: number;
  setPage: Function;
  totalCount: number;
};
const List = ({
  overallPerformanceLevelSlideOutDataLoading,
  slideOutData,
  order,
  sort,
  handleSort,
  benchmarkType,
  page,
  setPage,
  totalCount,
}: ListProps) => {
  const theme = useTheme();
  const styles = TeacherBenchmarkDashboardStylesCSS(theme);
  const { selectedSchools, selectedClasses, selectedGrades, setLocalTeacherBenchmarkDashboardFilters } =
    useTeacherBenchmarksDashboardContext();
  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';
  return (
    <TableContainer sx={{ border: 'none', height: '70vh', paddingTop: '8px' }}>
      <Table
        stickyHeader
        aria-label="overall performance scores"
        sx={{
          ...((overallPerformanceLevelSlideOutDataLoading || !slideOutData?.length) && tableWithNoDataStyles.noData),
        }}
      >
        {!overallPerformanceLevelSlideOutDataLoading && slideOutData?.length && (
          <>
            <TableHead>
              <TableRow>
                <StyledTableCell2
                  sx={{
                    ...styles.width15,
                    ...styles.drawerTableColumn,
                    textAlign: 'left',
                    fontSize: '14px',
                    color: `${theme.palette.customBlack.fontBlack}!important`,
                  }}
                >
                  <StyledTableSort3
                    direction={orderByIcon}
                    onClick={handleSort(OverallPerformanceLevelSlideOutSortType.StudentFullName)}
                    active={sort === OverallPerformanceLevelSlideOutSortType.StudentFullName}
                    IconComponent={ArrowDownIcon}
                  >
                    Name
                  </StyledTableSort3>
                </StyledTableCell2>
                <StyledTableCell2
                  sx={{
                    ...styles.width10,
                    ...styles.drawerTableColumn,
                    textAlign: 'center',
                    fontSize: '14px',
                    color: `${theme.palette.customBlack.fontBlack}!important`,
                  }}
                >
                  <StyledTableSort3
                    direction={orderByIcon}
                    onClick={handleSort(OverallPerformanceLevelSlideOutSortType.StudentAverageScore)}
                    active={sort === OverallPerformanceLevelSlideOutSortType.StudentAverageScore}
                    IconComponent={ArrowDownIcon}
                  >
                    Score
                  </StyledTableSort3>
                </StyledTableCell2>
              </TableRow>
            </TableHead>
            <TableBody>
              <InfiniteScroll
                height={650}
                style={{ width: '131%' }}
                next={() => setPage(page + 1)}
                hasMore={totalCount > slideOutData?.length}
                loader={null}
                dataLength={totalCount}
              >
                {slideOutData?.map((student: any, rowIndex: number) => (
                  <TableRow
                    key={rowIndex}
                    sx={{
                      ...styles.tableRowHover,
                      ...styles.tableRowBorder,
                    }}
                  >
                    <TableCell sx={{ ...styles.width35, textAlign: 'left', cursor: 'default' }}>
                      <Link
                        to={`/teacher/student-dashboard/${student.student_user_id}?benchmarkType=${benchmarkType}&linkPage=benchmarksummary`}
                        style={{ textDecoration: 'none' }}
                        onClick={() => {
                          setLocalTeacherBenchmarkDashboardFilters?.({
                            schools: selectedSchools,
                            grades: selectedGrades,
                            classes: selectedClasses,
                            benchmarkType,
                          });
                        }}
                      >
                        <TruncateStudentName
                          student={{ first_name: student.student_first_name, last_name: student.student_last_name }}
                          maxChars={20}
                          typographySx={{
                            fontSize: '18px',
                            fontWeight: 800,
                            color: theme.palette.customBlue.primaryBlue,
                          }}
                        />
                      </Link>
                    </TableCell>
                    <StyledTableDataColumn sx={{ ...styles.width15, textAlign: 'center', fontSize: '18px' }}>
                      <Typography
                        component="span"
                        sx={{ ...styles.tag, ...getColorsForScore?.(student?.student_average_score) } as SxProps<any>}
                      >
                        {student?.student_average_score}
                      </Typography>
                    </StyledTableDataColumn>
                  </TableRow>
                ))}
              </InfiniteScroll>
            </TableBody>
          </>
        )}
        {(!!slideOutData || slideOutData === null) &&
          !slideOutData?.length &&
          !overallPerformanceLevelSlideOutDataLoading && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <NoResults
                    label="No Students"
                    description="No students at this performance level"
                    svgComponent={<WavingLadyFrame />}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        {}
        {overallPerformanceLevelSlideOutDataLoading && (
          <TableBody>
            <TableRow>
              <TableCell colSpan={6} align="center">
                <CircularProgress size={28} />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};
export default List;
