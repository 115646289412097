import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette } from '../../theme/palette';

const ChevronDownIcon = ({ fillColor, ...props }: any) => {
  if (!fillColor || fillColor === '') {
    fillColor = palette.customBlue.primaryBlue;
  }
  return (
    <SvgIcon width="18" height="12" viewBox="0 0 18 12" fill="none" {...props}>
      <svg width="18" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.792893 0.292893C1.18342 -0.097631 1.81658 -0.097631 2.20711 0.292893L5.5 3.58579L8.79289 0.292893C9.18342 -0.0976311 9.81658 -0.0976311 10.2071 0.292893C10.5976 0.683417 10.5976 1.31658 10.2071 1.70711L6.20711 5.70711C5.81658 6.09763 5.18342 6.09763 4.79289 5.70711L0.792893 1.70711C0.402369 1.31658 0.402369 0.683418 0.792893 0.292893Z"
          fill={fillColor}
        />
      </svg>
    </SvgIcon>
  );
};

export default ChevronDownIcon;
