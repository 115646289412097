import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette } from '../../theme/palette';

const VocabularyIcon = React.memo(({ width = 49, height = 56, ...props }: any) => (
  <SvgIcon fontSize="small" width={width} height={height} viewBox="0 0 49 56" fill="none" {...props}>
    <g>
      <path
        d="M38.8702 17.266H20.741C20.0287 17.266 19.446 16.6833 19.446 15.971V15.1077C19.446 14.3955 20.0287 13.8128 20.741 13.8128H38.8702C39.5825 13.8128 40.1652 14.3955 40.1652 15.1077V15.971C40.1652 16.6833 39.5825 17.266 38.8702 17.266ZM40.1652 22.8774V22.0141C40.1652 21.3019 39.5825 20.7192 38.8702 20.7192H20.741C20.0287 20.7192 19.446 21.3019 19.446 22.0141V22.8774C19.446 23.5896 20.0287 24.1724 20.741 24.1724H38.8702C39.5825 24.1724 40.1652 23.5896 40.1652 22.8774ZM47.1471 51.7979H47.5032C48.2155 51.7979 48.7982 52.3807 48.7982 53.0929V53.9562C48.7982 54.6684 48.2155 55.2511 47.5032 55.2511H9.08642C4.31669 55.2511 0.45343 51.3879 0.45343 46.6182V8.63299C0.45343 3.86326 4.31669 0 9.08642 0H46.2083C47.6435 0 48.7982 1.15466 48.7982 2.5899V42.3017C48.7982 43.3808 48.1291 44.3088 47.1903 44.6973C46.8018 46.4347 46.7155 49.6181 47.1471 51.7979ZM14.2662 41.4384H45.345V3.4532H14.2662V41.4384ZM3.90663 43.165C5.35265 42.0858 7.144 41.4384 9.08642 41.4384H10.813V3.4532H9.08642C6.22674 3.4532 3.90663 5.77331 3.90663 8.63299V43.165ZM44.0824 51.7979C43.7479 49.5965 43.7695 46.9203 44.0824 44.8916H9.08642C2.18003 44.8916 2.18003 51.7979 9.08642 51.7979H44.0824Z"
        fill={palette.fontColors.fontBlack}
      />
    </g>
  </SvgIcon>
));

export default VocabularyIcon;
