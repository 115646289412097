import { Box, SxProps, Tooltip, Typography } from '@mui/material';
import React from 'react';
import BenchmarkIcon from '../../components/Icons/BenchmarkIcon';
import { palette } from '../../theme/palette';
import { toReadableFormat } from '../../utils/dateFormat';
import {
  boxStyles,
  capIconStyles,
  StyledFlexBox,
  StyledIconContainer,
  StyledImage,
  StyledImageOverlayBox,
  StyledTaskButton,
  StyledTypography,
} from './StudentDashboard.styled';
import { READABLE_DATE_OPTIONS_YEAR, MAX_CHARS_ASSIGNMENTTITLE } from '../../variables/constant';
import { truncateString } from '../../utils/truncateString';
import assignedToItems from '../AdminBenchmarks/Edit/AssignedToItems';

interface Props {
  assignmentId: string;
  resourceUrl: string;
  teacherUserId: string;
  submissionId: string;
  assignmentTitle: string;
  closeAt: string;
  teacherIDColorMap: Record<string, string>;
  handleRedirection: (id: string, submissionId?: string | undefined, isBenchmark?: boolean) => () => Promise<void>;
  isBenchmark?: boolean;
  teachers?: string;
  thumbnailUrl: string;
}

const DashboardAssignmentBenchmarkTile = ({
  assignmentId,
  resourceUrl,
  teacherUserId,
  submissionId,
  assignmentTitle,
  closeAt,
  teacherIDColorMap,
  handleRedirection,
  isBenchmark = false,
  teachers,
  thumbnailUrl = '',
}: Props) => {
  return (
    <Box sx={{ ...boxStyles.assignmentContainer, p: '0px' }} key={assignmentId}>
      <Box position="relative" width="100%" height="75%">
        <StyledImage
          src={thumbnailUrl ? thumbnailUrl : resourceUrl}
          alt="task"
          sx={{ borderRadius: '12px 12px 0 0' }}
        />
        <StyledImageOverlayBox style={{ backgroundColor: teacherIDColorMap[teacherUserId || 'new'] }}>
          {isBenchmark && (
            <StyledIconContainer sx={{ ...capIconStyles.iconWrapper } as SxProps}>
              <Box
                sx={{
                  display: 'flex',
                  direction: 'column',
                  justifyContent: 'left',
                  marginLeft: '16px',
                  alignItems: 'center',
                }}
              >
                <Box>
                  <BenchmarkIcon pathfill={palette.customWhite.main} sx={{ ...capIconStyles.iconDimensions }} />
                </Box>
                <Box>
                  <StyledTypography
                    sx={{
                      fontWeight: 800,
                    }}
                  >
                    Wait! This is a Benchmark Test.
                  </StyledTypography>
                  <StyledTypography
                    sx={{
                      fontWeight: 400,
                    }}
                  >
                    Please check with your teacher before starting.
                  </StyledTypography>
                </Box>
              </Box>
            </StyledIconContainer>
          )}
          <StyledTaskButton
            variant="contained"
            color="primary"
            onClick={handleRedirection(assignmentId, submissionId ?? undefined, isBenchmark)}
            aria-label={`${submissionId ? 'Resume' : 'Start'} ${assignmentTitle}`}
          >
            {submissionId ? 'Resume' : 'Start'}
          </StyledTaskButton>
        </StyledImageOverlayBox>
      </Box>
      <StyledFlexBox>
        {assignmentTitle.length > MAX_CHARS_ASSIGNMENTTITLE ? (
          <Tooltip title={assignmentTitle} placement="top">
            <Typography variant="sContentHeader">
              {truncateString(assignmentTitle, MAX_CHARS_ASSIGNMENTTITLE)}
            </Typography>
          </Tooltip>
        ) : (
          <Typography variant="sContentHeader"> {assignmentTitle}</Typography>
        )}

        {!isBenchmark && (
          <Typography variant="secondaryLabel" sx={{ color: palette.fontColors.fontBlack }}>
            {`${teachers}`}
          </Typography>
        )}
        <Typography variant="secondaryLabel" sx={{ color: palette.fontColors.fontBlack }}>
          {closeAt ? `Due by ${toReadableFormat(closeAt, READABLE_DATE_OPTIONS_YEAR)}` : ''}
        </Typography>
      </StyledFlexBox>
    </Box>
  );
};

export default DashboardAssignmentBenchmarkTile;
