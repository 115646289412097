import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import useStudent from './student-hook';
import Loader from '../../../components/Loader/loader';
import PencilIcon from '../../../components/Icons/PencilIcon';
import StyledBoxWrapper from '../../../components/StyledCard/StyledCard.styled';
import ResourceDetailHeader from '../../../components/ResourceDetailHeader';
import ResourceDetail from '../../Districts/District/resource-detail';
import SchoolsIcon from '../../../components/Icons/SchoolsIcon';
import MessageIcon from '../../../components/Icons/MessageIcon';
import ordinal from '../../../utils/ordinal';
import { StudentsIcon } from '../../../components/Icons';
import ConversationIcon from '../../../components/Icons/ConversationIcon';
import SignalIcon from '../../../components/Icons/SignalIcon';
import { MY_SCHOOL, PROVIDER_TYPE } from '../../../variables/constant';
import { Entity, ImitatationUserType } from '../../../variables/types';
import { palette } from '../../../theme/palette';
import AddNewEntity from '../../../components/AddNewEntity';
import FilledInfoIcon from '../../../components/Icons/FilledInfoIcon';
import ListWithTabs from '../../InfoTables';
import { studentsRoute } from '../../../variables/staticRoutes';
import AppBreadcrumbs from '../../../components/AppBreadcrumbs';
import {useEvaluateFlagQuery} from "../../../generated/graphql";
import ClipBoardCheckIcon from "../../../components/Icons/ClipBoardCheckIcon";


const styles = {
  link: {
    fontWeight: 700,
    color: palette.customBlue.primaryBlue,
    textDecoration: 'none',
    marginLeft: '4px',
  },
};

const Student = () => {
  const {
    student,
    loading,
    editStudentPermission,
    isSchoolAdmin, studentID,
    imitateUserPermission,
    handleImitationLogin,
  } = useStudent({});
  const breadcrumb = [
    studentsRoute,
    {
      label: `${student?.first_name ?? ''} ${student?.last_name ?? ''}`,
      route: `${studentsRoute.route}/${student?.id}`,
    }];

  const studentsDistrictIdValDefault = false;
  const { data: studentsDistrictIdVal } = useEvaluateFlagQuery({
    fetchPolicy: 'network-only',
    variables: { input: { defaultValue: studentsDistrictIdValDefault, flagKey: 'students-district-id-ui' } },
  });
  const showStudentsDistrictId =
    studentsDistrictIdVal?.evaluateFlag.on ?? studentsDistrictIdValDefault;

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <AppBreadcrumbs  breadcrumb={loading ? [] : breadcrumb} />
      <ResourceDetailHeader
        title={`${student?.first_name ? student.first_name : ''} ${student?.last_name ? student.last_name : ''}`}
        addNewEntity={editStudentPermission ? (
          <AddNewEntity
            entity={Entity.Student}
            districtId={student?.district_id ?? undefined}
            stateId={student?.state_id ?? undefined}
            schoolId={student?.school_id ?? undefined}
          />
        ) : undefined}
        primaryBtnRedirectionLink={(student?.source === PROVIDER_TYPE.CLEVER || student?.source === PROVIDER_TYPE.CLASSLINK) || !editStudentPermission ? undefined : `/admin/create/student/${student?.id}`}
        PrimaryBtnIcon={(student?.source === PROVIDER_TYPE.CLEVER || student?.source === PROVIDER_TYPE.CLASSLINK) ? undefined : PencilIcon}
        primaryBtnLabel={(student?.source === PROVIDER_TYPE.CLEVER || student?.source === PROVIDER_TYPE.CLASSLINK) ? undefined : 'Edit'}
        secondaryBtnLabel={imitateUserPermission ? 'Log In As' : undefined}
        secondaryBtnOnClick={() => handleImitationLogin(
          student?.user_id!, ImitatationUserType.Student, student?.id!,
        )}
        SecondaryBtnIcon={StudentsIcon}
      />
      <Box my={2}>
        <StyledBoxWrapper width="30%">
          <ResourceDetail
            label={(
              <Box display="flex">
                {student?.grade && (
                  <Typography>
                    {`${isNaN(parseInt(student.grade ?? '', 10)) ? student.grade : ordinal(parseInt(student.grade, 10))} grader ${student?.school_name && student.grade ? 'at' : ''}`}
                  </Typography>
                )}
                <Typography
                  sx={{ ...styles.link }}
                  component={Link}
                  to={`/schools/${isSchoolAdmin ? MY_SCHOOL : student?.school_id}`}
                >
                  {student?.school_name ?? ''}
                </Typography>
              </Box>
            )}
            Icon={SchoolsIcon}
          />
          {showStudentsDistrictId? <ResourceDetail label={`Username: ${student?.student_id || '-'}`} Icon={StudentsIcon} />: <ResourceDetail label={`Student ID: ${student?.student_id || '-'}`} Icon={StudentsIcon} />}
          {showStudentsDistrictId && <ResourceDetail label={`Student's District ID: ${student?.students_district_id || '-'}`} Icon={ClipBoardCheckIcon} />}
          <ResourceDetail label={`Heritage language: ${student?.heritage_language || '-'}`} Icon={ConversationIcon} />
          {!showStudentsDistrictId && <ResourceDetail label={`Language Level: ${student?.language_level || '-'}`} Icon={SignalIcon} />}
          <ResourceDetail label={`${student?.email || '-'}`} Icon={MessageIcon} />
          <ResourceDetail label={`${student?.demographic_info || '-'}`} Icon={FilledInfoIcon} />
        </StyledBoxWrapper>
        <ListWithTabs sectionType={'admin-students'} studentID={studentID} />
      </Box>
      <Loader open={loading} />
    </Box>
  );
};

export default React.memo(Student);
