import React, { useMemo } from 'react';
import {
  Checkbox,
  FormControlLabel,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { palette } from '../../theme/palette';
import {ManageStudentsListStudentDetailsFragmentFragment, useEvaluateFlagQuery} from '../../generated/graphql';

interface Props {
  student: ManageStudentsListStudentDetailsFragmentFragment,
  onChange: (event: React.ChangeEvent<HTMLInputElement>, studentId: string) => void,
  isSelected: (studentId: string) => boolean,
}

const styles = {
  tableCell: {
    paddingLeft: '24px',
  },
  label: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: palette.fontColors.fontBlack,
  },
};

const StudentRow = ({
  student,
  onChange,
  isSelected,
}: Props) => {
  const isSelectedStudent = useMemo(() => isSelected(student?.id!), [isSelected, student]);
  const studentsDistrictIdValDefault = false;
  const { data: studentsDistrictIdVal } = useEvaluateFlagQuery({
    fetchPolicy: 'network-only',
    variables: { input: { defaultValue: studentsDistrictIdValDefault, flagKey: 'students-district-id-ui' } },
  });
  const showStudentsDistrictId =
    studentsDistrictIdVal?.evaluateFlag.on ?? studentsDistrictIdValDefault;
  return (
    <TableRow>
      <TableCell sx={{ ...styles.tableCell, width: '65%' }}>
        <FormControlLabel
          sx={{ ...styles.label }}
          control={(
            <Checkbox
              color="primary"
              checked={isSelectedStudent}
              onChange={(e) => onChange(e, student?.id!)}
            />
          )}
          label={`${student?.first_name ?? ''} ${student?.last_name ?? ''}`}
        />
      </TableCell>
      <TableCell sx={{ ...styles.tableCell, width: '5%' }}>
        <Typography variant="font16">{`${student?.grade}`}</Typography>
      </TableCell>
      <TableCell sx={{ ...styles.tableCell, width: '30%', wordBreak: 'break-word' }}>
        <Typography variant="font16">{showStudentsDistrictId? student?.students_district_id:student?.student_id}</Typography>
      </TableCell>
    </TableRow>
  );
};
export default StudentRow;
