import { useEffect, useState } from 'react';
import { AdminStudentsListSortType, OrderBy, useManageStudentsListQuery } from '../../../../../generated/graphql';
import useCreateClass from '../../create-class-hook';

const useManageStudents = () => {
  const {
    isTeacher,
    isSchoolAdmin,
    isDistrictAdmin,
    createClassInput,
    updateClassStudents,
    classDataFetched,
    setTotalTeacherStudentsCount,
    totalTeacherStudentsLoaded,
    setTotalTeacherStudentsLoaded,
  } = useCreateClass();

  const [availableStudents, setAvailableStudents] = useState<string[]>([]);
  const [selectedAvailableStudents, setSelectedAvailableStudents] = useState<string[]>([]);

  const [allocatedStudents, setAllocatedStudents] = useState<string[]>(
    createClassInput?.student_ids_to_add! as string[]
  );
  const [selectedAllocatedStudents, setSelectedAllocatedStudents] = useState<string[]>([]);

  const [allTeacherStudents, setAllTeacherStudents] = useState<any[]>([]);

  const handleAddStudents = (addStudentIds: string[]) => {
    setAllocatedStudents?.((prevArray: any) => [...prevArray, ...addStudentIds]);
    setSelectedAvailableStudents([]);
    setSelectedAllocatedStudents([]);
  };

  const [removeStudentsWarning, setRemoveStudentsWarning] = useState<boolean>(false);
  const handleRemoveStudentsWithWarning = (removeStudentIds: string[]) => {
    const removingExistingStudents = removeStudentIds.filter((studentId) =>
      classDataFetched?.student_ids?.includes(studentId)
    );
    if (removingExistingStudents.length <= 0) {
      setRemoveStudentsWarning(false);
      handleRemoveStudents(removeStudentIds);
    } else setRemoveStudentsWarning(true);
  };

  const handleRemoveStudents = (removeStudentIds: string[]) => {
    setRemoveStudentsWarning(false);
    setAllocatedStudents?.((prevArray: any) => prevArray.filter((element: any) => !removeStudentIds.includes(element)));
    setSelectedAvailableStudents([]);
    setSelectedAllocatedStudents([]);
  };

  const updateAvailableStudents = (studentsList: any[], unavailableStudents: string[]) => {
    const studentsAvailable = studentsList?.filter((student) => !unavailableStudents.includes(student.id));
    setAvailableStudents(studentsAvailable?.map((student) => student.id) ?? []);
  };

  const [studentSearch, setStudentSearch] = useState('');

  const onStudentSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event!.target.value === '') {
      setStudentSearch?.('');
    } else {
      if (event!.target.value.length > 2) {
        setStudentSearch?.(event!.target.value);
      }
    }
  };

  const [sort, setSort] = useState(AdminStudentsListSortType.Name);
  const [order, setOrder] = useState(OrderBy.Asc);
  const { data: studentListData, loading } = useManageStudentsListQuery({
    fetchPolicy: 'network-only',
    skip: !isDistrictAdmin && !isSchoolAdmin && !isTeacher,
    variables: {
      sort,
      orderBy: order as OrderBy,
      schoolId: createClassInput?.school_id,
      ...(studentSearch?.length ? { search: studentSearch } : {}),
    },
  });

  const studentsData = studentListData?.allStudentsList?.nodes ?? [];

  const onSortChange = (sortBy: AdminStudentsListSortType, orderBy: OrderBy) => {
    setSort(sortBy);
    setOrder(orderBy);
  };

  const handleSort = (column: AdminStudentsListSortType) => {
    if (column === sort) {
      onSortChange(column, order === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  useEffect(() => {
    if (!studentSearch && !loading && studentsData.length > 0) {
      setAllTeacherStudents(studentsData);
    }
  }, [!studentSearch, loading, studentsData]);

  useEffect(() => {
    if (!loading) {
      const studentsAvailable = studentsData?.filter((student) => !allocatedStudents.includes(student.id));
      setAvailableStudents(studentsAvailable?.map((student) => student.id) ?? []);
      if (studentSearch.length < 2)
        updateClassStudents?.(studentsData.filter((student) => allocatedStudents.includes(student.id)));
      if (!totalTeacherStudentsLoaded && !!studentsData) {
        setTotalTeacherStudentsCount?.(studentsData.length);
        setTotalTeacherStudentsLoaded?.(true);
      }
    }
  }, [studentsData, loading, studentSearch]);

  useEffect(() => {
    updateAvailableStudents(studentsData, allocatedStudents);
    updateClassStudents?.(allTeacherStudents.filter((student) => allocatedStudents.includes(student.id)));
  }, [allocatedStudents]);

  return {
    classTitle: createClassInput?.name,
    availableStudents,
    setAvailableStudents,
    selectedAvailableStudents,
    setSelectedAvailableStudents,
    allocatedStudents,
    setAllocatedStudents,
    selectedAllocatedStudents,
    setSelectedAllocatedStudents,
    handleAddStudents,
    handleRemoveStudents,
    loading,
    isTableEmpty: studentListData ? !studentsData?.length : true,
    students: studentsData,
    studentSearch,
    setStudentSearch,
    onStudentSearch,
    sort,
    handleSort,
    order,
    removeStudentsWarning,
    setRemoveStudentsWarning,
    handleRemoveStudentsWithWarning,
  };
};

export default useManageStudents;
