import React from 'react';
import { palette } from '../../../../theme/palette';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { getMonthNameLong } from '../../../../components/AdminGraphs/CommonFunctions';
import { LegendIcon } from '../../../../components/Icons/LegendIcon';

interface Props {
  active?: string;
  payload?: any;
}

const style = {
  dialogBox: {
    padding: 10,
    backgroundColor: palette.customWhite.main,
    border: `2px solid ${palette.customRed.progressPrimary}`,
    width: 'auto',
    height: 'auto',
    borderRadius: '8px',
    boxShadow: `0 3px 5px 1px ${palette.customBackground.disabled}`,
  },
};

const CustomTooltip = ({ active, payload }: Props) => {
  const dataVar = payload?.[0]?.payload;
  const calendarMonth = getMonthNameLong(dataVar?.month);
  const totalUnits = dataVar?.unique_student_completed_pts_count;
  const consumedUnits = dataVar?.most_recent_goals_count;
  const displayYear = dataVar?.year;

  if (active && totalUnits != null) {
    return (
      <Box className="line-chart-tooltip" style={style.dialogBox}>
        <Typography component="p" variant="toolTipGreyText" marginBottom="5px" marginLeft="10px">
          {calendarMonth} {displayYear}
        </Typography>
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <LegendIcon color={palette.customGrey.submissionGrey!} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={<Typography variant="selectPlaceholder">{totalUnits} Submissions</Typography>}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <LegendIcon color={palette.customBlue.primaryBlue!} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={<Typography variant="selectPlaceholder">{consumedUnits} Goals</Typography>}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    );
  }

  return null;
};

export default CustomTooltip;
