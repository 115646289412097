import React from 'react';
import { getSkillTypeIcon } from '../../../../components/AdminGraphs/CommonFunctions';

export const XAxisLabels = (props: any) => {
  const { x, y, index, payload } = props;
  if (typeof payload.value === 'number') return <></>;
  const [firstLine, secondLine] = payload.value ? payload.value.split(/\s+(.*)/) : [undefined, undefined];
  const smallScreenLabelYOffset = !secondLine && window.innerWidth < 1450 && (index || 0) % 2 === 1 ? 15 : 0;

  return (
    <>
      <g transform={`translate(${x - 10}, ${y + 3})`}>
        {getSkillTypeIcon(payload.value.replace(/ and /gi, ' & '), {
          width: '25px',
          height: '25px',
        })}
      </g>
      <text
        x={x}
        y={y + 27 + smallScreenLabelYOffset}
        dy={16}
        width={50}
        textAnchor="middle"
        fill="black"
        style={{
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '19.1px',
        }}
      >
        {firstLine && (
          <tspan x={x} dy="1.2em" key={firstLine}>
            {firstLine}
          </tspan>
        )}
        {secondLine && (
          <tspan x={x} dy="1.2em" key={secondLine}>
            {secondLine}
          </tspan>
        )}
      </text>
    </>
  );
};
