import { createContext, useContext, useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { getUnixTime, max, min, parseISO } from 'date-fns';
import { cleanDate, fromUTCSOD } from '../../utils/dateFormat';
import {
  AdminDashboardFilterInput,
  AdminDashboardSectionFragmentFragment,
  PredefinedGrades,
  SchoolFilterFragmentFragment,
  SubmissionType,
  TeacherFilterFragmentFragment,
  useGetAcademicSessionQuery,
  useGetLastSnowflakeUpdateTimeQuery,
  useMyDistrictQuery,
  useMySchoolQuery,
} from '../../generated/graphql';
import useRole from '../../hooks/useRole';
import { AdminDashboards } from '../../variables/constant';
import { AdminAnalyticsDateFilterOptions } from '../../variables/types';
import useLocalStorage from '../../utils/useLocalStorage';

export interface DashboardProps {
  isDistrictAdmin: boolean;
  myDistrictDataLoading: boolean;
  myDistrictData?: any;
  isSchoolAdmin: boolean;
  mySchoolDataLoading: boolean;
  mySchoolData?: any;
  academicSessionLoading: boolean;
  academicSession: any;
  lastSnowflakeUpdateTimeData?: any;
  getLastSnowflakeUpdateTimeLoading: boolean;
}

export interface AnalyticsFiltersProps {
  dateRange?: string;
  submissionType?: SubmissionType;
  schools?: SchoolFilterFragmentFragment[];
  grades?: PredefinedGrades[];
  teachers?: TeacherFilterFragmentFragment[];
  classes?: AdminDashboardSectionFragmentFragment[];
  startDate?: Date;
  endDate?: Date;
}

export interface DashboardWidgetsDataStatusProps {
  widget: string;
  loading: boolean;
  dataMissing: boolean | undefined;
}

export interface DashboardContextProps {
  loading?: boolean;
  setLoading?: Function;
  isDistrictAdmin?: boolean;
  myDistrictData?: any;
  isSchoolAdmin?: boolean;
  mySchoolData?: any;
  startDateSelected?: Date;
  setStartDateSelected?: Function;
  endDateSelected?: Date;
  setEndDateSelected?: Function;
  dashboardFilters?: AnalyticsFiltersProps;
  currentFilters?: AnalyticsFiltersProps;
  setCurrentFilters?: Function;
  analyticsFilters?: AdminDashboardFilterInput;
  setAnalyticsFilters?: Function;
  startDateInit?: Date;
  academicSession?: any;
  academicSessionLoading?: boolean;
  dashboardWidgetsDataStatus?: DashboardWidgetsDataStatusProps[];
  setDashboardWidgetsDataStatus?: Function;
  updateDashboardWidgetsDataStatus?: Function;
  checkDataMissingInAllWidgets?: Function;
  isDataMissingInAllWidgets?: boolean;
  setIsDataMissingInAllWidgets?: Function;
  areWidgetsLoading?: boolean;
  setAreWidgetsLoading?: Function;
  getLocalStorageDashboardFilters?: Function;
  setLocalStorageDashboardFilters?: Function;
  localDashboardFilters?: any;
  lastSnowflakeUpdateTimeData?: any;
  getLastSnowflakeUpdateTimeLoading?: boolean;
}

export const DashboardContext = createContext<DashboardContextProps>({});

interface DashboardContextInitProps extends DashboardProps {
  dashboardWidgets: any;
}
export const DashboardContextInit = ({
  dashboardWidgets,
  isDistrictAdmin,
  myDistrictDataLoading,
  isSchoolAdmin,
  mySchoolDataLoading,
  academicSessionLoading,
  academicSession,
  lastSnowflakeUpdateTimeData,
  getLastSnowflakeUpdateTimeLoading,
}: DashboardContextInitProps) => {
  // Set Init Date Range to Last 3 months.
  const today = new Date();
  // set start Date to start date of the acedemic
  const currMonth = today.getMonth();
  const currYear = today.getFullYear();
  const startYear = [8, 9, 10, 11, 12].includes(currMonth) ? currYear : currYear - 1;
  const startDateInit = new Date(startYear, 8, 1);
  const [loading, setLoading] = useState(false);
  const [dashboardWidgetsDataStatus, setDashboardWidgetsDataStatus] = useState<DashboardWidgetsDataStatusProps[]>(
    Object.values(dashboardWidgets).map((widgetData: any) => ({
      widget: widgetData.name,
      loading: true,
      dataMissing: false,
    }))
  );
  const [isDataMissingInAllWidgets, setIsDataMissingInAllWidgets] = useState(false);
  const [areWidgetsLoading, setAreWidgetsLoading] = useState(true);
  // check if respective dashboard filters are availabale in local storage.
  const location = useLocation();
  var dashboard = 'analytics';
  if (matchPath(location.pathname, AdminDashboards.routePaths.learningGoals)) dashboard = `${dashboard}_learningGoals`;
  else if (matchPath(location.pathname, AdminDashboards.routePaths.performance)) dashboard = `${dashboard}_performance`;
  // setup a local storage for dashboard filters if already not exist
  const localStorageInitValue = {
    dateRange: AdminAnalyticsDateFilterOptions.CustomDateRange,
    submissionType: SubmissionType.Speaking,
    schools: [],
    grades: [],
    teachers: [],
    classes: [],
    startDate: parseISO(academicSession.session_start_date) ?? startDateInit,
    endDate: today,
  };
  const {
    localStorageValue: localDashboardFilters,
    getLocalStorageValue: getLocalStorageDashboardFilters,
    setLocalStorageValue: setLocalStorageDashboardFilters,
  } = useLocalStorage({
    keyTag: `${dashboard}_filters`,
    initialValue: localStorageInitValue,
    timeoutMS: 300000,
  });
  // To ensure the dates are in Date Type
  localDashboardFilters.startDate = cleanDate(localDashboardFilters.startDate);
  localDashboardFilters.endDate = cleanDate(localDashboardFilters.endDate, true);
  // Also maintain the startDateSeleted and endDateSeleted in local storage
  const {
    localStorageValue: startDateSelected,
    // getLocalStorageValue: getStartDateSelected,
    setLocalStorageValue: setStartDateSelected,
  } = useLocalStorage({
    keyTag: `${dashboard}_startDateSelected`,
    initialValue: localDashboardFilters.startDate,
    timeoutMS: 300000,
  });
  const {
    localStorageValue: endDateSelected,
    // getLocalStorageValue: getEndDateSelected,
    setLocalStorageValue: setEndDateSelected,
  } = useLocalStorage({
    keyTag: `${dashboard}_endDateSelected`,
    initialValue: localDashboardFilters.endDate,
    timeoutMS: 300000,
  });
  // Following function extracts data complaint with analytics API payloads from complex data in localDashboardFilters
  const getAnalyticsFiltersPayloadFromLocal = (localFiltersValue: any = localDashboardFilters) =>
    localFiltersValue
      ? {
          submission_score_type: localFiltersValue.submissionType ?? SubmissionType.Speaking,
          ...(localFiltersValue.schools.length && {
            school_ids: localFiltersValue.schools?.map((school: SchoolFilterFragmentFragment) => school?.id),
          }),
          ...(localFiltersValue.grades.length && {
            grade_ids: localFiltersValue.grades?.map((grade: PredefinedGrades) => grade.id),
          }),
          ...(localFiltersValue.teachers.length && {
            teacher_ids: localFiltersValue.teachers?.map((teacher: TeacherFilterFragmentFragment) => teacher.id),
          }),
          ...(localFiltersValue.classes.length && {
            section_ids: localFiltersValue.classes?.map((tclass: AdminDashboardSectionFragmentFragment) => tclass.id),
          }),
          start_timestamp: getUnixTime(localFiltersValue.startDate ?? startDateInit),
          end_timestamp: getUnixTime(localFiltersValue.endDate ?? today),
        }
      : {};
  const [dashboardFilters, setDashboardFilters] = useState<AnalyticsFiltersProps>(localDashboardFilters);
  // ** currentFilters will save the individual filter states as a whole in context, useful to revert from un-applied user changes to them **
  const [currentFilters, setCurrentFilters] = useState<AnalyticsFiltersProps>();
  // ** analyticsFilters will track the filters that are to be input to the widget APIs as state in context
  const [analyticsFilters, setAnalyticsFilters] = useState<AdminDashboardFilterInput>(
    getAnalyticsFiltersPayloadFromLocal()
  );

  useEffect(() => {
    if (academicSession) {
      const sessionStartDate = fromUTCSOD(academicSession?.session_start_date!);
      const sessionEndDate = fromUTCSOD(academicSession?.session_end_date!);
      localDashboardFilters.startDate = max([cleanDate(localDashboardFilters.startDate), sessionStartDate]);
      localDashboardFilters.endDate = min([cleanDate(localDashboardFilters.endDate, true), sessionEndDate]);
      setAnalyticsFilters?.({
        ...getAnalyticsFiltersPayloadFromLocal(localDashboardFilters),
      });
      setDashboardFilters({
        ...localDashboardFilters,
      });
      setLocalStorageDashboardFilters?.({
        ...localDashboardFilters,
      });
    }
  }, [academicSession]);

  useEffect(() => {
    setLoading?.(
      (isDistrictAdmin && myDistrictDataLoading) ||
        (isSchoolAdmin && mySchoolDataLoading) ||
        academicSessionLoading ||
        getLastSnowflakeUpdateTimeLoading
    );
  }, [
    isDistrictAdmin,
    isSchoolAdmin,
    myDistrictDataLoading,
    mySchoolDataLoading,
    academicSessionLoading,
    getLastSnowflakeUpdateTimeLoading,
  ]);

  return {
    loading,
    setLoading,
    getLocalStorageDashboardFilters,
    setLocalStorageDashboardFilters,
    localDashboardFilters,
    dashboardFilters,
    setDashboardFilters,
    currentFilters,
    setCurrentFilters,
    analyticsFilters,
    setAnalyticsFilters,
    startDateSelected,
    setStartDateSelected,
    endDateSelected,
    setEndDateSelected,
    startDateInit,
    dashboardWidgetsDataStatus,
    setDashboardWidgetsDataStatus,
    isDataMissingInAllWidgets,
    setIsDataMissingInAllWidgets,
    areWidgetsLoading,
    setAreWidgetsLoading,
    lastSnowflakeUpdateTimeData,
  };
};

export const useDashboardContext = () => {
  const {
    loading,
    setLoading,
    isSchoolAdmin,
    mySchoolData,
    isDistrictAdmin,
    myDistrictData,
    getLocalStorageDashboardFilters,
    setLocalStorageDashboardFilters,
    localDashboardFilters,
    dashboardFilters,
    currentFilters,
    setCurrentFilters,
    analyticsFilters,
    setAnalyticsFilters,
    startDateSelected,
    setStartDateSelected,
    endDateSelected,
    setEndDateSelected,
    academicSessionLoading,
    academicSession,
    startDateInit,
    dashboardWidgetsDataStatus,
    setDashboardWidgetsDataStatus,
    isDataMissingInAllWidgets,
    setIsDataMissingInAllWidgets,
    areWidgetsLoading,
    setAreWidgetsLoading,
    lastSnowflakeUpdateTimeData,
    getLastSnowflakeUpdateTimeLoading,
  } = useContext(DashboardContext);

  const sessionStartDate = fromUTCSOD(academicSession?.session_start_date);
  const sessionEndDate = fromUTCSOD(academicSession?.session_end_date);

  const skipGraphQLRequest = () =>
    (!isSchoolAdmin && !isDistrictAdmin) ||
    (isSchoolAdmin && !mySchoolData?.id) ||
    (isDistrictAdmin && !myDistrictData?.id) ||
    !academicSession ||
    !analyticsFilters;

  // Insufficient Data Code Block
  const checkDataMissingInAllWidgets = () =>
    dashboardWidgetsDataStatus?.map((widgetData) => widgetData.dataMissing).every((dataMissing) => dataMissing);
  const checkIfAnyWidgetStillLoading = () =>
    dashboardWidgetsDataStatus?.map((widgetData) => widgetData.loading).some((flag) => flag);

  const updateDashboardWidgetsDataStatus = ({
    widget,
    loading: widgetLoading,
    dataMissing,
  }: DashboardWidgetsDataStatusProps) => {
    setDashboardWidgetsDataStatus?.(
      dashboardWidgetsDataStatus?.map((dashboardWidget) =>
        dashboardWidget.widget !== widget
          ? dashboardWidget
          : { widget, loading: widgetLoading, dataMissing: dataMissing ?? dashboardWidget.dataMissing }
      )
    );
  };

  // resets the data flags of all widgets before the new set of requests due to new filters or user actions.
  const resetDashboardWidgetsDataStatus = (widgetLoading: boolean, widgetDataMissing?: boolean) => {
    const newDashboardWidgetsDataStatus: DashboardWidgetsDataStatusProps[] = [];
    dashboardWidgetsDataStatus?.forEach((widgetDataStatus) => {
      let newWidgetDataStatus: any = {};
      if (widgetLoading !== undefined) newWidgetDataStatus = { loading: widgetLoading, ...newWidgetDataStatus };
      if (widgetDataMissing !== undefined)
        newWidgetDataStatus = { dataMissing: widgetDataMissing, ...newWidgetDataStatus };
      newDashboardWidgetsDataStatus.push({
        ...widgetDataStatus,
        ...newWidgetDataStatus,
      });
    });
    setDashboardWidgetsDataStatus?.([...newDashboardWidgetsDataStatus]);
  };

  // update isDataMissingInAllWidgets when there is change in the data-Status in each widget
  useEffect(() => {
    setIsDataMissingInAllWidgets?.(checkDataMissingInAllWidgets());
    setAreWidgetsLoading?.(checkIfAnyWidgetStillLoading() || false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardWidgetsDataStatus]);

  return {
    loading,
    setLoading,
    isSchoolAdmin,
    mySchoolData,
    isDistrictAdmin,
    myDistrictData,
    skipGraphQLRequest,
    getLocalStorageDashboardFilters,
    setLocalStorageDashboardFilters,
    localDashboardFilters,
    dashboardFilters,
    currentFilters,
    setCurrentFilters,
    analyticsFilters,
    setAnalyticsFilters,
    academicSessionLoading,
    academicSession,
    sessionStartDate,
    sessionEndDate,
    startDateInit,
    startDateSelected,
    setStartDateSelected,
    endDateSelected,
    setEndDateSelected,
    dashboardWidgetsDataStatus,
    setDashboardWidgetsDataStatus,
    updateDashboardWidgetsDataStatus,
    resetDashboardWidgetsDataStatus,
    checkDataMissingInAllWidgets,
    isDataMissingInAllWidgets,
    setIsDataMissingInAllWidgets,
    areWidgetsLoading,
    setAreWidgetsLoading,
    lastSnowflakeUpdateTimeData,
    getLastSnowflakeUpdateTimeLoading,
  };
};

export const useAdminAnalytics = () => {
  // fetch current user's district/school data
  const { isSchoolAdmin, isDistrictAdmin } = useRole();
  const { data: myDistrictData, loading: myDistrictDataLoading } = useMyDistrictQuery({
    fetchPolicy: 'network-only',
    skip: isSchoolAdmin,
  });
  const { data: mySchoolData, loading: mySchoolDataLoading } = useMySchoolQuery({
    fetchPolicy: 'network-only',
    skip: isDistrictAdmin,
  });
  // YTD
  const { data: academicSessionData, loading: academicSessionLoading } = useGetAcademicSessionQuery({
    fetchPolicy: 'network-only',
  });

  //GetLastSnowflakeUpdateTime
  const { data: lastSnowflakeUpdateTimeData, loading: getLastSnowflakeUpdateTimeLoading } =
    useGetLastSnowflakeUpdateTimeQuery({
      fetchPolicy: 'network-only',
    });

  return {
    isSchoolAdmin,
    mySchoolDataLoading,
    mySchoolData: mySchoolData?.mySchool,
    isDistrictAdmin,
    myDistrictDataLoading,
    myDistrictData: myDistrictData?.myDistrict,
    academicSessionLoading,
    academicSession: academicSessionData?.getAcademicSession,
    lastSnowflakeUpdateTimeData: lastSnowflakeUpdateTimeData?.getLastSnowflakeUpdateTime,
    getLastSnowflakeUpdateTimeLoading,
  };
};
