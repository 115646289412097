import { useTheme } from '@mui/material/styles';
import { Box, Theme, Typography } from '@mui/material';
import { palette } from '../../../../theme/palette';
import React from 'react';
import { PerformanceWidgetScoreLineItemByMonth } from '../../../../generated/graphql';

type Props = {
  active?: string;
  payload?: { payload: PerformanceWidgetScoreLineItemByMonth }[];
};

const StylesCss = (theme: Theme) => ({
  dialogBox: {
    padding: theme.spacing(1, 2),
    backgroundColor: palette.customWhite.main,
    border: `2px solid ${palette.customRed.progressPrimary}`,
    width: 'auto',
    height: 'auto',
    borderRadius: '8px',
    boxShadow: `0 3px 5px 1px ${palette.customBackground.disabled}`,
  },
});

export function OverallByMonthTooltip({ active, payload }: Props) {
  const theme = useTheme();
  const styles = StylesCss(theme);
  if (active) {
    const currData = payload && payload.length ? payload[0].payload : null;
    return (
      <Box className="custom-tooltip" style={styles.dialogBox}>
        <Typography
          component="p"
          sx={{
            fontWeight: 700,
            mb: '5px',
          }}
        >
          {currData ? currData.month_year_display : ''}
        </Typography>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="toolTipText" sx={{ color: palette.customBlue.chipDark }}>
            Average score:
          </Typography>
          <Typography variant="toolTipValue" sx={{ paddingLeft: '18px' }}>
            {currData ? currData.average_score : ' -- '}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="toolTipText" sx={{ color: palette.customBlue.chipDark }}>
            Assignments graded:
          </Typography>
          <Typography variant="toolTipValue" sx={{ paddingLeft: '18px' }}>
            {currData ? currData.graded_assignments_count : ' -- '}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="toolTipText" sx={{ color: palette.customBlue.chipDark }}>
            Number of students:
          </Typography>
          <Typography variant="toolTipValue" sx={{ paddingLeft: '18px' }}>
            {currData ? currData.unique_student_count : ' -- '}
          </Typography>
        </Box>
      </Box>
    );
  }
  return null;
}
