import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette } from '../../theme/palette';

const DescriptionAndExplanation = React.memo(({ width = 53, height = 47, ...props }: any) => (
  <SvgIcon fontSize="small" width={width} height={height} viewBox="0 0 53 47" fill="none" {...props}>
    <g>
      <path
        d="M33.5436 36.6242H32.7908L32.2821 37.1791L26.0134 44.0176L19.7448 37.1791L19.2361 36.6242H18.4832H5.47651C3.39711 36.6242 1.71141 34.9385 1.71141 32.8591V5.47651C1.71141 3.3971 3.3971 1.71141 5.47651 1.71141H46.5503C48.6298 1.71141 50.3154 3.3971 50.3154 5.47651V32.8591C50.3154 34.9385 48.6298 36.6242 46.5503 36.6242H33.5436Z"
        stroke={palette.fontColors.fontBlack}
        fill={palette.customWhite.main}
        strokeWidth="3.42282"
      />
      <rect x="8.21484" y="9.58398" width="35.5973" height="3.42282" fill={palette.fontColors.fontBlack} />
      <rect x="8.21484" y="17.7991" width="16.4295" height="3.42282" fill={palette.fontColors.fontBlack} />
      <rect x="8.21484" y="26.0137" width="24.6443" height="3.42282" fill={palette.fontColors.fontBlack} />
    </g>
  </SvgIcon>
));

export default DescriptionAndExplanation;
