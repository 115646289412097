import React, { Dispatch, SetStateAction, useState } from 'react';
import Box from '@mui/material/Box';
import { Button, IconButton } from '@mui/material';
import { palette } from '../theme/palette';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import CloseIcon from '@mui/icons-material/Close';
import { WhatsNewModal } from './WhatsNewModal';
import { sentryMessageLog } from '../utils/sentry';

const styles = {
  imitationMessage: {
    fontSize: '18px',
    textDecoration: 'none',
    fontWeight: 800,
    color: palette.customWhite.main,
    minHeight: '60px',
    backgroundColor: palette.customBlue.primaryBlue,
    borderRadius: 2,
  },
  tellMeMore: {
    '&.MuiButtonBase-root': {
      alignSelf: 'center',
      border: '1px solid rgba(42, 42, 139, 0.5)',
      fontWeight: 800,
      height: 'fit-content',
      padding: '10px 16px',
      whiteSpace: 'nowrap',
    },
  },
  closeBanner: {
    color: 'white',
    '&.MuiButtonBase-root': {
      padding: '10px 16px',
    },
  },
};

type WhatsNewBannerProps = {
  setShouldSeeWhatsNewBanner: Dispatch<SetStateAction<boolean>>;
};

export const WhatsNewVersion = '1';
export const WhatsNewDismissedKey = 'whatsNewDismissed';

export const WhatsNewBanner = React.memo(({ setShouldSeeWhatsNewBanner }: WhatsNewBannerProps) => {
  function handleTellMeMore() {
    setShowModal(true);
    sentryMessageLog("What's new Banner: Tell Me More Clicked!", 'info');
  }

  function handleDismissBanner() {
    localStorage.setItem(WhatsNewDismissedKey, WhatsNewVersion);
    setShouldSeeWhatsNewBanner(false);
    sentryMessageLog("What's new Banner: Dismissed Clicked!", 'info');
  }

  function handleCloseDialog() {
    localStorage.setItem(WhatsNewDismissedKey, WhatsNewVersion);
    setShowModal(false);
    setShouldSeeWhatsNewBanner(false);
    sentryMessageLog("What's new Dialog: Okay Clicked!", 'info');
  }

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Box
        sx={{ ...styles.imitationMessage }}
        display="grid"
        gridTemplateColumns="1fr auto"
        justifyContent="center"
        alignItems="center"
      >
        <Box display="flex" justifySelf="center" gap={2}>
          <Box display="flex" alignItems="center" gap={1}>
            <InfoRoundedIcon width="25px" height="25px" />
            <span>Introducing the Ability to Create Classes and Manage Students as an Admin.</span>
          </Box>
          <Button variant="outlined" color="primary" onClick={handleTellMeMore} sx={{ ...styles.tellMeMore }}>
            Tell me more!
          </Button>
        </Box>
        <IconButton onClick={handleDismissBanner} sx={{ ...styles.closeBanner }} aria-label="Dismiss Banner">
          <CloseIcon />
        </IconButton>
      </Box>
      <WhatsNewModal openDialog={showModal} closeDialog={handleCloseDialog} />
    </>
  );
});
