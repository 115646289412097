/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ClipBoardCheckIcon = (props: any) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M10.8 2.40039C10.1373 2.40039 9.60005 2.93765 9.60005 3.60039C9.60005 4.26313 10.1373 4.80039 10.8 4.80039H13.2C13.8628 4.80039 14.4 4.26313 14.4 3.60039C14.4 2.93765 13.8628 2.40039 13.2 2.40039H10.8Z"
      fill="#6B7280"></path>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M4.80005 6.00039C4.80005 4.67491 5.87457 3.60039 7.20005 3.60039C7.20005 5.58862 8.81182 7.20039 10.8 7.20039H13.2C15.1883 7.20039 16.8 5.58862 16.8 3.60039C18.1255 3.60039 19.2 4.67491 19.2 6.00039V19.2004C19.2 20.5259 18.1255 21.6004 16.8 21.6004H7.20005C5.87457 21.6004 4.80005 20.5259 4.80005 19.2004V6.00039ZM16.4486 12.8489C16.9172 12.3803 16.9172 11.6205 16.4486 11.1519C15.9799 10.6832 15.2202 10.6832 14.7515 11.1519L10.8 15.1033L9.24858 13.5519C8.77995 13.0832 8.02015 13.0832 7.55152 13.5519C7.08289 14.0205 7.08289 14.7803 7.55152 15.2489L9.95152 17.6489C10.4201 18.1175 11.1799 18.1175 11.6486 17.6489L16.4486 12.8489Z"
          fill="#6B7280"></path>
  </SvgIcon>
);

export default ClipBoardCheckIcon;
