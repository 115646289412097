import React, { useEffect, useState } from 'react';
import {
  OrderBy,
  PerformanceSlideOutSortType,
  PerformanceWidgetSlideOutInput,
  usePerformanceWidgetSlideOutQuery,
} from '../../../../../generated/graphql';
import { ModalTypes } from '../../../../../variables/types';
import { PAGE_SIZE } from '../../../../../variables/constant';
import usePagination from '../../../../../utils/usePagination';
import { useDashboardContext } from '../../../admin-analytics-hook';

export interface Props {
  skill: any;
  skillLevel: string;
  open?: boolean;
  onClose?: any;
  variant?: ModalTypes;
  queryInput?: PerformanceWidgetSlideOutInput;
  setQueryInput?: Function;
}

interface QueryReqInputProps {
  input: PerformanceWidgetSlideOutInput;
}

export function useFlyoverInfo({ onClose, skill, skillLevel, queryInput, setQueryInput }: Props) {
  const {
    isDistrictAdmin,
    myDistrictData,
    isSchoolAdmin,
    mySchoolData,
    analyticsFilters: queryFilters,
    loading,
    setLoading,
  } = useDashboardContext();

  const pagination = usePagination('PerformanceSliderPage');

  const handleModalOnClose = (_: any, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason && ['backdropClick', 'escapeKeyDown'].includes(reason)) return;
    pagination.unsetPage();
    onClose();
  };

  const [queryReqInput, setQueryReqInput] = useState<QueryReqInputProps>({
    input: queryInput || {
      limit: PAGE_SIZE,
      page: pagination.page,
      sort: PerformanceSlideOutSortType.PerformanceSlideOutSortByStudentName,
      order_by: OrderBy.Asc,
      filters: queryFilters,
      scoring_detail_id: skill && skillLevel ? skill?.[`${skillLevel}_id`] : '',
      school_id: isSchoolAdmin && mySchoolData?.id ? mySchoolData.id : undefined,
      district_id: isDistrictAdmin && myDistrictData ? myDistrictData.id : undefined,
    },
  });
  const updateQueryInput = (queryInputUpdate: any) => {
    setQueryReqInput({
      input: {
        ...queryReqInput.input,
        ...queryInputUpdate,
      },
    });
  };

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: React.SetStateAction<number>) => {
    pagination.setPage(value as number);
    updateQueryInput({
      page: value as number,
    });
  };

  const onSortChange = (sortBy: PerformanceSlideOutSortType, orderBy: OrderBy) => {
    pagination.setPage(1);
    updateQueryInput({
      page: 1,
      sort: sortBy,
      order_by: orderBy,
    });
  };

  const handleSort = (column: PerformanceSlideOutSortType) => () => {
    if (column === queryReqInput.input.sort) {
      onSortChange(column, queryReqInput.input.order_by === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const skipGraphQLRequest = () =>
    (isSchoolAdmin || isDistrictAdmin) &&
    isSchoolAdmin &&
    !mySchoolData?.id &&
    isDistrictAdmin &&
    !myDistrictData?.id &&
    !!queryReqInput.input.scoring_detail_id;

  const { data: performanceWidgetSlideoutResp, loading: performanceWidgetSlideoutDataLoading } =
    usePerformanceWidgetSlideOutQuery({
      fetchPolicy: 'network-only',
      skip: skipGraphQLRequest(),
      variables: { ...queryReqInput },
    });

  const performanceWidgetSlideoutData = performanceWidgetSlideoutResp?.performanceWidgetSlideOut!;
  const performanceWidgetSlideoutDataCount = performanceWidgetSlideoutData?.total_count ?? 0;
  const isTableEmpty = !performanceWidgetSlideoutDataLoading && performanceWidgetSlideoutDataCount === 0;

  useEffect(() => {
    setLoading?.(loading && performanceWidgetSlideoutDataLoading);
  }, [performanceWidgetSlideoutDataLoading]);

  useEffect(() => {
    setQueryInput?.(queryReqInput.input);
  }, [queryReqInput]);

  return {
    handleModalOnClose,
    queryReqInput,
    pagination,
    handlePageChange,
    handleSort,
    performanceWidgetSlideoutDataLoading,
    performanceWidgetSlideoutData,
    performanceWidgetSlideoutDataCount,
    isTableEmpty,
  };
}
