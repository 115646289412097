import React, { ReactNode } from 'react';
import { Box, Button, Typography } from '@mui/material';
import StyledBoxWrapper from './StyledCard.styled';

type Alignment = 'inherit' | 'left' | 'center' | 'right' | 'justify';
interface Props {
  children?: ReactNode;
  title?: ReactNode | string;
  buttonTitle?: string;
  onClick?: () => void;
  titleAlignment?: Alignment;
  contentWrapperMinHeightAuto?: boolean;
  mediaParam?: boolean;
  columnSetting?: boolean;
  isToolTip?: boolean;
  toolTipComponent?: ReactNode;
  fullHeight?: boolean;
  contentAlignment?: boolean;
  marginBottom?: string;
  showButton?: boolean;
  overridePadding?: number | string;
}

const StyledCard = ({
  children,
  title,
  buttonTitle,
  onClick,
  titleAlignment = 'center',
  contentWrapperMinHeightAuto,
  mediaParam,
  columnSetting,
  toolTipComponent,
  isToolTip,
  fullHeight,
  contentAlignment,
  marginBottom = '50px',
  showButton = true,
  overridePadding,
}: Props) => {
  return (
    <StyledBoxWrapper width="100%" height={fullHeight ? '100%' : 'inherit'} overridePadding={overridePadding}>
      <Typography align={titleAlignment} variant="tileTitle">
        {title}
      </Typography>
      {isToolTip && (
        <Box component="span" position="absolute" right="15px" top="15px">
          {toolTipComponent}
        </Box>
      )}
      <Box
        sx={{
          minHeight: '250px',
          ...(contentWrapperMinHeightAuto && { minHeight: 'auto' }),
          minWidth: '250px',
          justifyContent: 'center',
          display: 'flex',
          ...(mediaParam && { justifyContent: 'normal', display: 'grid' }),
          alignItems: 'center',
          ...(contentAlignment && { alignItems: 'left', justifyContent: 'start' }),
          flexDirection: 'row',
          mb: 'inherit',
          ...(columnSetting && { flexDirection: 'column', mb: { marginBottom } }),
        }}
      >
        {children}
      </Box>
      <Box px={4} width="100%" display="flex" justifyContent="center">
        {showButton && buttonTitle && (
          <Button onClick={onClick || (() => {})} variant="outlined">
            {buttonTitle}
          </Button>
        )}
      </Box>
    </StyledBoxWrapper>
  );
};

export default StyledCard;
