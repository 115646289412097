import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../../components/Layout';
import LandingPage from './LandingPage';
import UploadImages from './UploadImages/UploadImages';
import ManageImages from './ManageImages/ManageImages';
import SuperAdmin from './SuperAdminUsers/SuperAdmin';
import SuperAdminTable from './SuperAdminUsers/SuperAdminTable';
import CreateSuperAdmin from './SuperAdminUsers/CreateSuperAdmin';
import RoutingPaths from '../RoutingPath';
import { ResetBenchmark } from './TempBenchmarkReset';

const Settings = () => (
  <Layout>
    <Switch>
      <Route path={RoutingPaths.Settings} component={LandingPage} exact />
      <Route path={RoutingPaths.UploadImages} component={UploadImages} exact />
      <Route path={RoutingPaths.ManageImages} component={ManageImages} exact />
      <Route path="/settings/super-admin" component={SuperAdminTable} exact />

      <Route path="/settings/super-admin/create" component={CreateSuperAdmin} exact />

      <Route path="/settings/super-admin/create/:id" component={CreateSuperAdmin} exact />
      <Route path="/settings/super-admin/:id" component={SuperAdmin} exact />
      <Route path="/settings/benchmark-reset" component={ResetBenchmark} exact />
    </Switch>
  </Layout>
);

export default Settings;
