/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React, { ComponentProps } from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const MicIcon = React.memo(({ pathFill, ...props }: ComponentProps<typeof SvgIcon> & { pathFill?: string }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="22" height="32" viewBox="0 0 22 32" fill="none" {...props}>
    <path
      d="M11 22C14.3138 22 17 19.3137 17 16V6C17 2.68625 14.3138 0 11 0C7.68625 0 5 2.68625 5 6V16C5 19.3137 7.68625 22 11 22ZM21 12H20C19.4475 12 19 12.4475 19 13V16C19 20.675 14.9694 24.4263 10.2006 23.9613C6.04438 23.5556 3 19.8194 3 15.6438V13C3 12.4475 2.5525 12 2 12H1C0.4475 12 0 12.4475 0 13V15.51C0 21.1125 3.99813 26.1069 9.5 26.8656V29H6C5.4475 29 5 29.4475 5 30V31C5 31.5525 5.4475 32 6 32H16C16.5525 32 17 31.5525 17 31V30C17 29.4475 16.5525 29 16 29H12.5V26.8894C17.8569 26.1544 22 21.5562 22 16V13C22 12.4475 21.5525 12 21 12Z"
      fill={pathFill || 'white'}
    />
  </SvgIcon>
));

export default MicIcon;
