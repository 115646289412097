import React from 'react';

const styles = {
  icon: {
    width: '25px',
    height: '25px',
  },
  text: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '19.1px',
  },
};

const XaxisCustomizeTick = (props: any) => {
  const { x, y, payload, barData } = props;
  const [first, rest] = payload.value ? payload.value.split(/\s+(.*)/) : [undefined, undefined];
  const icon = barData.find((data: any) => {
    return data.rubricTitle === payload.value;
  });

  return (
    <>
      <image
        transform={`translate(${x - 10}, ${y + 3})`}
        style={styles.icon}
        href={`data:image/svg+xml;base64,${icon.rubricIcon}`}
      />
      <text x={x} y={y + 27} dy={16} width={50} textAnchor="middle" fill="black" style={styles.text}>
        {first && (
          <tspan x={x} dy="1.2em" key={first}>
            {first}
          </tspan>
        )}
        {rest && (
          <tspan x={x} dy="1.2em" key={rest}>
            {rest}
          </tspan>
        )}
      </text>
    </>
  );
};

export default XaxisCustomizeTick;
