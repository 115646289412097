import React, { Fragment } from 'react';
import { ACTIVE_GOAL_TOOLTIP_TYPE, SKILL_LINK_TEXT } from '../../../variables/constant';
import {
  StyledBox,
  StyledDescriptionTypography,
  StyledGoalDescriptionTypography,
  StyledGoalTitleTypography,
  StyledLink,
  StyledPerformanceLink,
  StyledTitleTypography,
} from './tooltip-styled';

interface Props {
  data: any;
  onLinkClick?: (data: any, skillScore?: string) => void;
  tooltipType?: string;
  skillLevel?: string;
  disableLink?: boolean;
  disableDesc?: boolean;
}

export function SingleBarHorizontalGraphTooltip({
  data,
  tooltipType,
  onLinkClick = () => {},
  skillLevel,
  disableLink,
  disableDesc,
}: Props) {
  const handleClick = () => {
    const number = data?.numberOfGoals;
    if (number > 0) {
      onLinkClick(data);
    }
  };

  const handleClickForPerformance = (count: number, skillScore: string) => {
    if (count > 0) {
      onLinkClick(data, skillScore);
    }
  };
  const toolTipContent =
    tooltipType !== ACTIVE_GOAL_TOOLTIP_TYPE ? (
      <>
        {data &&
          Object.keys(data)?.reduce((prevValue: any[], currentValue) => {
            if (currentValue?.endsWith('_key') && currentValue === skillLevel) {
              prevValue.push(
                <Fragment key={currentValue}>
                  <StyledTitleTypography>
                    {`Level ${data?.[`${currentValue}_level`] as number}: ${
                      data?.[`${currentValue}_label`] as string
                    } ${data?.[`${currentValue}_type`] as string}`}
                  </StyledTitleTypography>
                  {!disableDesc && (
                    <StyledDescriptionTypography>
                      {data?.[`${currentValue}_description`] as string}
                    </StyledDescriptionTypography>
                  )}
                  <StyledPerformanceLink
                    color="inherit"
                    numberOfStudents={0}
                    style={{
                      color: data?.[`${currentValue}_color`] as string,
                      cursor: `${
                        (data?.[`${currentValue}_numberOfStudents`] as number) > 0 && !disableLink ? 'pointer' : 'auto'
                      }`,
                    }}
                    onClick={() =>
                      handleClickForPerformance(data?.[`${currentValue}_numberOfStudents`] as number, skillLevel)
                    }
                  >
                    {`${data?.[`${currentValue}_numberOfStudents`]} ${
                      (data?.[`${currentValue}_numberOfStudents`] as number) > 1
                        ? `${SKILL_LINK_TEXT}s`
                        : SKILL_LINK_TEXT
                    }`}{' '}
                    {!disableLink && data?.[`${currentValue}_numberOfStudents`] > 0 && <span>&rarr;</span>}
                  </StyledPerformanceLink>
                </Fragment>
              );
            }
            return prevValue;
          }, [])}
      </>
    ) : (
      <>
        <StyledGoalTitleTypography>{data?.numberOfGoals}</StyledGoalTitleTypography>
        <StyledGoalDescriptionTypography>{data?.skillLabel}</StyledGoalDescriptionTypography>
        <StyledLink
          color={data?.color ?? 'inherit'}
          numberOfStudents={data?.numberOfStudents ?? 0}
          numberOfGoals={data?.numberOfGoals ?? 0}
          onClick={handleClick}
        >
          {`${data?.numberOfGoals > 1 ? `${data?.skillLinkText}` : data?.skillLinkText}`}
          {data?.skill_key && <span>&rarr;</span>}
        </StyledLink>
      </>
    );

  return (
    <StyledBox display="flex" flexDirection="column" width="100%" tooltipType={tooltipType || ''}>
      {toolTipContent}
    </StyledBox>
  );
}
