import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { DashboardIcon, SettingIcon, StudentsIcon } from '../Icons';
import DistrictsIcon from '../Icons/DistrictsIcon';
import { StyledNavigationButton, StyledNavigationButtonBox, superAdminOptionStyles } from './Sidebar.styled';
import SchoolsIcon from '../Icons/SchoolsIcon';
import ClassesIcon from '../Icons/ClassesIcon';
import TeachersIcon from '../Icons/TeachersIcon';
import SchoolAdminIcon from '../Icons/SchoolAdminIcon';
import useDashboard from '../../pages/AdminDashboard/admin-dashboard-hook';
import useRole from '../../hooks/useRole';
import { MY_DISTRICT, MY_SCHOOL } from '../../variables/constant';
import NumberFormat from 'react-number-format';
import { StyledChipBox } from '../Chip/ChipsCount.styled';
import { palette } from '../../theme/palette';
import BenchmarkIcon from '../Icons/BenchmarkIcon';
import TreeViewNavigation from './TreeViewNavigation';
import TreeMapSelectedIcon from '../Icons/TreeMapSelectedIcon';
import NavigationChip from '../NavigationChip';
import useSidebarHook from './sidebar-hook';
import { SearchContext } from '../../core/searchContext';
import SolidPhotograph from '../Icons/SolidPhotograph';
import RoutingPaths from '../../pages/RoutingPath';

const SuperAdminNavigation = () => {
  const history = useHistory();
  const { isSuperAdmin, isDistrictAdmin, isSchoolAdmin } = useRole();
  const { setTabSection } = useContext(SearchContext);
  const {
    dashboardData: data,
    districtPermission,
    districtAdminPermission,
    schoolPermission,
    schoolAdminPermission,
    teacherPermission,
    classPermission,
    studentPermission,
    myDistrictPermission,
    mySchoolPermission,
  } = useDashboard();

  const { sidenavBenchmarkGradeTileCount } = useSidebarHook();
  const isDashboard = useRouteMatch({ path: ['/', '/tasks/:id/submit', '/tasks/:id/score/:section'], exact: true });
  const isDistrict = useRouteMatch({ path: ['/districts', '/districts/:id'], exact: false });
  const isSchool = useRouteMatch({ path: ['/schools', '/schools/:id'], exact: false });
  const isClasses = useRouteMatch({ path: ['/admin-classes', '/admin-classes/:id'], exact: false });
  const isStudents = useRouteMatch({ path: ['/admin-students', '/admin-students/:id'], exact: false });
  const isTeachers = useRouteMatch({ path: ['/teachers', '/teachers/:id'], exact: false });
  const isSchoolAdmins = useRouteMatch({ path: ['/admins/school', '/admins/school/:id'], exact: false });
  const isDistrictAdmins = useRouteMatch({ path: ['/admins/district', '/admins/district/:id'], exact: false });
  const isSettings = useRouteMatch({ path: '/settings', exact: false });
  const isMyDistrict = useRouteMatch({ path: `/districts/${MY_DISTRICT}`, exact: false });
  const isMySchool = useRouteMatch({ path: `/schools/${MY_SCHOOL}`, exact: false });
  const isPerformanceData = useRouteMatch({ path: '/admin/analytics', exact: false });
  const isLearningGoals = useRouteMatch({ path: '/admin/analytics/learning-goals', exact: false });
  const isDistrictComparisons = useRouteMatch({
    path: ['/admin/analytics/district-wide-comparisons', '/admin/analytics/district-wide-comparisons/:tabKey'],
    exact: false,
  });
  const isBenchmarkList = useRouteMatch({
    path: ['/benchmarks', '/benchmarks/:id/edit', '/tasks/:id/benchmark/score', '/tasks/:id/benchmark/score/:section'],
    exact: false,
  });
  const isBenchMarkCreate = useRouteMatch({ path: '/benchmarks/create', exact: false });
  const isBenchMarkGrade = useRouteMatch({
    path: ['/benchmarks/grade', '/tasks/:id/benchmark/score', '/tasks/:id/benchmark/score/:section'],
    exact: false,
  });
  const isBenchMarkUpload = useRouteMatch({ path: '/benchmarks/upload', exact: false });
  const isAdminBenchmarkReport = useRouteMatch({ path: RoutingPaths.AdminBenchmarkReport, exact: false });
  const isAdminBenchmarkComparison = useRouteMatch({ path: RoutingPaths.AdminBenchmarkComparison, exact: false });

  const navigateTo = (path: string) => () => {
    history.push(path);
    setTabSection?.('');
  };

  const benchmarkSidebar = {
    mainCategory: {
      indexId: '1',
      label: 'Benchmarks',
      linkToRedirect: '/benchmarks',
      routeMatches: isBenchmarkList,
      icon: BenchmarkIcon,
    },
    subItems: [
      {
        indexId: '1.a',
        label: 'Create',
        linkToRedirect: '/benchmarks/create',
        routeMatches: isBenchMarkCreate,
        icon: TreeMapSelectedIcon,
      },
      {
        indexId: '1.b',
        label: 'Grade',
        linkToRedirect: '/benchmarks/grade',
        routeMatches: isBenchMarkGrade,
        icon: TreeMapSelectedIcon,
        counterChips: <NavigationChip dataCount={sidenavBenchmarkGradeTileCount} />,
      },
      {
        indexId: '1.c',
        label: 'Upload Images',
        linkToRedirect: '/benchmarks/upload',
        routeMatches: isBenchMarkUpload,
        icon: TreeMapSelectedIcon,
      },
    ],
  };

  const performanceSidebar = {
    mainCategory: {
      indexId: '1',
      label: 'Assignment Data',
      linkToRedirect: RoutingPaths.Performance,
      routeMatches: isPerformanceData,
      icon: SolidPhotograph,
    },
    subItems: [
      {
        indexId: '1.a',
        label: isSchoolAdmin ? 'School Comparisons' : 'District Comparisons',
        linkToRedirect: RoutingPaths.DistrictWideComparison,
        routeMatches: isDistrictComparisons,
        icon: TreeMapSelectedIcon,
      },
      {
        indexId: '1.b',
        label: 'Learning Goals',
        linkToRedirect: RoutingPaths.LearningGoals,
        routeMatches: isLearningGoals,
        icon: TreeMapSelectedIcon,
      },
    ],
  };

  const benchmarkDashboardCommon = {
    mainCategory: {
      indexId: '1',
      label: 'Benchmark Data',
      linkToRedirect: RoutingPaths.AdminBenchmarkReport,
      routeMatches: isAdminBenchmarkReport,
      icon: BenchmarkIcon,
    },
    subItems: [
      {
        indexId: '1.a',
        label: `${isSchoolAdmin ? 'School' : 'District'} Comparison`,
        linkToRedirect: RoutingPaths.AdminBenchmarkComparison,
        routeMatches: isAdminBenchmarkComparison,
        icon: TreeMapSelectedIcon,
      },
    ],
  };

  return (
    <Box>
      {isSuperAdmin && (
        <StyledNavigationButton fullWidth onClick={navigateTo('/')}>
          <StyledNavigationButtonBox
            sx={{ ...superAdminOptionStyles.buttonBox, ...(isDashboard && superAdminOptionStyles.active) }}
          >
            <DashboardIcon sx={{ mr: '12px' }} />
            Dashboard
          </StyledNavigationButtonBox>
        </StyledNavigationButton>
      )}
      {/* will be enabled once the dashboard is ready */}
      {(isDistrictAdmin || isSchoolAdmin) && (
        <StyledNavigationButton fullWidth>
          <TreeViewNavigation
            mainCategoryItem={performanceSidebar.mainCategory}
            subCategoryItems={performanceSidebar.subItems}
          />
        </StyledNavigationButton>
      )}
      {(isDistrictAdmin || isSchoolAdmin) && (
        <StyledNavigationButton fullWidth>
          <TreeViewNavigation
            mainCategoryItem={benchmarkDashboardCommon.mainCategory}
            subCategoryItems={benchmarkDashboardCommon.subItems}
          />
        </StyledNavigationButton>
      )}
      {myDistrictPermission && (
        <StyledNavigationButton fullWidth onClick={navigateTo(`/districts/${MY_DISTRICT}`)}>
          <StyledNavigationButtonBox
            sx={{ ...superAdminOptionStyles.buttonBox, ...(isMyDistrict && superAdminOptionStyles.active) }}
          >
            <DistrictsIcon sx={{ mr: '12px' }} />
            My District
          </StyledNavigationButtonBox>
        </StyledNavigationButton>
      )}
      {mySchoolPermission && (
        <StyledNavigationButton fullWidth onClick={navigateTo(`/schools/${MY_SCHOOL}`)}>
          <StyledNavigationButtonBox
            sx={{ ...superAdminOptionStyles.buttonBox, ...(isMySchool && superAdminOptionStyles.active) }}
          >
            <SchoolsIcon sx={{ mr: '12px' }} />
            My School
          </StyledNavigationButtonBox>
        </StyledNavigationButton>
      )}
      {districtPermission && (
        <StyledNavigationButton fullWidth onClick={navigateTo('/districts')}>
          <StyledNavigationButtonBox
            sx={{ ...superAdminOptionStyles.buttonBox, ...(isDistrict && superAdminOptionStyles.active) }}
          >
            <DistrictsIcon sx={{ mr: '12px' }} />
            Districts
            <StyledChipBox
              sx={{ backgroundColor: palette.customBackground.dragBackground }}
              hascolor={palette.customBlack.lightBlack}
              label={<NumberFormat displayType="text" value={data?.districts ?? '-'} thousandSeparator />}
            />
          </StyledNavigationButtonBox>
        </StyledNavigationButton>
      )}
      {schoolPermission && (
        <StyledNavigationButton fullWidth onClick={navigateTo('/schools')}>
          <StyledNavigationButtonBox
            sx={{ ...superAdminOptionStyles.buttonBox, ...(isSchool && superAdminOptionStyles.active) }}
          >
            <SchoolsIcon sx={{ mr: '12px' }} />
            Schools
            <StyledChipBox
              sx={{ backgroundColor: palette.customBackground.dragBackground }}
              hascolor={palette.customBlack.lightBlack}
              label={<NumberFormat displayType="text" value={data?.schools ?? '-'} thousandSeparator />}
            />
          </StyledNavigationButtonBox>
        </StyledNavigationButton>
      )}
      {teacherPermission && (
        <StyledNavigationButton fullWidth onClick={navigateTo('/teachers')}>
          <StyledNavigationButtonBox
            sx={{ ...superAdminOptionStyles.buttonBox, ...(isTeachers && superAdminOptionStyles.active) }}
          >
            <TeachersIcon sx={{ mr: '12px' }} />
            Teachers
            <StyledChipBox
              sx={{ backgroundColor: palette.customBackground.dragBackground }}
              hascolor={palette.customBlack.lightBlack}
              label={<NumberFormat displayType="text" value={data?.teachers ?? '-'} thousandSeparator />}
            />
          </StyledNavigationButtonBox>
        </StyledNavigationButton>
      )}
      {classPermission && (
        <StyledNavigationButton fullWidth onClick={navigateTo('/admin-classes')}>
          <StyledNavigationButtonBox
            sx={{ ...superAdminOptionStyles.buttonBox, ...(isClasses && superAdminOptionStyles.active) }}
          >
            <ClassesIcon sx={{ mr: '12px' }} />
            Classes
            <StyledChipBox
              sx={{ backgroundColor: palette.customBackground.dragBackground }}
              hascolor={palette.customBlack.lightBlack}
              label={<NumberFormat displayType="text" value={data?.classes ?? '-'} thousandSeparator />}
            />
          </StyledNavigationButtonBox>
        </StyledNavigationButton>
      )}
      {studentPermission && (
        <StyledNavigationButton
          fullWidth
          onClick={navigateTo(mySchoolPermission ? `/admin-students/?school= ${MY_SCHOOL}` : '/admin-students')}
        >
          <StyledNavigationButtonBox
            sx={{ ...superAdminOptionStyles.buttonBox, ...(isStudents && superAdminOptionStyles.active) }}
          >
            <StudentsIcon sx={{ mr: '12px' }} />
            Students
            <StyledChipBox
              sx={{ backgroundColor: palette.customBackground.dragBackground }}
              hascolor={palette.customBlack.lightBlack}
              label={<NumberFormat displayType="text" value={data?.students ?? '-'} thousandSeparator />}
            />
          </StyledNavigationButtonBox>
        </StyledNavigationButton>
      )}
      {schoolAdminPermission && (
        <StyledNavigationButton fullWidth onClick={navigateTo('/admins/school')}>
          <StyledNavigationButtonBox
            sx={{ ...superAdminOptionStyles.buttonBox, ...(isSchoolAdmins && superAdminOptionStyles.active) }}
          >
            <SchoolAdminIcon sx={{ mr: '12px' }} />
            School Admins
            <StyledChipBox
              sx={{ backgroundColor: palette.customBackground.dragBackground }}
              hascolor={palette.customBlack.lightBlack}
              label={<NumberFormat displayType="text" value={data?.school_admins ?? '-'} thousandSeparator />}
            />
          </StyledNavigationButtonBox>
        </StyledNavigationButton>
      )}
      {districtAdminPermission && (
        <StyledNavigationButton fullWidth onClick={navigateTo('/admins/district')}>
          <StyledNavigationButtonBox
            sx={{ ...superAdminOptionStyles.buttonBox, ...(isDistrictAdmins && superAdminOptionStyles.active) }}
          >
            <SchoolAdminIcon sx={{ mr: '12px' }} />
            District Admins
            <StyledChipBox
              sx={{ backgroundColor: palette.customBackground.dragBackground }}
              hascolor={palette.customBlack.lightBlack}
              label={<NumberFormat displayType="text" value={data?.district_admins ?? '-'} thousandSeparator />}
            />
          </StyledNavigationButtonBox>
        </StyledNavigationButton>
      )}
      {isSuperAdmin && (
        <StyledNavigationButton fullWidth>
          <TreeViewNavigation
            mainCategoryItem={benchmarkSidebar.mainCategory}
            subCategoryItems={benchmarkSidebar.subItems}
          />
        </StyledNavigationButton>
      )}
      {isSuperAdmin && (
        <StyledNavigationButton fullWidth onClick={navigateTo('/settings')}>
          <StyledNavigationButtonBox
            sx={{ ...superAdminOptionStyles.buttonBox, ...(isSettings && superAdminOptionStyles.active) }}
          >
            <SettingIcon sx={{ mr: '12px' }} />
            Admin Tools
          </StyledNavigationButtonBox>
        </StyledNavigationButton>
      )}
    </Box>
  );
};

export default SuperAdminNavigation;
