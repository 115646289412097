import React, { useContext, useEffect } from 'react';
import { AppBar, Box, IconButton, Menu, MenuItem, Toolbar } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import {
  HeaderNameStyledTypography,
  HeaderSchoolStyledTypography,
  HeaderStyledNavigationButton,
  StyledAddBtn,
} from './Header.styled';
import InfoIcon from '../Icons/InfoIcon';
import VideoModal from './VideoModal';
import useHeader from './navbar-hook';
import useFeature from '../../hooks/useFeature';
import useRole from '../../hooks/useRole';
import { ImitationBanner } from '../ImitationBanner';
import { SHARED_BUCKET_URL } from '../../variables/constant';
import { SearchContext } from '../../core/searchContext';
import SearchBar from '../SearchBar';
import usePagination from '../../utils/usePagination';
import { palette } from '../../theme/palette';

const styles = {
  appBar: {
    borderBottom: `2px solid ${palette.customBackground.disabled}`,
    position: 'sticky',
  },
  toolBar: {
    paddingLeft: 0,
    justifyContent: 'space-between',
  },
  dropdownPaper: {
    '& div.MuiMenu-paper': {
      width: '280px',
    },
  },
  separator: {
    backgroundColor: palette.customBackground.disabled,
    height: '50px',
    width: '1px',
  },
  infoIcon: {
    width: '24px',
    height: '24px',
  },
};

interface Props {
  imitationUserId?: String;
}

export const Header = ({ imitationUserId }: Props) => {
  const history = useHistory();
  const {
    anchorEl,
    user,
    isMenuOpen,
    handleProfileMenuOpen,
    handleMenuClose,
    signOut,
    showVideoModal,
    hideVideoModal,
    showVideo,
  } = useHeader();
  const pagination = usePagination();
  const { isEnabled: isPasswordFlowEnable } = useFeature('PASSWORD_FLOW');
  const { isTeacher, isStudent, isSuperAdmin } = useRole();
  const { isEnabled: isDemoFeatureEnabled } = useFeature('DEMO_FEATURES');
  const { placeholder, isSearchEnabled, searchTerm, onSearch, setSearchEnabled, setTabSection } =
    useContext(SearchContext);
  const navigateToResetPassword = () => {
    history.push('/reset-password');
  };

  useEffect(() => {
    setTabSection?.('');
    setSearchEnabled?.();
  }, []);
  const setSearchTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    pagination.setPage(1);
    onSearch?.(e);
  };

  const renderProfileMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id="primary-account-menu"
      transformOrigin={{
        vertical: -40,
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      disableAutoFocusItem
      sx={{ ...styles.dropdownPaper }}
    >
      {isTeacher && isPasswordFlowEnable && (
        <MenuItem onClick={navigateToResetPassword} key={1}>
          Reset Password
        </MenuItem>
      )}
      <MenuItem onClick={signOut} key={2}>
        Sign out
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar color="inherit" sx={{ ...styles.appBar }}>
        {imitationUserId && <ImitationBanner />}
        <Toolbar sx={{ ...styles.toolBar }}>
          {isSuperAdmin && isSearchEnabled ? (
            <Box>
              <SearchBar
                handleChange={setSearchTerm}
                searchTerm={searchTerm}
                placeholder={placeholder}
                mt={0}
                mb={0}
                isCommon
              />
            </Box>
          ) : (
            <div />
          )}
          <Box display="flex">
            {isStudent && (
              <>
                <IconButton
                  key="info"
                  aria-label="Video Tutorial"
                  onClick={showVideoModal}
                  disableTouchRipple
                  disableRipple
                >
                  <InfoIcon pathFill={palette.customBlue.primaryBlue} sx={{ ...styles.infoIcon }} />
                </IconButton>
                <Box sx={{ ...styles.separator }} />
              </>
            )}
            {isSuperAdmin && isDemoFeatureEnabled && (
              <StyledAddBtn
                variant="contained"
                color="primary"
                component={Link}
                to="/admin/create/demo"
                startIcon={<AddIcon />}
              >
                Add Demo Accounts
              </StyledAddBtn>
            )}
            <HeaderStyledNavigationButton
              aria-label="account of current user"
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <HeaderNameStyledTypography>{`${user?.first_name} ${user?.last_name}`}</HeaderNameStyledTypography>
                <HeaderSchoolStyledTypography>{user?.school}</HeaderSchoolStyledTypography>
              </Box>
            </HeaderStyledNavigationButton>
          </Box>
        </Toolbar>
      </AppBar>
      {!imitationUserId && renderProfileMenu}
      {showVideo && (
        <VideoModal
          open={showVideo}
          onClose={hideVideoModal}
          url={`${SHARED_BUCKET_URL}/videos/teacher-toolbox-videos/primary-student-tutorial.mp4`}
          showCloseButton
        />
      )}
    </>
  );
};
