/* eslint-disable react/jsx-props-no-spreading,react/prop-types */
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import { svgStyles } from '../Common/Common.styled';
import { AutoCompleteTextField } from '../Autocomplete/Autocomplete.styled';
import { ClassFilterFragmentFragment, SectionCategory } from '../../generated/graphql';
import useClassesFilter from './class-filter-hook';
import ClassesIcon from '../Icons/ClassesIcon';
import { palette } from '../../theme/palette';
import { List, ListItem } from '@mui/material';

interface Props {
  error?: boolean;
  className?: string;
  value?: ClassFilterFragmentFragment | null | string;
  onChange?: (
    data: ClassFilterFragmentFragment | null | string,
    oldValue: ClassFilterFragmentFragment | null | string
  ) => void;
  placeholder?: string;
  idSelectOnly?: boolean;
  stateId?: string | undefined;
  districtId?: string | undefined;
  schoolId?: string | undefined;
  teacherId?: string;
  disabled?: boolean;
  classTypes?: SectionCategory[];
}

type ClassValue = ClassFilterFragmentFragment | null | undefined;

const ClassFilter = ({
  className,
  placeholder,
  onChange,
  value,
  idSelectOnly = false,
  stateId,
  districtId,
  schoolId,
  teacherId,
  classTypes,
}: Props) => {
  const { sections, loading, setSearch } = useClassesFilter({ stateId, districtId, schoolId, teacherId, classTypes });
  const onSelectChange = (_: any, section: ClassFilterFragmentFragment | null) => {
    onChange?.(idSelectOnly ? section?.id ?? '' : section, value as string);
  };
  const selectedValue: ClassValue = idSelectOnly
    ? sections?.find((item) => item.id === value) ?? null
    : (value as ClassValue);

  const [inputValue, setInputValue] = React.useState('');

  const updateSearch = (searchValue: string) => {
    setInputValue(searchValue);
    setSearch(searchValue);
  };

  return (
    <Autocomplete
      freeSolo={sections?.length ? true : false}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        updateSearch(newInputValue);
      }}
      className={className}
      options={sections || []}
      getOptionLabel={(option: any) => option?.name}
      isOptionEqualToValue={(option: any, selected: any) => option?.id === selected?.id}
      onChange={onSelectChange}
      value={selectedValue}
      loading={loading}
      renderOption={(props, option: ClassValue) => (
        <List key={option?.id} sx={{ p: '0px' }}>
          <ListItem {...props}>{option?.name ?? ''}</ListItem>
        </List>
      )}
      renderInput={(params) => (
        <AutoCompleteTextField
          {...params}
          placeholder={placeholder}
          key={params.id}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            style: { fontWeight: 800 },
            startAdornment: (
              <InputAdornment position="start">
                <ClassesIcon pathfill={palette.customBlue.primaryBlue} sx={{ ...svgStyles.icon }} />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default React.memo(ClassFilter);
