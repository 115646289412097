import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Layout from '../../components/Layout';
import { AssignmentData } from './AssignmentData';
import LearningGoals from './LearningGoals';
import DistrictWideComparisons from './DistrictWideComparisons';
import RoutingPaths from '../RoutingPath';
import { useAdminAnalytics } from './admin-analytics-hook';

const AdminAnalytics = () => {
  const {
    isDistrictAdmin,
    myDistrictDataLoading,
    myDistrictData,
    isSchoolAdmin,
    mySchoolDataLoading,
    mySchoolData,
    academicSessionLoading,
    academicSession,
    lastSnowflakeUpdateTimeData,
    getLastSnowflakeUpdateTimeLoading,
  } = useAdminAnalytics();
  const additionalProps = {
    isDistrictAdmin,
    myDistrictDataLoading,
    myDistrictData,
    isSchoolAdmin,
    mySchoolDataLoading,
    mySchoolData,
    academicSessionLoading,
    academicSession,
    lastSnowflakeUpdateTimeData,
    getLastSnowflakeUpdateTimeLoading,
  };
  const dwAdditionalProps = {
    lastSnowflakeUpdateTimeData,
    getLastSnowflakeUpdateTimeLoading,
  };
  return (
    <>
      {!academicSessionLoading && !!academicSession && (
        <Layout>
          <Switch>
            <Route
              path={RoutingPaths.Performance}
              render={(routeProps) => <AssignmentData {...routeProps} {...additionalProps} />}
              exact
            />
            <Route
              path={RoutingPaths.LearningGoals}
              render={(routeProps) => <LearningGoals {...routeProps} {...additionalProps} />}
              exact
            />
            <Route
              path={[RoutingPaths.DistrictWideComparison, RoutingPaths.TabKeyDistrictWideComparison]}
              render={(routeProps) => <DistrictWideComparisons {...routeProps} {...dwAdditionalProps} />}
              exact
            />
            <Redirect from={RoutingPaths.AdminAnalytics} to={RoutingPaths.Performance} />
          </Switch>
        </Layout>
      )}
    </>
  );
};

export default AdminAnalytics;
