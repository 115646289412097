import { PaletteColorOptions, PaletteOptions, TypeBackground } from '@mui/material';

export interface CustomGrey {
  main?: string;
  lighterGrey?: string;
  opaquePrimary?: string;
  lightGrey?: string;
  adminButtonBorder?: string;
  sgiLightGray?: string;
  lightShedGrey?: string;
  darkGrey?: string;
  silver?: string;
  iconGrade?: string;
  dragBorder?: string;
  lightVinyl?: string;
  promptBackground?: string;
  calendarGrey?: string;
  submissionGrey?: string;
  lineGrey?: string;
}

export interface CustomBlue {
  main: string;
  activeGoalVocabsAndGrammar?: string;
  aquaBlue?: string;
  oldTheme?: string;
  lightBlue?: string;
  activeGoalVocabulary?: string;
  primaryBlue?: string;
  primaryLight?: string;
  primaryDark?: string;
  darkBlue?: string;
  flashlightBlue?: string;
  darkBlueTanned?: string;
  darkMagenta?: string;
  primaryPurple?: string;
  cornflowerBlue?: string;
  activeGoalDescriptionAndExplanation?: string;
  darkestBlue?: string;
  filterColor?: string;
  chipDark?: string;
  darkIcon?: string;
  waterBlue?: string;
  fillBlue?: string;
  purpleBright?: string;
  toolTipIconBackground?: string;
  purple?: string;
  lightSky?: string;
}

export interface CustomBlack {
  main?: string;
  scrollBarBackgroundColor?: string;
  lightBlack?: string;
  dimmedBlack?: String;
  fontBlack?: string;
  textBlack?: string;
}

export interface CustomWhite {
  main?: string;
  lightWhite?: string;
  whiteSmoke?: string;
  inputDisabled?: string;
}

export interface CustomRed {
  main?: string;
  subRed?: string;
  buttonFontRed?: string;
  darkRed?: string;
  darkFont?: string;
  darkestRed?: string;
  countBackgroundRed?: string;
  notSubmitted?: string;
  progressPrimary?: string;
  scoreDisabled?: string;
  deleteButton?: string;
  deleteButtonShadow?: string;
}

export interface CustomGreen {
  main?: string;
  activeGoalGrammar?: string;
  darkerGreen?: string;
  fontGreen: string;
  progressSecondary?: string;
  submitted?: string;
  darkParrot?: string;
  activeGoalFluency?: string;
  countBackgroundGreen?: string;
  graphTertiaryGreen: string;
}

export interface CustomYellow {
  main?: string;
  secondary?: string;
  darkMustard?: string;
  buttonFontOrange?: string;
  dandelion?: string;
  secondaryDark?: string;
}

export interface CustomOrange {
  main?: string;
  darkOrange?: string;
  flashlightSaffron?: string;
  countBackgroundOrange?: string;
  flashlightIconColor?: string;
}

export interface CustomPink {
  main?: string;
  darkPink?: string;
  persianPinkOverlay?: string;
  persianPink?: string;
  activeGoalPointsAndReason?: string;
}

export interface Gradients {
  controlGradient?: string;
  scrollMenuGradient?: string;
  loginGradient?: string;
  dataComingSoonGradient?: string;
  overlayShadowGradient?: string;
}

export interface FontColors {
  fontBlack?: string;
  buttonFontGreen?: string;
  buttonFontOrange?: string;
  buttonFontRed?: string;
  darkFont?: string;
}

export interface Overlays {
  overlay?: string;
  imageBlue?: string;
  emerald?: string;
  carnation?: string;
  dodgerBlue?: string;
  tanHide?: string;
  dandelion?: string;
  cornflowerBlue?: string;
  persianPink?: string;
  spray?: string;
}

export interface CustomBackground {
  paperBackground?: string;
  disabled?: string;
  dragBackground?: string;
  loginBackground?: string;
  graphBackgroundColor?: string;
  tagBackground?: string;
  iconButtonBackground?: string;
  disabledInputColor?: string;
  emptyBarBackground?: string;
  selectedAreaBackground?: string;
}

export interface Errors {
  main?: string;
  dark?: string;
}

export interface Transparent {
  main?: string;
}

export interface Shadow {
  main?: string;
}

export const customGrey: CustomGrey = {
  main: '#E8E8E8',
  lighterGrey: '#F8F8F8',
  opaquePrimary: '#DFF2FF',
  lightGrey: '#F9F9F9',
  adminButtonBorder: '#D1D5DB',
  sgiLightGray: '#AAAAAA',
  lightShedGrey: '#4D4D4D',
  darkGrey: '#9B9B9B',
  silver: '#C4C4C4',
  iconGrade: '#9CA3AF',
  dragBorder: '#E5E7EB',
  lightVinyl: '#C2E2F9',
  promptBackground: '#D8D8F3',
  calendarGrey: '#8D8D8D',
  submissionGrey: '#D9D9D9',
  lineGrey: '#CCCCCC',
};

export const customBlue: CustomBlue = {
  main: 'blue',
  activeGoalVocabsAndGrammar: '#B1C6FD',
  aquaBlue: '#0094FF',
  oldTheme: '#42B0FF',
  lightBlue: '#236DE3',
  activeGoalVocabulary: '#236696',
  primaryBlue: '#2A2A8B',
  primaryLight: '#3B3BC4',
  primaryDark: '#18184E',
  darkBlue: '#1920A3',
  flashlightBlue: '#150C7E',
  darkBlueTanned: '#3C39FF',
  darkMagenta: '#0182E0',
  primaryPurple: '#3B17A0',
  cornflowerBlue: '#7270fe',
  activeGoalDescriptionAndExplanation: '#7B59D9',
  darkestBlue: '#31405C',
  filterColor: '#374151',
  chipDark: '#6B7280',
  darkIcon: '#111827',
  waterBlue: '#0090F9',
  fillBlue: '#2a2a8b26',
  purpleBright: '#BF40BF',
  toolTipIconBackground: '#2A2A8B',
  purple: '#7270FE',
  lightSky: '#B2DFFF',
};

export const customBlack: CustomBlack = {
  main: 'black',
  scrollBarBackgroundColor: 'rgba(0, 0, 0, .5)',
  lightBlack: '#4B5563', // disabled
  dimmedBlack: '#616D7D',
  fontBlack: '#333333',
  textBlack: '#6B717A',
};

export const customWhite: CustomWhite = {
  main: '#FFFFFF',
  lightWhite: '#fbfbfb',
  whiteSmoke: '#F7F7F7',
  inputDisabled: '#F4F4F4',
};

export const customRed: CustomRed = {
  main: '#F65050',
  subRed: '#F62222',
  buttonFontRed: '#F86464', // deleteButton
  darkRed: '#EA4335',
  darkFont: '#7D7D7D',
  darkestRed: '#9B360B', // error.dark
  countBackgroundRed: 'rgba(248, 100, 100, 0.2)',
  notSubmitted: '#EBEBEB',
  progressPrimary: '#E5E5E5',
  scoreDisabled: '#C5C5C5',
  deleteButton: '#D12E2E',
  deleteButtonShadow: '#971A1A',
};

export const customGreen: CustomGreen = {
  main: '#00AFAA',
  activeGoalGrammar: '#75DDDD',
  darkerGreen: '#43D56A',
  fontGreen: '#2BCC57', // submitted
  progressSecondary: '#43D46A',
  submitted: '#2BCC57',
  darkParrot: '#19A940',
  activeGoalFluency: '#09BC8A',
  countBackgroundGreen: 'rgba(43, 204, 87, 0.2)',
  graphTertiaryGreen: '#4ca48a',
};

export const customYellow: CustomYellow = {
  main: '#FBDA67',
  secondary: '#FFD542',
  darkMustard: '#EFBD06',
  buttonFontOrange: '#fa965e', // tanHide
  dandelion: '#FFDD65',
  secondaryDark: '#FFB547',
};

export const customOrange: CustomOrange = {
  main: '#FFA16C',
  darkOrange: '#F86411',
  flashlightSaffron: '#ff5200',
  flashlightIconColor: '#DF4C1D',
  countBackgroundOrange: 'rgba(250, 150, 94, 0.2)',
};

export const customPink: CustomPink = {
  main: '#FF7E7E',
  darkPink: '#ff6161',
  persianPinkOverlay: 'rgba(252, 124, 201, 0.5)',
  persianPink: '#FC7CC9',
  activeGoalPointsAndReason: '#E5A4F5',
};

export const gradients: Gradients = {
  controlGradient: '#777777',
  scrollMenuGradient: 'rgba(51, 51, 51, 0)',
  loginGradient: 'linear-gradient(140.97deg, rgba(234, 115, 79, 0.75) 14.74%, rgba(255, 168, 116, 0.82) 84.14%);',
  dataComingSoonGradient: 'linear-gradient(0deg, rgba(51, 51, 51, 0.5) 0%, rgba(51, 51, 51, 0) 100%)',
  overlayShadowGradient: 'linear-gradient(180deg, rgba(51, 51, 51, 0) 0%, #333333 100%)',
};

export const fontColors: FontColors = {
  fontBlack: customBlack.fontBlack,
  buttonFontGreen: customGreen.fontGreen,
  buttonFontOrange: customYellow.buttonFontOrange,
  buttonFontRed: customRed.buttonFontRed,
  darkFont: customRed.darkFont,
};

export const overlays: Overlays = {
  overlay: 'rgba(0,0,0,0.8)',
  imageBlue: 'rgba(66, 176, 255, 0.5)',
  emerald: 'rgba(67, 212, 106, 0.5)',
  carnation: 'rgba(246, 80, 80, 0.5)',
  dodgerBlue: 'rgba(66, 176, 255, 0.5)',
  tanHide: 'rgba(250, 150, 94, 0.5)',
  dandelion: 'rgba(255, 221, 101, 0.5)',
  cornflowerBlue: 'rgba(114, 112, 254, 0.5)',
  persianPink: 'rgba(252, 124, 201, 0.5)',
  spray: 'rgba(129, 246, 239, 0.5)',
};

export const primary: PaletteColorOptions = {
  main: customBlue.primaryBlue ?? '',
  light: customBlue.primaryLight,
  dark: '#18184E',
};

export const transparent: Transparent = {
  main: 'rgba(0,0,0,0)',
};

export const shadow = {
  main: 'rgba(0, 0, 0, 0.2)',
};

export const secondary: PaletteColorOptions = {
  main: customYellow.main ?? '',
  light: customWhite.main,
  dark: customGrey.darkGrey,
};

export const background = {
  default: customWhite.main,
  paper: customWhite.main,
};

export const customBackground: CustomBackground = {
  paperBackground: '#222d32',
  disabled: '#E5E5E5',
  dragBackground: '#F3F4F6',
  loginBackground: '#F2F2F2',
  emptyBarBackground: '#EFEFEF',
  graphBackgroundColor: 'rgba(229, 229, 229, 0.6)',
  tagBackground: 'rgba(51,51,51,0.7)',
  iconButtonBackground: 'rgba(42,42,139,0.04)',
  disabledInputColor: 'rgba(0,0,0,0.35)',
  selectedAreaBackground: 'rgba(42, 42, 139, 0.30)',
};

export const errors: Errors = {
  main: customRed.main,
  dark: customRed.darkestRed,
};

interface PaletteExtensions {
  customGrey: CustomGrey;
  customWhite: CustomWhite;
  customBlue: CustomBlue;
  customRed: CustomRed;
  customBlack: CustomBlack;
  customGreen: CustomGreen;
  customYellow: CustomYellow;
  gradients: Gradients;
  fontColors: FontColors;
  overlays: Overlays;
  background: TypeBackground;
  errors: Errors;
  transparent: Transparent;
  customBackground: CustomBackground;
  shadow: Shadow;
  customPink: CustomPink;
  customOrange: CustomOrange;
}

declare module '@mui/material/styles' {
  interface Palette extends PaletteExtensions {}
  interface PaletteOptions extends PaletteExtensions {}
}

export const palette: PaletteOptions = {
  primary: { ...primary },
  secondary: { ...secondary },
  background,
  customGrey,
  customWhite,
  customBlue,
  customBlack,
  customRed,
  customGreen,
  customYellow,
  gradients,
  fontColors,
  overlays,
  errors,
  transparent,
  customBackground,
  shadow,
  customPink,
  customOrange,
};

export const OVERLAY_COLORS = [
  overlays.emerald,
  overlays.carnation,
  overlays.dodgerBlue,
  overlays.tanHide,
  overlays.dandelion,
  overlays.cornflowerBlue,
  overlays.persianPink,
  overlays.spray,
];

export function getColorsForScore(score: number | undefined) {
  const styles = {
    tagDisabled: {
      background: palette.customGrey.iconGrade!,
      color: palette.customWhite.main!,
    },
    tagRed: {
      background: palette.customRed.buttonFontRed!,
      color: palette.customWhite.main!,
    },
    tagYellow: {
      background: palette.customYellow.main!,
      color: palette.customBlack.fontBlack!,
    },
    tagBlue: {
      background: palette.customBlue.waterBlue!,
      color: palette.customWhite.main!,
    },
    tagGreen: {
      background: palette.customGreen.fontGreen,
      color: palette.customWhite.main!,
    },
    tagPurple: {
      background: palette.customBlue.primaryBlue!,
      color: palette.customWhite.main!,
    },
  };
  if (score === undefined) return styles.tagDisabled;
  if (0 < score && score < 1.5) return styles.tagRed;
  if (1.5 <= score && score < 2.5) return styles.tagYellow;
  if (2.5 <= score && score < 3.5) return styles.tagGreen;
  if (3.5 <= score && score < 4.5) return styles.tagBlue;
  if (4.5 <= score && score <= 5) return styles.tagPurple;
  return styles.tagDisabled;
}
