import React from 'react';
import StyledCard from '../../../../components/StyledCard';
import NoResults from '../../../../components/NoResults';
import EmptyResults from '../../../../components/Icons/EmptyResults';
import PerformanceTable from '../PerformanceTable';
import { SingleBarGraphChart } from '../../../../components/Charts/Graph/SingleBarGraphChart';
import SingleBarGraphLoader from '../../../../components/Loader/SingleBarGraphLoader';
import { GraphSetData } from '../../../../variables/types';
import { palette } from '../../../../theme/palette';

interface Props {
  loading: boolean;
  data: GraphSetData[] | null;
  totalCount: number;
  handleTooltipClick: (data: GraphSetData | null, skillScore: string | undefined) => void;
  title: string;
  buttonTitle?: string;
  handleButtonClick: () => void;
  skillTypes?: string;
}

const Performance = ({
  loading,
  totalCount,
  data,
  handleTooltipClick,
  handleButtonClick,
  buttonTitle = 'View Report',
  title,
  skillTypes,
}: Props) => {
  const hasData = !loading && totalCount > 0;
  const getData = () =>
    data?.reduce(
      (prevValue, currentValue) => {
        const colorKey = `${currentValue?.skill_key}_key_color`;
        const skillKey = `${currentValue?.skill_key}_key`;
        const skillDescription = `${currentValue?.skill_key}_key_description`;
        const skillLevel = `${currentValue?.skill_key}_key_level`;
        const skillLabel = `${currentValue?.skill_key}_key_label`;
        const skillType = `${currentValue?.skill_key}_key_type`;
        const numberOfStudents = `${currentValue?.skill_key}_key_numberOfStudents`;
        const students = `${currentValue?.skill_key}_key_students`;
        return {
          ...prevValue,
          [skillKey]: currentValue?.total_count ?? 0,
          [colorKey]: currentValue?.color,
          [skillDescription]: currentValue?.skill_description,
          [skillLevel]: currentValue?.skill_level,
          [skillLabel]: currentValue?.skill_label,
          [skillType]: currentValue?.skillType,
          [numberOfStudents]: currentValue?.numberOfStudents,
          [students]: currentValue?.students,
        };
      },
      {
        name: 'Performance',
      }
    );

  return (
    <StyledCard title={title} buttonTitle={hasData ? buttonTitle : undefined} onClick={handleButtonClick} columnSetting>
      <>
        {hasData && data && (
          <SingleBarGraphChart
            boxWidth={600}
            data={getData()}
            onTooltipLinkClick={(current, skillScore) => {
              handleTooltipClick(current?.payload ?? null, skillScore);
            }}
          />
        )}
        {hasData && data && <PerformanceTable data={data} skillType={skillTypes} skillCount={totalCount} />}
        {!loading && totalCount === 0 && (
          <NoResults
            description="No scores recorded yet"
            svgComponent={<EmptyResults fillColor={palette.customBackground.disabled} />}
          />
        )}
        {loading && <SingleBarGraphLoader />}
      </>
    </StyledCard>
  );
};

export default React.memo(Performance);
