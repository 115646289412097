import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

type StyledBoxWrapperProps = {
  overridePadding?: string | number | undefined;
};

const StyledBoxWrapper = styled(Box, {
  shouldForwardProp: (prop) => !['overridePadding'].includes(prop as string),
})<StyledBoxWrapperProps>(({ overridePadding, theme }) => ({
  padding: overridePadding || 24,
  borderRadius: 12,
  position: 'relative',
  backgroundColor: theme.palette.customWhite.main,
  border: `2px solid ${theme.palette.customBackground.disabled}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export default StyledBoxWrapper;
