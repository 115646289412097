import React, { useContext } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link, useLocation } from 'react-router-dom';
import { Box, Button, SxProps, Typography, useTheme } from '@mui/material';
import { BreadcrumbItem, useBreadcrumb } from '../../core/breadcrumbContext';
import { SearchContext } from '../../core/searchContext';

interface Breadcrumb {
  breadcrumb: BreadcrumbItem[];
}

const styles = {
  root: {
    '& > * + *': {
      marginTop: '16px',
    },
    marginBottom: '8px',
  },
};

const AppBreadcrumbs = ({ breadcrumb }: Breadcrumb) => {
  const { breadcrumbs } = useBreadcrumb({ breadcrumbItems: breadcrumb });
  const { setTabSection } = useContext(SearchContext);
  const location = useLocation();
  const theme = useTheme();
  let currentRoutes = [];
  currentRoutes = location.pathname !== '/' ? location.pathname.split('/') : [];
  if (currentRoutes.length > 0) {
    currentRoutes.shift();
    return (
      <Box sx={{ ...styles.root }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {breadcrumbs.map((item: BreadcrumbItem, index: number) =>
            index !== breadcrumbs.length - 1 ? (
              <Button
                key={index}
                component={Link}
                to={item.route!}
                sx={
                  {
                    borderWidth: 0,
                    borderRadius: 0,
                    fontWeight: 800,
                    fontSize: 18,
                    color: theme.palette.primary.main,
                    textTransform: 'uppercase',
                    lineHeight: '20px',
                    letterSpacing: 1,
                    textDecoration: 'none',
                    '&:first-of-type': {
                      paddingLeft: 0,
                    },
                    '&:hover': {
                      textDecoration: 'none',
                      cursor: 'pointer',
                      backgroundColor: 'transparent',
                    },
                    '&:active': {
                      borderWidth: 0,
                      borderRadius: 0,
                    },
                    '&::after': {
                      display: 'none',
                    },
                  } as SxProps
                }
                onClick={() => {
                  setTabSection?.('');
                }}
              >
                {item.label}
              </Button>
            ) : (
              <Typography key={index} variant="breadcrumbs">
                {item.label}
              </Typography>
            )
          )}
        </Breadcrumbs>
      </Box>
    );
  }
  return <></>;
};

export default AppBreadcrumbs;
