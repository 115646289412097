import { useHistory, useParams } from 'react-router-dom';
import {
  useClassResourceDetailsQuery,
  useDeleteClassRequestMutation, useDeleteDistrictRequestMutation,
  UserPermissions,
  useSectionDetailQuery
} from '../../../generated/graphql';
import usePermission from '../../../hooks/usePermission';
import useRole from '../../../hooks/useRole';
import {openSnackbar} from "../../../components/Notifier";
import {NotifierType} from "../../../variables/types";
import getErrorMessage from "../../../utils/getErrorMessage";

interface RouteProp {
  id: string
}

const useClass = () => {
  const history = useHistory();
  const { id } = useParams<RouteProp>();
  const { data, loading } = useSectionDetailQuery({
    fetchPolicy: 'network-only',
    variables: {
      id,
    },
  });

  const { data: resourceData, loading: resourceDetailsLoading } =  useClassResourceDetailsQuery({
    fetchPolicy: 'network-only',
    variables: {
      id,
    },
  })

  const [deleteClassRequest, { loading: deleteClassRequestLoading }] = useDeleteClassRequestMutation();
  const submitDeleteClassRequest = async (id: string) => {
    try {
      await deleteClassRequest({
        fetchPolicy: 'network-only',
        variables: {
          id: id,
        },
      });
      openSnackbar({ message: `${resourceData?.sectionAssociationCount.name} was permanently deleted.` }, NotifierType.Info);
      history.push(`/admin-classes`);
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };

  const { hasPermission: editClassPermission } = usePermission(UserPermissions.EditClasses);
  const { isSuperAdmin, isSchoolAdmin } = useRole();
  return {
    loading: loading || resourceDetailsLoading || deleteClassRequestLoading,
    classDetail: data?.sectionDetails,
    editClassPermission,
    isSuperAdmin, isSchoolAdmin,
    classID: id,
    resourceData,
    submitDeleteClassRequest,
  };
};

export default useClass;
