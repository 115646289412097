import React, { Fragment, useState } from 'react';
import { Box, Modal, Slide } from '@mui/material';
import { ModalTypes } from '../../../../../variables/types';
import { StyledSliderHeader, styles } from '../../../../../components/Flyover';
import { StyledFlyoverBox } from '../../../../../components/Flyover/flyover-styled';
import List from './List';
import {
  PerformanceAndLearningGoalWidgetSlideOutLineItem,
  PerformanceSlideOutSortType,
  PerformanceWidgetSlideOutInput,
} from '../../../../../generated/graphql';
import { Props, useFlyoverInfo } from './flyover-hook';

export function Flyover({ skill, skillLevel, open, onClose, variant = ModalTypes.Drawer }: Props) {
  const [queryInput, setQueryInput] = useState<PerformanceWidgetSlideOutInput>();

  const {
    handleModalOnClose,
    pagination,
    handlePageChange,
    handleSort,
    performanceWidgetSlideoutDataLoading,
    performanceWidgetSlideoutData,
    performanceWidgetSlideoutDataCount,
    isTableEmpty,
  } = useFlyoverInfo({
    queryInput,
    setQueryInput,
    skill,
    skillLevel,
    onClose,
  });

  return (
    <Modal
      open={open!}
      onClose={handleModalOnClose}
      sx={{ display: 'flex', alignItems: 'center' }}
      disableEscapeKeyDown
      disableEnforceFocus
    >
      <Slide in={open} direction="left">
        <StyledFlyoverBox
          sx={{
            ...styles[variant],
            maxWidth: 'calc(100% - 60vh)',
            minWidth: 'inherit',
            width: 'calc(100% - 70vh)',
          }}
        >
          {skill &&
            Object.keys(skill)?.reduce((prevValue: any[], currentValue) => {
              if (currentValue?.endsWith('_key') && currentValue === skillLevel) {
                prevValue.push(
                  <Fragment key={currentValue}>
                    <StyledSliderHeader
                      color={skill?.color}
                      skill={skill}
                      currentValue={currentValue}
                      type={skill?.skill_label}
                      onClick={handleModalOnClose}
                    />
                    <Box>
                      <List
                        isTableEmpty={isTableEmpty}
                        loading={performanceWidgetSlideoutDataLoading!}
                        totalCount={performanceWidgetSlideoutDataCount}
                        pagination={pagination}
                        limit={queryInput?.limit!}
                        sort={queryInput?.sort! as PerformanceSlideOutSortType}
                        order={queryInput?.order_by!}
                        students={
                          performanceWidgetSlideoutData?.nodes as PerformanceAndLearningGoalWidgetSlideOutLineItem[]
                        }
                        handlePageChange={handlePageChange}
                        handleSort={handleSort}
                      />
                    </Box>
                  </Fragment>
                );
              }
              return prevValue;
            }, [])}
        </StyledFlyoverBox>
      </Slide>
    </Modal>
  );
}
