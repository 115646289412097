import React from 'react';
import { Box, Typography } from '@mui/material';
import UploadImageIcon from '../../components/Icons/UploadImageIcon';
import TileButton from '../../components/TileButton';
import usePermission from '../../hooks/usePermission';
import { UserPermissions } from '../../generated/graphql';
import SuperAdminIcon from '../../components/Icons/SuperAdminIcon';
import ResetIcon from '../../components/Icons/ResetIcon';

const LandingPage = () => {
  const { hasPermission } = usePermission(UserPermissions.UploadImage);
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box>
        <Typography variant="pageTitle">Settings</Typography>
      </Box>
      <Box
        mt={3}
        display="grid"
        gridTemplateColumns="repeat(3, 250px)"
        sx={{
          gridGap: 32,
        }}
      >
        {hasPermission && (
          <TileButton label="Upload Images" Icon={UploadImageIcon} redirectionLink="/settings/upload-images" />
        )}
        {hasPermission && (
          <TileButton label="Super Admin Users" Icon={SuperAdminIcon} redirectionLink="/settings/super-admin" />
        )}
        {hasPermission && (
          <TileButton label="Reset Benchmarks" Icon={ResetIcon} redirectionLink="/settings/benchmark-reset" />
        )}
      </Box>
    </Box>
  );
};

export default React.memo(LandingPage);
