import { useEffect, useState } from 'react';
import {
  AdminDashboardFilterInput,
  useAssignmentStatusReportWidgetQuery,
  useStudentCompletionRatesWidgetQuery,
} from '../../../../generated/graphql';
import { AdminDashboards } from '../../../../variables/constant';
import { useDashboardContext } from '../../admin-analytics-hook';

interface QueryInputProps {
  district_id?: string;
  school_id?: string;
  input?: AdminDashboardFilterInput;
}

export const useAssignmentStatusReport = () => {
  const {
    isDistrictAdmin,
    myDistrictData,
    isSchoolAdmin,
    mySchoolData,
    skipGraphQLRequest,
    analyticsFilters: queryFilters,
    updateDashboardWidgetsDataStatus,
    sessionEndDate,
    sessionStartDate,
    startDateSelected,
    endDateSelected,
  } = useDashboardContext();

  const [hasData, setHasData] = useState(0);
  const queryInput: QueryInputProps = {
    input: queryFilters,
  };
  if (isSchoolAdmin && mySchoolData?.id) queryInput.school_id = mySchoolData?.id;
  if (isDistrictAdmin && myDistrictData) queryInput.district_id = myDistrictData?.id;

  const { data: data, loading: assignmentCompletionRatesLoading } = useStudentCompletionRatesWidgetQuery({
    fetchPolicy: 'network-only',
    skip: skipGraphQLRequest(),
    variables: { ...queryInput },
  });

  const { data: assignmentStatusReportData, loading: assignmentStatusReportLoading } =
    useAssignmentStatusReportWidgetQuery({
      fetchPolicy: 'network-only',
      skip: skipGraphQLRequest(),
      variables: { ...queryInput },
    });

  useEffect(() => {
    const assignmentCompletionRatesDataMissing =
      data?.performanceWideStudentCompletionRateData?.performanceWideStudentCompletionRateBar
        .map((rateData) => rateData?.pts_assigned)
        .reduce((total, num) => total + num, 0)!;
    setHasData(assignmentCompletionRatesDataMissing);
    updateDashboardWidgetsDataStatus?.({
      widget: AdminDashboards.performance.assignmentCompletionRatesWidget.name,
      loading: assignmentCompletionRatesLoading,
      dataMissing:
        !assignmentCompletionRatesDataMissing &&
        !data?.performanceWideStudentCompletionRateData?.percentage_of_pts_submitted_and_graded_for_ytd,
    });
  }, [assignmentCompletionRatesLoading, data]);

  return {
    assignmentCompletionRatesLoading,
    assignmentCompletionRateData:
      data?.performanceWideStudentCompletionRateData?.performanceWideStudentCompletionRateBar,
    sessionEndDate,
    sessionStartDate,
    currStartDate: startDateSelected,
    currEndDate: endDateSelected,
    completeData: data,
    hasData,
    overallStatusTotals: assignmentStatusReportData,
  };
};
