import React, { useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import { palette } from '../../theme/palette';
import useRole from '../../hooks/useRole';
import { DistrictResourcesCount } from '../../generated/graphql';
import MoreActions from '../MoreActionsDropdown';
import DecoupleDistrictModal from '../DecoupleDistrictModal';
import DecoupleWarningModal from '../DecoupleWarningModal';
import DeleteDistrictModal from '../DeleteDistrictModal';
import { StyledDeleteButton } from './ResourceDetailHeader.styled';

const styles = {
  icon: {
    marginRight: '8px',
    fill: palette.customBlue.primaryBlue,
    '& path': {
      fill: palette.customBlue.primaryBlue,
    },
  },
  button: {
    fontWeight: 800,
    minWidth: '180px',
    maxHeight: '50px',
    py: 1.5,
    px: 2,
    mx: 1,
  },
  buttonDisabled: {
    backgroundColor: palette.customWhite.main,
    color: palette.customBackground.disabled,
  },
  inputInput: {
    '& .MuiInputBase-input': {
      background: 'none',
      border: 0,
    },
  },
};

interface LinkProps {
  pathname: string;
  state: any;
}
interface Props {
  title: string;
  primaryBtnLabel?: string;
  PrimaryBtnIcon?: any;
  deleteBtnLabel?: string;
  deleteBtnHandler?: Function;
  primaryBtnRedirectionLink?: string | LinkProps;
  secondaryBtnLabel?: string;
  SecondaryBtnIcon?: any;
  secondaryBtnRedirectionLink?: string;
  secondaryBtnOnClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  addNewEntity?: React.ReactNode;
  syncNowRedirectionLink?: string;
  syncNowEnabled?: Boolean | null;
  syncNowShow?: Boolean | null;
  handleSecondryBtnRedirectionClick?: Function;
  districtID?: string;
  resourceCountByDistrict?: DistrictResourcesCount;
  deleteDistrictShow?: boolean;
  isDistrictDeleted?: boolean;
  deleteDistrictRequestHandler?: Function;
  decoupleDistrictHandler?: Function;
  decoupleDistrictShow?: boolean;
  disableMoreOptions?: boolean;
  cognitoUsers?: any;
  decoupleInProgress?: boolean;
}

const ResourceDetailHeader = ({
  title,
  primaryBtnLabel,
  PrimaryBtnIcon,
  deleteBtnLabel,
  deleteBtnHandler = () => {},
  primaryBtnRedirectionLink,
  secondaryBtnLabel,
  SecondaryBtnIcon,
  secondaryBtnRedirectionLink,
  secondaryBtnOnClick,
  addNewEntity,
  syncNowRedirectionLink,
  syncNowEnabled = false,
  syncNowShow = false,
  handleSecondryBtnRedirectionClick = () => {},
  districtID,
  resourceCountByDistrict,
  deleteDistrictShow = false,
  deleteDistrictRequestHandler,
  isDistrictDeleted,
  decoupleDistrictHandler,
  decoupleDistrictShow,
  disableMoreOptions,
  cognitoUsers,
}: Props) => {
  const history = useHistory();

  const { isSuperAdmin } = useRole();
  const [deleteDistrictOpen, setDeleteDistrictOpen] = useState(false);
  const [decoupleDistrictOpen, setDecoupleDistrictOpen] = useState(false);
  const [decoupleWarningOpen, setDecoupleWarningOpen] = useState(false);

  const toggleDeleteDistrictOpen = () => {
    setDeleteDistrictOpen(!deleteDistrictOpen);
  };

  const toggleDecoupleDistrictOpen = () => {
    if (cognitoUsers && cognitoUsers.length > 0) {
      toggleDecoupleWarningOpen();
    } else setDecoupleDistrictOpen(!decoupleDistrictOpen);
  };

  const toggleDecoupleWarningOpen = () => {
    setDecoupleWarningOpen(!decoupleWarningOpen);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <Typography variant="pageTitle" sx={{ wordBreak: 'break-word' }}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={7} container justifyContent="flex-end">
        {secondaryBtnLabel &&
          (secondaryBtnOnClick ? (
            <Button
              variant="outlined"
              color="primary"
              sx={{ ...styles.button }}
              onClick={secondaryBtnOnClick || (() => {})}
              startIcon={SecondaryBtnIcon ? <SecondaryBtnIcon sx={{ ...styles.icon }} /> : undefined}
            >
              {secondaryBtnLabel}
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              component={Link}
              sx={{ ...styles.button }}
              to={secondaryBtnRedirectionLink ?? ''}
              startIcon={SecondaryBtnIcon ? <SecondaryBtnIcon sx={{ ...styles.icon }} /> : undefined}
              onClick={() => handleSecondryBtnRedirectionClick()}
            >
              {secondaryBtnLabel}
            </Button>
          ))}
        {addNewEntity && <>{addNewEntity}</>}
        {primaryBtnLabel && PrimaryBtnIcon && primaryBtnRedirectionLink && (
          <Button
            variant="outlined"
            color="primary"
            component={Link}
            sx={{ ...styles.button }}
            to={primaryBtnRedirectionLink}
            startIcon={
              <PrimaryBtnIcon
                sx={{ ...(deleteDistrictOpen && styles.icon) }}
                fillColor={
                  disableMoreOptions || isDistrictDeleted
                    ? palette.customBackground.disabled
                    : palette.customBlue.primaryBlue
                }
              />
            }
            disabled={disableMoreOptions || isDistrictDeleted}
          >
            {primaryBtnLabel}
          </Button>
        )}
        {deleteBtnLabel && (
          <StyledDeleteButton
            variant="outlined"
            color="primary"
            onClick={() => deleteBtnHandler()}
            sx={{ ...styles.button }}
            startIcon={SecondaryBtnIcon ? <SecondaryBtnIcon sx={{ ...styles.icon }} /> : undefined}
          >
            <Typography variant="sFormHelper" marginBottom={1}>
              {deleteBtnLabel}
            </Typography>
          </StyledDeleteButton>
        )}
        {isSuperAdmin && (syncNowEnabled || deleteDistrictShow || decoupleDistrictShow) && (
          <MoreActions
            handleDelete={toggleDeleteDistrictOpen}
            handleDecouple={toggleDecoupleDistrictOpen}
            handleSync={() => {
              if (!!syncNowRedirectionLink) history.push(syncNowRedirectionLink);
            }}
            deleteDistrictShow={deleteDistrictShow}
            decoupleDistrictShow={decoupleDistrictShow}
            syncShow={syncNowShow && syncNowRedirectionLink}
            variant="contained"
            color="primary"
            disabled={disableMoreOptions}
          />
        )}
      </Grid>
      <DeleteDistrictModal
        title={title}
        districtID={districtID}
        resourceCountByDistrict={resourceCountByDistrict}
        deleteDistrictRequestHandler={deleteDistrictRequestHandler}
        toggleDeleteDistrictOpen={toggleDeleteDistrictOpen}
        deleteDistrictOpen={deleteDistrictOpen}
      />
      {!cognitoUsers && (
        <DecoupleDistrictModal
          title={title}
          districtID={districtID}
          decoupleDistrictHandler={decoupleDistrictHandler}
          toggleDecoupleDistrictOpen={toggleDecoupleDistrictOpen}
          decoupleDistrictOpen={decoupleDistrictOpen}
        />
      )}
      <DecoupleWarningModal
        toggleDecoupleWarningOpen={toggleDecoupleWarningOpen}
        warningOpen={decoupleWarningOpen}
        cognitoUsers={cognitoUsers}
      />
    </Grid>
  );
};

export default ResourceDetailHeader;
