import { useEffect } from 'react';
import {
  OverallPerformanceWidgetInput,
  useEvaluateFlagQuery,
  useOverallPerformanceWidgetQuery,
} from '../../../../generated/graphql';
import { AdminDashboards } from '../../../../variables/constant';
import { DashboardWidgetsDataStatusProps, useDashboardContext } from '../../admin-analytics-hook';

export function useOverallPerformanceInfo() {
  const {
    isDistrictAdmin,
    myDistrictData,
    isSchoolAdmin,
    mySchoolData,
    skipGraphQLRequest,
    updateDashboardWidgetsDataStatus,
    analyticsFilters: queryFilters,
    sessionStartDate,
    sessionEndDate,
    startDateSelected,
    endDateSelected,
  } = useDashboardContext();

  const { data: adminPerformanceDataPhase3 } = useEvaluateFlagQuery({
    fetchPolicy: 'network-only',
    variables: { input: { defaultValue: false, flagKey: 'admin-perf-data' } },
  });

  const queryInput: OverallPerformanceWidgetInput = {
    filters: queryFilters,
  };
  if (isSchoolAdmin && mySchoolData?.id) queryInput.schoolID = mySchoolData?.id;
  if (isDistrictAdmin && myDistrictData?.id) queryInput.districtID = myDistrictData?.id;

  const {
    data: overallPerformanceData,
    loading: overallPerformanceDataLoading,
    error: overallPerformanceDataError,
  } = useOverallPerformanceWidgetQuery({
    fetchPolicy: 'network-only',
    skip: skipGraphQLRequest(),
    variables: { input: queryInput },
  });

  useEffect(() => {
    const dashboardWidgetStatus: DashboardWidgetsDataStatusProps = {
      widget:
        adminPerformanceDataPhase3?.evaluateFlag.on ?? false
          ? AdminDashboards.performance.overallByMonth.name
          : AdminDashboards.performance.overallPerformance.name,
      loading: overallPerformanceDataLoading,
      dataMissing: undefined,
    };
    if (!overallPerformanceDataLoading && !!overallPerformanceData?.overallPerformanceInfo) {
      dashboardWidgetStatus.dataMissing = !overallPerformanceData?.overallPerformanceInfo?.historical_score?.length;
    }
    if (!overallPerformanceDataLoading && overallPerformanceDataError) {
      dashboardWidgetStatus.dataMissing = true;
    }
    updateDashboardWidgetsDataStatus?.({ ...dashboardWidgetStatus });
  }, [overallPerformanceDataLoading, overallPerformanceData, overallPerformanceDataError, adminPerformanceDataPhase3]);

  return {
    overallPerformanceDataLoading,
    overallPerformanceData: overallPerformanceData?.overallPerformanceInfo,
    overallPerformanceDataError,
    sessionStartDate,
    sessionEndDate,
    currStartDate: startDateSelected,
    currEndDate: endDateSelected,
  };
}
