import { useState } from 'react';
import { SubmissionStatusLabels } from '../../../../variables/constant';
import { FlyoverHeaderColor } from '../../../../components/FlyoverHeader';

const useStudentSlideout = () => {
  const [showSlideout, setShowSlideout] = useState<boolean>(false);
  const [entityId, setEntityId] = useState<string>('');
  const [submissionType, setSubmissionType] = useState<string>(SubmissionStatusLabels.Submitted);
  const [submissionColor, setSubmissionColor] = useState<FlyoverHeaderColor>('success');
  const [studentCount, setStudentCount] = useState<number>(0);
  const [studentGradedBy, setStudentGradedBy] = useState<string>('');

  const handleOpenSlideout = (id: string, type: string, initialCount: number, gradedBy?: string) => {
    setEntityId(id);
    setSubmissionType(type);
    setStudentCount(initialCount);
    setStudentGradedBy(gradedBy || '');

    switch (type) {
      case SubmissionStatusLabels.Submitted:
        setSubmissionColor('success');
        break;
      case SubmissionStatusLabels.InProgress:
        setSubmissionColor('warning');
        break;
      case SubmissionStatusLabels.NotStarted:
        setSubmissionColor('error');
        break;
    }
    setShowSlideout(true);
  };

  const handleCloseSlideout = () => {
    setShowSlideout(false);
  };

  return {
    showSlideout,
    handleOpenSlideout,
    handleCloseSlideout,
    submissionColor,
    submissionType,
    studentCount,
    setStudentCount,
    entityId,
    studentGradedBy,
  };
};

export default useStudentSlideout;
