import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { matchPath, useHistory } from 'react-router-dom';
import useScore from './score-hook';
import Loader from '../../components/Loader/loader';
import Layout from '../../components/Layout';
import { ScoreContext } from './score-context';
import { toReadableFormat } from '../../utils/dateFormat';
import useSubmissionQueueHook from './submission-queue-hook';
import PrimaryOutlinedButton from '../../components/PrimaryOutlinedButton';
import { NotifierType, ScoreTabs, SubmissionStatus } from '../../variables/types';
import useSidebarHook from '../../components/Sidebar/sidebar-hook';
import CelebrationCard from '../../components/CelebrationCard';
import {
  CELEBRATION_ACTION,
  CELEBRATION_DATA,
  GRADES_FOR_CELEBRATION,
  QUEUE_END_INFO,
  SAVING_ERROR_TRY_AGAIN_TEXT,
} from '../../variables/constant';
import { shuffleArray } from '../../utils/shuffle';
import { StyledFlexModal } from './ScoreTask.styled';
import { openSnackbar } from '../../components/Notifier';
import ConfirmationScoreTaskModal from './ConfirmationScoreTaskModal';

interface Props {
  body: React.ReactFragment;
  saveTeacherNotes: () => Promise<string | undefined>;
  saveTeacherFeedback: () => Promise<string[]>;
  isRecording?: boolean;
  isPlaying?: boolean;
}

const ScoreTaskLayout = ({
  body,
  saveTeacherFeedback,
  saveTeacherNotes,
  isRecording = false,
  isPlaying = false,
}: Props) => {
  const theme = useTheme();
  // const Styles = StylesCss(theme);

  const {
    id,
    expanded,
    setExpanded,
    loading: submissionLoading,
    submission,
    handleScoreUpdate,
    updating,
    activeGoals,
    fetchingGoals,
    handleGoalStatus,
    updatingStatus,
    addStudentGoal,
    adding,
    refetch,
    submissionsRefetch,
    predefinedGoalsData,
    predefinedLoading,
    predefinedRefetch,
    scoreSummaryOptions,
    submissionGoalInfoRefetch,
    speakingGoalCount,
    writingGoalCount,
    submissionGoalInfoLoading,
    handleAnchorToggleUpdate,
    anchorEnabled,
    anchorRefetch,
    updateToggle,
    submitting,
    handleSubmit,
    isAnchorPlaying,
    setIsAnchorPlaying,
    isListHistory,
  } = useScore();

  const [openModal, setOpenModal] = React.useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const celerbrationDetails = useRef(shuffleArray(CELEBRATION_DATA.imageMessageData));

  useEffect(() => {
    celerbrationDetails.current = shuffleArray(CELEBRATION_DATA.imageMessageData);
  }, [openModal]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const { sidenavPTSubmissionCountRefetch } = useSidebarHook();

  const {
    isLast,
    isValid,
    isFirst,
    handleNext,
    getGoalsCount,
    getGradedCount,
    handlePrevious,
    endQueue,
    handlePreviousSubmission,
    navigationOnExitQueue,
    setSnoozeNotificationTime,
    getSnoozeConfirmationStatus,
  } = useSubmissionQueueHook();

  const history = useHistory();
  const loading = submissionLoading;
  const isSpeakingSection = matchPath(history.location.pathname, `/tasks/:id/score/${ScoreTabs.Speaking}`);
  const handleRedirect = (action: string = '') => {
    if (openModal) handleCloseModal();
    if (action === CELEBRATION_ACTION.DONE) {
      endQueue();
      navigationOnExitQueue(isListHistory);
    } else handleNext(id, isListHistory);
  };

  const skipSubmission = () => {
    if (isLast(id) && getGradedCount()) handleOpenModal();
    else handleNext(id, isListHistory);
  };

  // TODO - Sentry logging userinfo - userId, district info, error
  const saveAndQuit = async () => {
    const saveTeacherNotesErr = await saveTeacherNotes();
    const saveTeacherFeedbackErrs = await saveTeacherFeedback();
    if (saveTeacherNotesErr || saveTeacherFeedbackErrs.length) {
      openSnackbar(
        {
          message: SAVING_ERROR_TRY_AGAIN_TEXT,
        },
        NotifierType.Error
      );
      return;
    }
    endQueue();
    navigationOnExitQueue(isListHistory);
  };

  // TODO - Sentry logging userinfo - userId, district info, error
  const submitAndNext = async () => {
    // TODO - merge review and update feedback request into single request
    const saveTeacherNotesErr = await saveTeacherNotes();
    const saveTeacherFeedbackErrs = await saveTeacherFeedback();
    if (saveTeacherNotesErr || saveTeacherFeedbackErrs.length) {
      openSnackbar(
        {
          message: SAVING_ERROR_TRY_AGAIN_TEXT,
        },
        NotifierType.Error
      );
      return;
    }
    await handleSubmit(id);
    sidenavPTSubmissionCountRefetch();
    if ((isLast(id) && getGradedCount()) || getGradedCount() === GRADES_FOR_CELEBRATION) handleOpenModal();
    else handleNext(id, isListHistory);
  };

  const handleDoneClick = () => {
    endQueue();
    navigationOnExitQueue(isListHistory);
  };

  const displayCelebrationModal = () => {
    if (isLast(id) && getGradedCount()) {
      const { imageUrl, imageTitle, primaryButtonText, primaryMessage, secondaryMessage } = QUEUE_END_INFO;
      return (
        <StyledFlexModal open={openModal}>
          <CelebrationCard
            imageUrl={imageUrl}
            imageTitle={imageTitle}
            primaryButtonText={primaryButtonText}
            primaryMessage={primaryMessage}
            secondaryMessage={secondaryMessage}
            primaryAction={() => handleRedirect(CELEBRATION_ACTION.DONE)}
          />
        </StyledFlexModal>
      );
    }

    if (getGradedCount() === GRADES_FOR_CELEBRATION && getGoalsCount() <= GRADES_FOR_CELEBRATION) {
      const { imageUrl, imageTitle, primaryMessage } = celerbrationDetails.current;
      const { primaryButtonText, secondaryButtonText, secondaryMessageForGoals } = CELEBRATION_DATA;
      return (
        <StyledFlexModal open={openModal}>
          <CelebrationCard
            imageUrl={imageUrl}
            imageTitle={imageTitle}
            primaryButtonText={primaryButtonText}
            secondaryButtonText={secondaryButtonText}
            primaryMessage={primaryMessage}
            secondaryMessage={secondaryMessageForGoals}
            secondaryAction={() => handleRedirect(CELEBRATION_ACTION.DONE)}
            primaryAction={() => handleRedirect(CELEBRATION_ACTION.CONTINUE)}
          />
        </StyledFlexModal>
      );
    }

    if (getGradedCount() === GRADES_FOR_CELEBRATION) {
      const { imageUrl, imageTitle, primaryMessage, secondaryMessage } = celerbrationDetails.current;
      const { primaryButtonText, secondaryButtonText } = CELEBRATION_DATA;
      return (
        <StyledFlexModal open={openModal}>
          <CelebrationCard
            imageUrl={imageUrl}
            imageTitle={imageTitle}
            primaryButtonText={primaryButtonText}
            secondaryButtonText={secondaryButtonText}
            primaryMessage={primaryMessage}
            secondaryMessage={secondaryMessage}
            secondaryAction={() => handleRedirect(CELEBRATION_ACTION.DONE)}
            primaryAction={() => handleRedirect(CELEBRATION_ACTION.CONTINUE)}
          />
        </StyledFlexModal>
      );
    }
    return null;
  };

  return (
    // TODO: revisit the class/styles to be passed to component.
    // <Layout mainClass={Styles.layoutMain}>
    <Layout>
      <ScoreContext.Provider
        value={{
          submission,
          expanded,
          setExpanded,
          handleScoreUpdate,
          updating,
          activeGoals,
          fetchingGoals,
          handleGoalStatus,
          updatingStatus,
          addStudentGoal,
          adding,
          refetch,
          submissionsRefetch,
          predefinedGoalsData,
          predefinedLoading,
          predefinedRefetch,
          submissionGoalInfoRefetch,
          speakingGoalCount,
          writingGoalCount,
          submissionGoalInfoLoading,
          scoreSummaryOptions,
          handleAnchorToggleUpdate,
          anchorEnabled,
          updateToggle,
          anchorRefetch,
          isAnchorPlaying,
          setIsAnchorPlaying,
          isListHistory,
        }}
      >
        <Loader open={loading} />
        <Box display="flex" flexDirection="column" padding="0px 10px">
          {/* Header - {Submission Title - Section} and Skip this PT button */}
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box sx={{ width: '800px', maxWidth: '100%' }}>
              <Typography variant="pageTitle" component="p" sx={{ wordBreak: 'break-word' }}>
                {`${submission?.student?.first_name ?? ''} ${submission?.student?.last_name ?? ''} - ${
                  submission?.assignment?.title ?? ''
                }`}
              </Typography>
              <Typography variant="sLabel" component="p">
                {submission?.submitted_at && toReadableFormat(submission?.submitted_at)}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" gap={2}>
              <Box>
                <PrimaryOutlinedButton
                  label="< PREVIOUS"
                  disabled={isFirst(id)}
                  handleClick={() => handlePreviousSubmission(id)}
                />
              </Box>
              <Box>
                <PrimaryOutlinedButton
                  label="NEXT ASSIGNMENT >"
                  disabled={isLast(id)}
                  handleClick={() => skipSubmission()}
                />
              </Box>
            </Box>
          </Box>

          {/* Section Body */}
          <Box>{body}</Box>

          {/* Bottom - includes "save and quit" and "next - {section}" button  */}
          <Box display="grid" gridTemplateColumns="1fr 1fr 1fr">
            {isValid(id) && (!isFirst(id) || (isFirst(id) && !isSpeakingSection)) && (
              <Box>
                <PrimaryOutlinedButton
                  label="BACK"
                  handleClick={async () => {
                    if (submission?.status !== SubmissionStatus.Reviewed) {
                      const saveTeacherNotesErr = await saveTeacherNotes();
                      const saveTeacherFeedbackErrs = await saveTeacherFeedback();
                      if (saveTeacherNotesErr || saveTeacherFeedbackErrs.length) {
                        openSnackbar(
                          {
                            message: SAVING_ERROR_TRY_AGAIN_TEXT,
                          },
                          NotifierType.Error
                        );
                        return;
                      }
                    }
                    handlePrevious(id);
                  }}
                  disabled={isRecording || isPlaying}
                />
              </Box>
            )}
            <Box gridColumn="3" display="flex" flexDirection="row-reverse">
              <Box>
                {isSpeakingSection ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                      if (submission?.status !== SubmissionStatus.Reviewed) {
                        const saveTeacherNotesErr = await saveTeacherNotes();
                        const saveTeacherFeedbackErrs = await saveTeacherFeedback();
                        if (saveTeacherNotesErr || saveTeacherFeedbackErrs.length) {
                          openSnackbar(
                            {
                              message: SAVING_ERROR_TRY_AGAIN_TEXT,
                            },
                            NotifierType.Error
                          );
                          return;
                        }
                      }
                      history.push(`/tasks/${id}/score/${ScoreTabs.Writing}`);
                    }}
                  >
                    NEXT: WRITING
                  </Button>
                ) : (
                  submission?.status !== SubmissionStatus.Reviewed && (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ width: theme.spacing(19) }}
                      onClick={
                        getSnoozeConfirmationStatus()
                          ? submitAndNext
                          : () => {
                              setOpenConfirmationModal(true);
                            }
                      }
                      disabled={
                        submission?.submission_type_scores?.findIndex((item) => {
                          const index = item.rubric_line_items.findIndex(
                            (rubricLineItem) => rubricLineItem.score_detail_id === ''
                          );
                          return index !== -1;
                        }) !== -1 ||
                        submitting ||
                        isRecording ||
                        isPlaying
                      }
                    >
                      SUBMIT
                    </Button>
                  )
                )}
              </Box>
              <ConfirmationScoreTaskModal
                openModal={openConfirmationModal}
                handleClose={() => setOpenConfirmationModal(false)}
                handleNext={submitAndNext}
                setTimerActive={setSnoozeNotificationTime}
              />
              <Box mr="20px">
                {submission?.status !== SubmissionStatus.Reviewed && (
                  <PrimaryOutlinedButton
                    label="SAVE & QUIT"
                    handleClick={saveAndQuit}
                    disabled={submitting || isRecording || isPlaying}
                  />
                )}
                {submission?.status === SubmissionStatus.Reviewed && (
                  <PrimaryOutlinedButton label="Done" handleClick={handleDoneClick} disabled={isPlaying} />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </ScoreContext.Provider>
      {displayCelebrationModal()}
    </Layout>
  );
};

export default ScoreTaskLayout;
