/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const ResetIcon = (props: any) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    {...props}
  >
    <path
      d="M1.64465 0.880859V4.18664H2.02913M12.1822 5.50896C11.8569 2.89983 9.63118 0.880859 6.93391 0.880859C4.71416 0.880859 2.81377 2.24824 2.02913 4.18664M2.02913 4.18664H4.95044M12.2232 11.4594V8.15359H11.8387M11.8387 8.15359C11.054 10.092 9.15366 11.4594 6.93391 11.4594C4.23664 11.4594 2.01093 9.4404 1.68557 6.83127M11.8387 8.15359H8.91738"
      stroke={palette.customBlue.primaryBlue}
      strokeWidth="1.32231"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={palette.customWhite.main}
    />
  </SvgIcon>
);

export default ResetIcon;
