import React from 'react';
import { TableRow, Typography, useTheme } from '@mui/material';
import { BenchmarkProgressScoreNode, BenchmarkProgressScoreSubmissionType } from '../../../generated/graphql';
import { StyledTableDataColumn, StyledTableDataColumn1 } from '../../Admin/admin-tables-styled';
import { TeacherBenchmarkDashboardStylesCSS } from '../teacherbenchmarkdashboard.styled';
import { useTeacherBenchmarksDashboardContext } from '../teacherbenchmarks-dashboard-hook';
import ArrowUpIcon from '../../../components/Icons/ArrowUpIcon';

import NilIcon from '../../../components/Icons/NilIcon';
import { getColorsForScore, palette } from '../../../theme/palette';
import TruncateStudentName from '../../AdminBenchmarks/List/StudentListSlideout/TruncateStudentName';
import { Link } from 'react-router-dom';
import DownArrowIcon from '../../../components/Icons/DownArrow';

interface Props {
  progressScore: BenchmarkProgressScoreNode;
}

const ProgressRow = ({ progressScore }: Props) => {
  const theme = useTheme();
  const styles = TeacherBenchmarkDashboardStylesCSS(theme);
  const { setLocalTeacherBenchmarkDashboardFilters, selectedClasses, selectedGrades, selectedSchools, benchmarkType } =
    useTeacherBenchmarksDashboardContext();
  const speakingScore: BenchmarkProgressScoreSubmissionType | undefined = progressScore?.score_by_submission_type?.find(
    (score) => score.submission_type === 'Speaking'
  );
  const writingScore: BenchmarkProgressScoreSubmissionType | undefined = progressScore?.score_by_submission_type?.find(
    (score) => score.submission_type === 'Writing'
  );
  return (
    <TableRow key={progressScore?.student_id}>
      <StyledTableDataColumn1>
        {progressScore?.has_reviewed_submissions ? (
          <Link
            to={`/teacher/student-dashboard/${progressScore?.student_user_id}?linkPage=progressscores`}
            style={{ textDecoration: 'none' }}
            onClick={() => {
              setLocalTeacherBenchmarkDashboardFilters?.({
                schools: selectedSchools,
                grades: selectedGrades,
                classes: selectedClasses,
                benchmarkType,
              });
            }}
          >
            <TruncateStudentName
              student={{ first_name: progressScore?.student_first_name, last_name: progressScore?.student_last_name }}
              maxChars={30}
              typographySx={{ fontSize: '18px', fontWeight: 800, color: theme.palette.customBlue.primaryBlue }}
            />
          </Link>
        ) : (
          <TruncateStudentName
            student={{ first_name: progressScore?.student_first_name, last_name: progressScore?.student_last_name }}
            maxChars={30}
            typographySx={{ fontSize: '18px', fontWeight: 800, color: theme.palette.customBlue.chipDark }}
          />
        )}
      </StyledTableDataColumn1>
      <StyledTableDataColumn>
        <Typography
          sx={{
            color: theme.palette.customBlack.fontBlack,
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: 'normal',
          }}
        >
          {progressScore?.grade || ''}
        </Typography>
      </StyledTableDataColumn>

      <StyledTableDataColumn>
        <Typography
          component="span"
          sx={{ ...styles.tag, ...getColorsForScore?.(speakingScore?.boy_score ?? undefined) }}
        >
          {speakingScore?.boy_score || '-'}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn>
        {speakingScore?.boy_to_moy_progress === 'up' ? (
          <ArrowUpIcon />
        ) : speakingScore?.boy_to_moy_progress === 'down' ? (
          <DownArrowIcon />
        ) : (
          <NilIcon />
        )}
      </StyledTableDataColumn>
      <StyledTableDataColumn>
        <Typography
          component="span"
          sx={{ ...styles.tag, ...getColorsForScore?.(speakingScore?.moy_score ?? undefined) }}
        >
          {speakingScore?.moy_score || '-'}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn>
        {speakingScore?.moy_to_eoy_progress === 'up' ? (
          <ArrowUpIcon />
        ) : speakingScore?.moy_to_eoy_progress === 'down' ? (
          <DownArrowIcon />
        ) : (
          <NilIcon />
        )}
      </StyledTableDataColumn>
      <StyledTableDataColumn>
        <Typography
          component="span"
          sx={{ ...styles.tag, ...getColorsForScore?.(speakingScore?.eoy_score ?? undefined) }}
        >
          {speakingScore?.eoy_score || '-'}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn sx={{ borderRight: `2px solid ${palette.customGrey.iconGrade}` }}>
        <Typography
          sx={{
            color: theme.palette.customBlack.fontBlack,
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: 800,
            lineHeight: 'normal',
            letterSpacing: '1px',
          }}
        >
          {speakingScore?.ytd_change || <NilIcon />}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn>
        <Typography
          component="span"
          sx={{ ...styles.tag, ...getColorsForScore?.(writingScore?.boy_score ?? undefined) }}
        >
          {writingScore?.boy_score || '-'}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn>
        {writingScore?.boy_to_moy_progress === 'up' ? (
          <ArrowUpIcon />
        ) : writingScore?.boy_to_moy_progress === 'down' ? (
          <DownArrowIcon />
        ) : (
          <NilIcon />
        )}
      </StyledTableDataColumn>
      <StyledTableDataColumn>
        <Typography
          component="span"
          sx={{ ...styles.tag, ...getColorsForScore?.(writingScore?.moy_score ?? undefined) }}
        >
          {writingScore?.moy_score || '-'}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn>
        {writingScore?.moy_to_eoy_progress === 'up' ? (
          <ArrowUpIcon />
        ) : writingScore?.moy_to_eoy_progress === 'down' ? (
          <DownArrowIcon />
        ) : (
          <NilIcon />
        )}
      </StyledTableDataColumn>
      <StyledTableDataColumn>
        <Typography
          component="span"
          sx={{ ...styles.tag, ...getColorsForScore?.(writingScore?.eoy_score ?? undefined) }}
        >
          {writingScore?.eoy_score || '-'}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn>
        <Typography
          sx={{
            color: theme.palette.customBlack.fontBlack,
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: 800,
            lineHeight: 'normal',
            letterSpacing: '1px',
          }}
        >
          {' '}
          {writingScore?.ytd_change || <NilIcon />}
        </Typography>
      </StyledTableDataColumn>
    </TableRow>
  );
};

export default ProgressRow;
