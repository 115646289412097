import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, Theme, useTheme } from '@mui/material';
import { StyledDialogContentText, StyledDialogTitle } from '../pages/SubmitTask/Common/SubmitTask.styled';
import { sentryMessageLog } from '../utils/sentry';

interface Props {
  openDialog: boolean;
  closeDialog: () => void;
}

const StylesCSS = (theme: Theme) => ({
  root: {
    position: 'relative',
    minWidth: 735,
    overflow: 'hidden',
  },
  title: {
    paddingBottom: 0,
  },
  subTitle: {
    paddingTop: 0,
    marginBottom: theme.spacing(3),
  },
  displayNewLine: {
    whiteSpace: 'pre-line',
  },
  button: {
    border: '1px solid rgba(42, 42, 139, 0.5)',
    padding: '12px 24px',
    '&:hover': { border: '1px solid rgba(42, 42, 139, 0.5)' },
  },
  actionRoot: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
  orderedList: {
    ol: {
      listStyle: 'none',
      padding: '0 0 0 42px',
      counterReset: 'numList',
      li: {
        fontWeight: 400,
        paddingY: '8px',
        position: 'relative',
        '&::before': {
          counterIncrement: 'numList',
          content: 'counter(numList)',

          float: 'left',
          position: 'absolute',
          left: '-42px',

          fontSize: '18px',
          fontWeight: 800,
          textAlign: 'center',
          color: '#fff',
          lineHeight: '28px',

          width: '28px',
          height: '28px',
          background: theme.palette.customBlue.primaryBlue,

          '-moz-border-radius': '50%',
          borderRadius: '50%',
        },
        '& .listItemHeading': {
          fontWeight: 800,
          color: theme.palette.customBlue.primaryBlue,
        },
      },
    },
  },
});

export const WhatsNewModal = ({ openDialog, closeDialog }: Props) => {
  const theme = useTheme();
  const styles = StylesCSS(theme);

  function handleLearnMore(): void {
    sentryMessageLog("What's new Dialog: Learn More Clicked! (help page)", 'info');
    window.open(
      'https://flashlight360.zendesk.com/hc/en-us/articles/27241198998299-Creating-and-Managing-Classes',
      '_blank'
    );
    closeDialog();
  }

  return (
    <Dialog
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: styles.root,
      }}
      onClose={closeDialog}
    >
      <StyledDialogTitle sx={styles.title}>What's New</StyledDialogTitle>
      <DialogContent>
        <StyledDialogContentText
          sx={{
            ...styles.displayNewLine,
            ...styles.subTitle,
            ...styles.orderedList,
            textAlign: 'left',
          }}
        >
          <p>
            Under the Classes tab, you can now create custom classes for your teachers or edit existing custom classes
            (Clever and ClassLink synced classes cannot be edited). It’s as easy as 1-2-3!
          </p>
          <ol>
            <li>
              <span className="listItemHeading">Edit Class Details:</span> Update school, teachers, class name, and
              period.
            </li>
            <li>
              <span className="listItemHeading">Manage Students:</span> Edit class list and search by Name or Student
              ID.
            </li>
            <li>
              <span className="listItemHeading">Confirm Changes:</span> Review, confirm, and view new class on Classes
              page.
            </li>
          </ol>
        </StyledDialogContentText>
      </DialogContent>
      <DialogActions sx={styles.actionRoot}>
        <Box display="flex" alignItems="center" justifyContent="end" width="100%" gap="8px">
          <Button sx={styles.button} variant="outlined" color="primary" onClick={handleLearnMore}>
            Learn More
          </Button>
          <Button sx={styles.button} variant="contained" color="primary" onClick={closeDialog}>
            Okay
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
