import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { GraphSetData } from '../../../../variables/types';
import { palette } from '../../../../theme/palette';

interface Props {
  data: GraphSetData[] | null;
  skillType?: string;
  skillCount?: number;
  customLabel?: boolean;
  fullWidth?: boolean;
  onRowClick?: (skill: GraphSetData) => void;
}

const StylesCSS = (theme: Theme) => ({
  tableContainer: {
    border: 0,
    boxShadow: 'none',
    width: theme.spacing(75),
  },
  colorBand: {
    width: theme.spacing(1.875),
    height: theme.spacing(1.875),
    borderRadius: '50%',
    '&.MuiTypography-root': {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
  skillLabel: {
    width: theme.spacing(14.375),
    '&.MuiTypography-root': {
      fontSize: theme.spacing(2),
      fontWeight: 800,
      lineHeight: '31px',
      color: palette.customBlue.primaryBlue,
    },
  },
  percentageLabel: {
    display: 'inline-block',
    '&.MuiTypography-root': {
      fontSize: theme.spacing(1.75),
      fontWeight: 400,
      lineHeight: '31px',
      width: theme.spacing(6.25),
      marginLeft: theme.spacing(1),
    },
  },
  rowHover: {
    '& tbody tr:nth-of-type(even):hover': {
      backgroundColor: theme.palette.transparent.main,
      cursor: 'arrow',
    },
  },
  skillStudents: {
    fontWeight: 800,
    width: theme.spacing(1.25),
    display: 'inline',
  },
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.customBlack.fontBlack,
  fontSize: theme.spacing(2),
  lineHeight: '31px',
  fontWeight: 700,
  textAlign: 'center',
  borderBottom: 'none',
}));

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  fontSize: theme.spacing(2),
  fontWeight: 600,
  lineHeight: '31px',
  alignItems: 'center',
  borderBottom: 'none',
  textAlign: 'center',
}));

const percentage = (partialValue: number | undefined, totalValue: number | undefined) =>
  (100 * partialValue!) / totalValue!;

const PerformanceTable = ({ onRowClick, data, skillType, skillCount, customLabel, fullWidth = false }: Props) => {
  const theme = useTheme();
  const styles = StylesCSS(theme);
  return (
    <TableContainer
      component={Paper}
      sx={{
        border: 0,
        boxShadow: 'none',
        width: fullWidth ? '100%' : 600,
        overflowY: 'hidden',
      }}
    >
      <Table sx={styles.rowHover}>
        <TableHead
          sx={{
            '&.MuiTableHead-root': {
              backgroundColor: theme.palette.transparent.main,
            },
          }}
        >
          <TableRow>
            <StyledTableCell>{customLabel ? 'Category' : `${skillType} Level`}</StyledTableCell>
            <StyledTableCell># of Students</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <StyledTableRow onClick={() => onRowClick && onRowClick(row)} key={row?.id}>
              <TableCell align="left" style={{ display: 'flex', justifyContent: 'center', borderBottom: 'none' }}>
                <Typography sx={styles.colorBand} style={{ backgroundColor: row?.color }} />
                <Typography sx={styles.skillLabel}>{row?.skill_label}</Typography>
              </TableCell>
              <StyledTableCell2>
                <Typography sx={styles.skillStudents}>{row?.numberOfStudents}</Typography>
                <Typography sx={styles.percentageLabel}>
                  {`(${percentage(row.numberOfStudents, skillCount).toFixed(0)}%)`}
                </Typography>
              </StyledTableCell2>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PerformanceTable;
