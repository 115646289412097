import React from 'react';
import { Box, Typography } from '@mui/material';
import AppTooltip from '../../../../../../components/AppTooltip';
import { AdminBenchmarkReportWidgets, BENCHMARK_TYPE } from '../../../../../../variables/constant';
import StyledCard from '../../../../../../components/StyledCard';
import OverallPerformanceDonutChart from '../../../../../../components/Charts/Graph/OverallPerformanceDonutChart';
import StyledBoxWrapper from '../../../../../../components/StyledCard/StyledCard.styled';
import NoResults from '../../../../../../components/NoResults';
import EmptyBenchmarkResults from '../../../../../../components/Icons/EmptyBenchmarksResults';
import DonutChartLoader from '../../../../../../components/Loader/DonutChartLoader';
import { PieChartData } from '../../benchmark-summary-hook';
import EmptyDonutChart from '../../../../../../components/Charts/Graph/EmptyDonutChart';

interface Props {
  alignTitle?: boolean; // to specify alignment of the component title, default is center.
  enableTooltip?: boolean; // to enable tooltip for this component.
  tooltipTitle?: string; // specifies the text used for Tooltip component.
  boySubmissionPieChartData: PieChartData[] | [];
  moySubmissionPieChartData: PieChartData[] | [];
  eoySubmissionPieChartData: PieChartData[] | [];
  boyTotalSubmissions: number;
  moyTotalSubmissions: number;
  eoyTotalSubmissions: number;
  hasBoyStatusData: boolean;
  hasMoyStatusData: boolean;
  hasEoyStatusData: boolean;
  loading: boolean;
  hasStatusData: boolean;
}

const SubmissionStatusReport = ({
  alignTitle,
  enableTooltip,
  tooltipTitle,
  boySubmissionPieChartData,
  moySubmissionPieChartData,
  eoySubmissionPieChartData,
  boyTotalSubmissions,
  moyTotalSubmissions,
  eoyTotalSubmissions,
  hasBoyStatusData,
  hasMoyStatusData,
  hasEoyStatusData,
  loading,
  hasStatusData,
}: Props) => {
  const title: string = 'Benchmark Submission Status Report';

  return (
    <Box
      id={AdminBenchmarkReportWidgets.BenchmarkSummary.DistrictBenchmarkSubmissionStatusReport.name}
      sx={{ height: '100%', width: '100%' }}
    >
      <StyledCard
        title={title}
        columnSetting
        isToolTip={enableTooltip}
        titleAlignment={alignTitle ? 'left' : 'center'}
        toolTipComponent={<AppTooltip toolTipText={tooltipTitle} />}
        fullHeight
        marginBottom="0px"
      >
        {hasStatusData && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '30px',
              marginTop: '30px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box
                id="submission-status-boy-id"
                sx={{
                  marginBottom: '5px',
                }}
              >
                <Typography align={'center'} variant="h6">
                  {BENCHMARK_TYPE.BOY}
                </Typography>
              </Box>
              <StyledBoxWrapper overridePadding={10}>
                {hasBoyStatusData ? (
                  <OverallPerformanceDonutChart
                    data={boySubmissionPieChartData!}
                    startAngle={90}
                    endAngle={-270}
                    boxWidth={410}
                    chartWidth={390}
                    chartHeight={310}
                    boxHeight={330}
                    dataKey="studentCount"
                    totalCount={boyTotalSubmissions}
                    label
                    innerLabel="Total Benchmarks"
                    paddingAngle={1.5}
                    innerRadius={82}
                  />
                ) : (
                  <EmptyDonutChart dataKey="studentCount" />
                )}
              </StyledBoxWrapper>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box
                id="submission-status-moy-id"
                sx={{
                  marginBottom: '5px',
                }}
              >
                <Typography align={'center'} variant="h6">
                  {BENCHMARK_TYPE.MOY}
                </Typography>
              </Box>
              <StyledBoxWrapper overridePadding={10}>
                {hasMoyStatusData ? (
                  <OverallPerformanceDonutChart
                    data={moySubmissionPieChartData!}
                    startAngle={90}
                    endAngle={-270}
                    boxWidth={410}
                    chartWidth={390}
                    chartHeight={310}
                    boxHeight={330}
                    dataKey="studentCount"
                    totalCount={moyTotalSubmissions}
                    label
                    innerLabel="Total Benchmarks"
                    paddingAngle={1.5}
                    innerRadius={82}
                  />
                ) : (
                  <EmptyDonutChart dataKey="studentCount" />
                )}
              </StyledBoxWrapper>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box
                id="submission-status-eoy-id"
                sx={{
                  marginBottom: '5px',
                }}
              >
                <Typography align={'center'} variant="h6">
                  {BENCHMARK_TYPE.EOY}
                </Typography>
              </Box>
              <StyledBoxWrapper overridePadding={10}>
                {hasEoyStatusData ? (
                  <OverallPerformanceDonutChart
                    data={eoySubmissionPieChartData!}
                    startAngle={90}
                    endAngle={-270}
                    boxWidth={410}
                    chartWidth={390}
                    chartHeight={310}
                    boxHeight={330}
                    dataKey="studentCount"
                    totalCount={eoyTotalSubmissions}
                    label
                    innerLabel="Total Benchmarks"
                    paddingAngle={1.5}
                    innerRadius={82}
                  />
                ) : (
                  <EmptyDonutChart dataKey="studentCount" />
                )}
              </StyledBoxWrapper>
            </Box>
          </Box>
        )}
        {!loading && !hasStatusData && !hasBoyStatusData && !hasMoyStatusData && !hasEoyStatusData && (
          <NoResults
            description={
              AdminBenchmarkReportWidgets.BenchmarkSummary.DistrictBenchmarkSubmissionStatusReport.insufficientData
                .description
            }
            svgComponent={<EmptyBenchmarkResults />}
          />
        )}
        {loading && <DonutChartLoader />}
      </StyledCard>
    </Box>
  );
};

export default SubmissionStatusReport;
