import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import StyledMain from './Layout.styled';
import { useNavigationProvider } from '../../core/navigationContext';
import { NavigationType } from '../../variables/types';
import { useImitationProvider } from '../../core/imitationContext';
import { SearchContext } from '../../core/searchContext';
import useRole from '../../hooks/useRole';
import { WhatsNewBanner, WhatsNewDismissedKey, WhatsNewVersion } from '../WhatsNewBanner';
import { Header } from '../Header/Header';
import Sidebar from '../Sidebar';

type Props = {
  children?: React.ReactNode;
  mainClass?: string;
  padding?: number;
};

const Layout = ({ children, mainClass, padding = 2 }: Props) => {
  const { getImitationUserId } = useImitationProvider();
  const imitationUserId = getImitationUserId();
  const { getNavigationType } = useNavigationProvider();
  const NavType = getNavigationType();
  const NoNavigation = NavType === NavigationType.None;
  const { setSearchEnabled } = useContext(SearchContext);
  useEffect(() => {
    setSearchEnabled?.();
  });
  const { isDistrictAdmin, isSchoolAdmin } = useRole();
  const [shouldSeeWhatsNewBanner, setShouldSeeWhatsNewBanner] = useState(
    (isDistrictAdmin || isSchoolAdmin) && localStorage.getItem(WhatsNewDismissedKey) !== WhatsNewVersion
  );
  return (
    <>
      {!NoNavigation && (
        <Box zIndex={1200}>
          <Sidebar />
        </Box>
      )}
      <Box
        component="div"
        display="flex"
        width="100%"
        flexDirection={'column'}
        height={NoNavigation ? '100vh' : `calc(100vh - ${imitationUserId ? 130 : 75})`}
        sx={{ mt: 0 }}
      >
        {!NoNavigation && <Header imitationUserId={imitationUserId} />}
        <StyledMain component="main" className={mainClass}>
          {shouldSeeWhatsNewBanner && <WhatsNewBanner setShouldSeeWhatsNewBanner={setShouldSeeWhatsNewBanner} />}
          <Box
            sx={{
              pl: padding,
              pr: padding,
              pt: 2,
              pb: 2,
              height: '100%',
            }}
          >
            {children}
          </Box>
        </StyledMain>
      </Box>
    </>
  );
};

export default Layout;
