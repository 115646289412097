import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import useMediaRecorder, { Status } from './useMediaRecorder';
import {
  StopButton,
  StopButtonBlue,
  StyledMicIcon,
  StyledMicOffIcon,
  StyledStopIcon,
  TimeLabel,
} from './Recorder.styled';
import MicIcon from '../Icons/MicIcon';
import MicDisabledIcon from '../Icons/MicDisabledIcon';
import { palette } from '../../theme/palette';
import { useEvaluateFlagQuery } from '../../generated/graphql';

interface Props {
  onRecord: (blob: File) => void;
  delayTime?: number;
  disable: boolean;
  setIsRecording?: Function;
  showElapsedTime?: boolean;
  recordButtonHeight?: number;
  recordButtonWidth?: number;
  maxRecordingTime?: number;
  setHitTimeLimit?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Recorder = ({
  onRecord,
  delayTime,
  disable,
  setIsRecording,
  showElapsedTime = true,
  recordButtonHeight = 72,
  recordButtonWidth = 72,
  maxRecordingTime,
  setHitTimeLimit,
}: Props) => {
  const { elapsedTime, startRecording, stopRecording, status, liveRecordingFrequencyLevel } = useMediaRecorder({
    onError: (err) => console.log(err),
    onStop: (blob) => {
      if (blob) {
        const currentTime = new Date();
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        blob.lastModifiedDate = currentTime;
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        blob.name = currentTime.getTime().toString();
        onRecord(blob as File);
      }
    },
    mediaStreamConstraints: { audio: true, video: false },
  });

  const onStart = () => {
    startRecording(0);
    setIsRecording?.(true);
  };

  const onStop = (hitMaxTimeLimit: boolean) => {
    stopRecording();
    setIsRecording?.(false);
    setHitTimeLimit?.(hitMaxTimeLimit);
  };

  const isRecording = [Status.Recording, Status.Stopping, Status.Paused].includes(status);
  const canRecord = [Status.Idle, Status.AcquiringMedia, Status.Ready, Status.Failed, Status.Stopped].includes(status);

  useEffect(() => {
    if (status === Status.Recording && maxRecordingTime && elapsedTime >= maxRecordingTime) {
      onStop(true);
    }
  }, [maxRecordingTime, status, elapsedTime, onStop]);

  const secsToTimeFormat = () => {
    const mins = Math.floor(elapsedTime / 60);
    const seconds = Math.floor(elapsedTime % 60);
    return `${mins.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const showNewAudioVisualizerDefault = true;
  const { data: stopButtonVisualizer } = useEvaluateFlagQuery({
    fetchPolicy: 'network-only',
    variables: { input: { defaultValue: showNewAudioVisualizerDefault, flagKey: 'audio-visualizer' } },
  });

  const showNewAudioVisualizer = stopButtonVisualizer?.evaluateFlag.on ?? showNewAudioVisualizerDefault;

  return (
    <Box>
      {showElapsedTime && (
        <Box marginBottom={1} display="flex" flexDirection="row" justifyContent="center" alignItems="center">
          <TimeLabel role="timer" aria-label="Elapsed Recording Time">{`${secsToTimeFormat()}`}</TimeLabel>
        </Box>
      )}

      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
        {disable ? (
          <Box marginLeft={4} marginRight={4}>
            <StyledMicOffIcon
              variant="contained"
              disabled
              aria-label="Recording Disabled"
              sx={{ width: recordButtonWidth, height: recordButtonHeight }}
            >
              <MicDisabledIcon pathFill={palette.customBlack.lightBlack} />
            </StyledMicOffIcon>
          </Box>
        ) : (
          <>
            {canRecord && (
              <Box marginLeft={4} marginRight={4}>
                <StyledMicIcon
                  variant="contained"
                  color="primary"
                  sx={{ width: recordButtonWidth, height: recordButtonHeight }}
                  aria-label="Start Recording"
                  onClick={onStart}
                >
                  <MicIcon pathFill={palette.customWhite.main} />
                </StyledMicIcon>
              </Box>
            )}
            {isRecording &&
              (showNewAudioVisualizer ? (
                <Box marginLeft={4} marginRight={4}>
                  <div style={{ margin: -16 }}>
                    <div
                      style={{
                        background: `${
                          !!delayTime && isRecording && delayTime > elapsedTime
                            ? palette.customGrey.iconGrade
                            : palette.customBlue.primaryBlue
                        }${Math.floor(
                          Math.min(liveRecordingFrequencyLevel, 255) *
                            (!!delayTime && isRecording && delayTime > elapsedTime ? 0.25 : 0.25)
                        )
                          .toString(16)
                          .padStart(2, '0')}`,
                        padding: 8,
                        borderRadius: '50%',
                      }}
                    >
                      <div
                        style={{
                          background: `${
                            !!delayTime && isRecording && delayTime > elapsedTime
                              ? palette.customGrey.iconGrade
                              : palette.customBlue.primaryBlue
                          }${Math.floor(
                            Math.min(liveRecordingFrequencyLevel, 255) *
                              (!!delayTime && isRecording && delayTime > elapsedTime ? 0.5 : 0.5)
                          )
                            .toString(16)
                            .padStart(2, '0')}`,
                          padding: 8,
                          borderRadius: '50%',
                        }}
                      >
                        <StopButtonBlue
                          size="large"
                          color="primary"
                          sx={{ width: recordButtonWidth, height: recordButtonHeight }}
                          aria-label="Stop Recording"
                          disabled={!!delayTime && isRecording && delayTime > elapsedTime}
                          onClick={() => onStop(false)}
                          style={{ boxShadow: 'none', transition: 'background-color 1s ease-in' }}
                        >
                          <StyledStopIcon viewBox="3 3 18 18" />
                        </StopButtonBlue>
                      </div>
                    </div>
                  </div>
                </Box>
              ) : (
                <Box marginLeft={4} marginRight={4}>
                  <StopButton
                    size="large"
                    color="primary"
                    sx={{ width: recordButtonWidth, height: recordButtonHeight }}
                    aria-label="Stop Recording"
                    disabled={!!delayTime && isRecording && delayTime > elapsedTime}
                    onClick={() => onStop(false)}
                  >
                    <StyledStopIcon viewBox="3 3 18 18" />
                  </StopButton>
                </Box>
              ))}
          </>
        )}
      </Box>
    </Box>
  );
};
export default React.memo(Recorder);
