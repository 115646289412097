import * as React from 'react';

const styles = {
  icon: {
    width: '25px',
    height: '25px',
  },
};

type IconBase64Props = {
  x?: number;
  y?: number;
  payload?: {
    value: string;
  };
};

export function IconBase64({ x = 0, y = 0, payload = { value: '' } }: IconBase64Props) {
  return (
    <image
      transform={`translate(${x - 10}, ${y})`}
      style={styles.icon}
      href={`data:image/svg+xml;base64,${payload.value}`}
    />
  );
}
