import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDeleteBenchmarkSubmissionMutation, useGetUserBenchmarkQuery } from '../../../generated/graphql';
import { openSnackbar } from '../../../components/Notifier';
import { NotifierType } from '../../../variables/types';
import { Button, Input, InputLabel, MenuItem, Select } from '@mui/material';
import TextField from '@mui/material/TextField';
import WarningWindow from '../../../components/WarningWindow';
import { sentryErrorLog } from '../../../utils/sentry';
import { ITEM_HEIGHT, ITEM_PADDING_TOP } from '../../../variables/constant';

const MenuProps = {
  border: 'none',
  PaperProps: {
    border: 'none',
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 'fit-content',
      transform: 'translateY(-2%)',
    },
    sx: {
      '&.MuiPaper-root.MuiPaper-root.MuiPopover-paper': {
        borderRadius: '0px',
      },
    },
    MenuListProps: {
      disablePadding: true,
    },
    getContentAnchorEl: null,
  },
};

const styles = {
  menuStyle: {
    '&.MuiMenu-paper': {
      border: 'none !important',
    },
  },
  inputInput: {
    '& div.MuiInputBase-input': {
      background: 'none',
      border: 0,
    },
  },
  menuItemStyles: {
    '&.MuiButtonBase-root': {
      border: 'none',
      '&:hover, &:focus': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        border: 'none',
      },
      '&.Mui-selected': {
        backgroundColor: 'rgba(42, 42, 139, 0.12) !important',
      },
    },
  },
};

export function ResetBenchmark() {
  const [invalidIdOrUrl, setInvalidIdOrUrl] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [input, setInput] = useState('');
  const [submissionId, setSubmissionId] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [reasonSelected, setReasonSelected] = useState('');
  const submissionIdRegex =
    /(?<submissionId>[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12})/;

  const { data, loading } = useGetUserBenchmarkQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: submissionId,
    },
    skip: !submissionId,
  });

  const [deleteBenchmark, { data: deleteResult, loading: deleteLoading }] = useDeleteBenchmarkSubmissionMutation();

  useEffect(() => {
    if (!isDeleting) return;

    const success = !!deleteResult?.deleteSubmissionV2;
    setIsDeleting(false);
    if (success) {
      openSnackbar({ message: 'Submission Deleted' }, NotifierType.Success);
      resetForm();
    } else {
      openSnackbar({ message: 'Unable to delete Submission' }, NotifierType.Error);
    }
  }, [deleteResult]);

  const handleDeleteBenchmarkSubmission = () => {
    if (isDeleting) return;

    setIsDeleting(true);
    deleteBenchmark({
      variables: {
        submissionID: submissionId,
        reason: reasonSelected,
      },
    }).catch((err) => {
      sentryErrorLog(err);
      openSnackbar({ message: 'Error deleting submission' }, NotifierType.Error);
    });
  };

  const resetForm = () => {
    setInvalidIdOrUrl(false);
    setIsDeleting(false);
    setShowWarning(false);
    setInput('');
    setSubmissionId('');
    setReasonSelected('');
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const matches = submissionIdRegex.exec(event.target.value);
    const submissionId = matches?.groups?.submissionId;

    if (submissionId) {
      setInput(submissionId);
      setSubmissionId(submissionId);
      setInvalidIdOrUrl(false);
    } else {
      setInput(event.target.value);
      setSubmissionId('');
      setInvalidIdOrUrl(true);
    }
  };

  return (
    <>
      <h2>Reset Benchmark</h2>
      {invalidIdOrUrl && <p style={{ color: 'red' }}>SubmissionId or URL is invalid.</p>}

      <InputLabel htmlFor="submissionIdInput">SubmissionId or URL:</InputLabel>
      <TextField
        style={{ minWidth: '500px' }}
        id="submissionIdInput"
        autoComplete="off"
        value={input}
        onChange={handleInputChange}
      />
      {submissionId && (
        <>
          {loading && <p>Loading...</p>}
          {!loading && data && (
            <>
              <h3>Found submission with id: {data.benchmarkSubmission.id}</h3>
              <p>
                Student Name: {data.benchmarkSubmission.studentBM.first_name}{' '}
                {data.benchmarkSubmission.studentBM.last_name}
              </p>
              {/*<p>District Name: {data.benchmarkSubmission.studentBM.student?.school?.district?.name}</p>*/}
              {/*<p>School Name: {data.benchmarkSubmission.studentBM.student?.school?.name}</p>*/}
              <p>Grade: {data.benchmarkSubmission.studentBM.student?.grade}</p>
              <p>Benchmark Title: {data.benchmarkSubmission.benchmarkPT.title}</p>
              <p>Benchmark Type: {data.benchmarkSubmission.benchmarkPT.benchmark_type}</p>
              <p>Status: {data.benchmarkSubmission.status}</p>
              <p>Reviewed At: {data.benchmarkSubmission.reviewed_at}</p>
              <p>Submitted At: {data.benchmarkSubmission.submitted_at}</p>

              {deleteLoading && <p>Deleting Submission...</p>}
            </>
          )}
          {!loading && !data && <p>No submission found.</p>}

          <InputLabel htmlFor="resetReason">Reset Reason:</InputLabel>
          <Select
            id="resetReason"
            value={reasonSelected}
            onChange={(event) => {
              setReasonSelected(event.target.value);
            }}
            sx={{ ...styles.inputInput, ...styles.menuStyle, width: '500px' }}
            input={<Input disableUnderline />}
            MenuProps={MenuProps}
          >
            {[
              { value: 'User Submitted Without Permission', label: 'User Submitted Without Permission' },
              { value: 'Microphone / Recording Issue', label: 'Microphone / Recording Issue' },
              { value: 'Wrong Student Logged In', label: 'Wrong Student Logged In' },
              { value: 'Other', label: 'Other' },
            ].map((option) => (
              <MenuItem value={option.value}>{option.label}</MenuItem>
            ))}
          </Select>
          <div style={{ marginTop: '20px' }}>
            <Button style={{ marginRight: '20px' }} variant={'text'} onClick={resetForm}>
              Cancel
            </Button>
            {data && reasonSelected && (
              <>
                <Button variant={'contained'} onClick={() => setShowWarning(true)}>
                  Reset
                </Button>
                <WarningWindow
                  openDialog={showWarning}
                  closeDialog={() => setShowWarning(false)}
                  header="Are you sure?"
                  subheader={`Are you sure you want to reset ${data.benchmarkSubmission.studentBM.first_name} ${data.benchmarkSubmission.studentBM.last_name}'s benchmark? All data related to this benchmark will be erased and cannot be undone. Selected Reason: "${reasonSelected}"`}
                  actions={[
                    {
                      title: 'Keep Submission',
                      event: () => resetForm(),
                      classname: 'keepButton',
                    },
                    {
                      title: 'Delete Submission',
                      event: () => handleDeleteBenchmarkSubmission(),
                      classname: 'deleteButton',
                    },
                  ]}
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}
