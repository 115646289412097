import React from 'react';
import { Bar, BarChart, CartesianGrid, Cell, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { Box } from '@mui/material';
import { palette } from '../../../../theme/palette';
import YaxisCustomizeTick from '../../../../components/OverallBenchmarkIndicatorGraph/YaxisCustomizeTick';
import { XAxisLabels } from './XAxisLabels';
import { AdminDashboards } from '../../../../variables/constant';
import useScoreSchemeDomain from '../../../TeacherBenchmarksDashboard/scorescheme-domain-hook';
import { useOverallIndicatorScores } from './overall-indicator-scores-hook';
import { useDashboardContext } from '../../admin-analytics-hook';
import AppTooltip from '../../../../components/AppTooltip';
import StyledCard from '../../../../components/StyledCard';
import VerticalBarLoaders from '../../../../components/Loader/VerticalBarLoaders';
import NoResults from '../../../../components/NoResults';
import EmptyResults from '../../../../components/Icons/EmptyResults';

export function OverallIndicatorScoresWidget() {
  const { yAxisDomain, yAxisTicks } = useScoreSchemeDomain();

  const { barData, hasData, loading } = useOverallIndicatorScores();
  const barDataSpeaking = barData?.filter((_: any, index: number) => index <= 4);
  const barDataWriting = barData?.filter((_: any, index: number) => index > 4);

  const overrideContent = ({
    fill,
    textBreakAll,
    parentViewBox,
    content,
    value,
    width,
    height,
    x,
    y,
    ...props
  }: any) => {
    const containsDecimal = value.toString().includes('.');
    const labelColor = value < 2.5 ? palette.customBlack.main : palette.customWhite.main;
    return (
      value !== 0 && (
        <text
          {...props}
          x={containsDecimal ? x + width / 3 - 3 : x + width / 2 - 2}
          y={y + height - 10}
          fill={labelColor}
        >
          {value}
        </text>
      )
    );
  };

  const overrideLabelList = (
    key: string,
    position: any = 'insideBottom',
    fontSize: number = 13,
    fontWeight: number = 800,
    content: any
  ) => {
    return (
      <LabelList
        dataKey={key}
        position={position}
        fontSize={fontSize}
        fontWeight={fontWeight}
        content={content ? content : overrideContent}
      />
    );
  };

  const { analyticsFilters } = useDashboardContext();

  return (
    <Box id={AdminDashboards.performance.overallIndicatorScoresWidget.name} sx={{ height: '100%', width: '100%' }}>
      <StyledCard
        title={`Overall ${analyticsFilters?.submission_score_type} Indicator Scores`}
        columnSetting
        isToolTip
        titleAlignment={'left'}
        toolTipComponent={
          <AppTooltip toolTipText={AdminDashboards.performance.overallIndicatorScoresWidget.tooltipText} />
        }
        fullHeight
        marginBottom="0px"
      >
        {loading && <VerticalBarLoaders />}
        {!loading && !hasData && (
          <NoResults
            description={AdminDashboards.performance.overallIndicatorScoresWidget.insufficientData.description}
            svgComponent={<EmptyResults fillColor={palette.customBackground.disabled} />}
          />
        )}
        {!loading && hasData && analyticsFilters?.submission_score_type === 'Speaking' && (
          <ResponsiveContainer width="100%" height="100%" minHeight={360}>
            <BarChart
              width={500}
              height={300}
              data={barDataSpeaking}
              margin={{
                top: 5,
                bottom: 40,
                left: -20,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="rubricTitle"
                tickLine={false}
                minTickGap={1}
                tickMargin={1}
                interval={0}
                axisLine={false}
                scale="point"
                padding={{ left: 60, right: 80 }}
                tick={<XAxisLabels />}
              />
              <YAxis
                tickLine={false}
                axisLine={false}
                tick={<YaxisCustomizeTick />}
                tickMargin={8}
                domain={yAxisDomain}
                ticks={yAxisTicks}
                allowDataOverflow={true}
              />
              <Bar
                dataKey="rubricScoreAverage"
                fill="fill"
                barSize={38}
                isAnimationActive={false}
                radius={[4, 4, 4, 4]}
                minPointSize={4}
              >
                {overrideLabelList('rubricScoreAverage', 'insideBottom', 14, 800, overrideContent)}
                {barDataSpeaking?.map((entry: any, index: number) => (
                  <Cell key={`cell-${index}`} fill={entry?.color} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        )}
        {!loading && hasData && analyticsFilters?.submission_score_type === 'Writing' && (
          <ResponsiveContainer width="100%" height="100%" minHeight={360}>
            <BarChart
              width={300}
              height={300}
              data={barDataWriting}
              margin={{
                top: 5,
                bottom: 40,
                left: -20,
              }}
              barGap={3}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="rubricTitle"
                tickLine={false}
                minTickGap={1}
                tickMargin={1}
                interval={0}
                axisLine={false}
                domain={[0, 5]}
                padding={{ right: -25 }}
                tick={<XAxisLabels />}
              />
              <YAxis
                tickLine={false}
                axisLine={false}
                tick={<YaxisCustomizeTick />}
                domain={yAxisDomain}
                ticks={yAxisTicks}
                allowDataOverflow={true}
              />
              <Bar
                dataKey="rubricScoreAverage"
                fill="fill"
                barSize={38}
                isAnimationActive={false}
                radius={[4, 4, 4, 4]}
                minPointSize={4}
              >
                {overrideLabelList('rubricScoreAverage', 'insideBottom', 14, 800, overrideContent)}
                {barDataWriting?.map((entry: any, index: number) => (
                  <Cell key={`cell-${index}`} fill={entry?.color} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        )}
      </StyledCard>
    </Box>
  );
}
