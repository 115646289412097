import React from 'react';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import Loader from '../../../components/Loader/loader';
import useStudentsSubmission from './students-submission-hook';
import SectionFilter from '../../../components/SectionsFilter';
import AssignmentFilter from '../../../components/AssignmentsFilter';
import StudentFilter from '../../../components/StudentFilter';
import Scorecard from './Scorecard';
import CurrentGoals from './CurrentGoals';
import PreviousGoals from './PreviousGoals';
import Submissions from './Submissions';
import {
  AssignmentFilterFragmentFragment,
  SectionFilterFragmentFragment,
  StudentFilterFragmentFragment, useEvaluateFlagQuery,
} from '../../../generated/graphql';
import EmptyResults from '../../../components/Icons/EmptyResults';
import NoResults from '../../../components/NoResults';
import zIndex from '../../../theme/z-index';
import { escapingSpecialCharacters, unescapeSpecialCharacters } from '../../../utils/handleEscapeCharacters';

const StylesCSS = (theme: Theme) => ({
  speakingTile: {
    flex: '2 1 auto',
    marginRight: theme.spacing(1.25),
  },
  writingTile: {
    flex: '1 2 auto',
    marginLeft: theme.spacing(1.25),
  },
  stickyHeader: {
    position: 'sticky',
    backgroundColor: theme.palette.customWhite.main,
    width: '100%',
    marginTop: theme.spacing(-2),
    top: 0,
    paddingTop: theme.spacing(2),
    zIndex: zIndex.zIndex9,
    borderBottom: `2px solid ${theme.palette.customBackground.disabled}`,
  },
  spaceBetween: {
    placeContent: 'space-between',
  },
});
const Student = () => {
  const theme = useTheme();
  const styles = StylesCSS(theme);
  const history = useHistory();
  const {
    id,
    assignments,
    loading,
    studentDetails,
    goalsDetail,
    previousGoals,
    assignment,
    setAssignment,
    selectedSection,
    setSelectedSection,
    maxScoreCount,
    scoreGraphData,
    scoreScheme,
    sort,
    handleSort,
    order,
  } = useStudentsSubmission();

  const studentsDistrictIdValDefault = false;
  const { data: studentsDistrictIdVal } = useEvaluateFlagQuery({
    fetchPolicy: 'network-only',
    variables: { input: { defaultValue: studentsDistrictIdValDefault, flagKey: 'students-district-id-ui' } },
  });
  const showStudentsDistrictId =
    studentsDistrictIdVal?.evaluateFlag.on ?? studentsDistrictIdValDefault;

  const onStudentChange = (data: string | StudentFilterFragmentFragment | null) => {
    if (data) {
      setSelectedSection(undefined);
      setAssignment(undefined);
      history.push(`/students/${data}/view/Task`);
    }
  };

  const onAssignmentChange = (data: string | AssignmentFilterFragmentFragment | null) => {
    setAssignment(data as string);
  };

  const onSectionChange = (data: string | SectionFilterFragmentFragment | null) => {
    setSelectedSection(data as string);
    setAssignment(undefined);
  };

  // @ts-ignore
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
      >
        {!loading && (
          <>
            <Box sx={styles.stickyHeader as SxProps<Theme>}>
              <Typography variant="pageTitle">{`${studentDetails?.user?.first_name} ${studentDetails?.user?.last_name}`}</Typography>
              <Box mb={1} display="flex" sx={styles.spaceBetween}>
                {showStudentsDistrictId ? <Typography variant='sLabel'>
                  Students District ID:
                  {'  '}
                  {studentDetails?.students_district_id}
                </Typography>: <Typography variant='sLabel'>
                  Student ID:
                  {'  '}
                  {studentDetails?.student_number}
                </Typography> }
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(3, 1fr)"
                  sx={{
                    gridGap: '8px',
                  }}
                  width="45%"
                >
                  <StudentFilter
                    value={id}
                    onChange={onStudentChange}
                    idSelectOnly
                    placeholder="All Students"
                  />
                  <SectionFilter
                    value={selectedSection}
                    onChange={onSectionChange}
                    idSelectOnly
                    placeholder="All Classes"
                    studentId={id}
                  />
                  <AssignmentFilter
                    onChange={onAssignmentChange}
                    idSelectOnly
                    placeholder="All Assignments"
                    value={assignment}
                    studentId={id}
                    sectionId={selectedSection || ''}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              mt={3}
              display="grid"
              width="100%"
              gridTemplateColumns="3fr 2fr"
              sx={{
                gridGap: '24px',
              }}
            >
              {
                scoreGraphData?.map((scoreStudent) => (
                  <Scorecard
                    loading={loading}
                    title={`${scoreStudent.submissionType} Performance`}
                    maxScoreCount={maxScoreCount}
                    studentData={scoreStudent}
                  />
                ))
              }
            </Box>
            <Box>
              {
                !loading && !scoreGraphData?.length && (
                  <NoResults
                    description="No scores recorded yet"
                    svgComponent={<EmptyResults fillColor={theme.palette.customBackground.disabled} />}
                  />
                )
              }
            </Box>
            <Submissions assignments={assignments} benchmarks={[]} scoreScheme={scoreScheme} loading={loading} title="Submissions" sort={sort} order={order} handleSort={handleSort} />
            <CurrentGoals activeGoal={goalsDetail?.getGoalsByStudentID} loading={loading} title="Current Goals" />
            <PreviousGoals previousGaols={previousGoals?.getGoalsByStudentID} loading={loading} title="Previous Goals" />
          </>
        )}
      </Box>
      <Loader open={loading} />
    </Box>
  );
};

export default React.memo(Student);
