import React from 'react';
import AdminDashboardPerformanceGraph from '../../../../components/AdminGraphs/AdminDashboardPerformanceGraph';
import { useOverallPerformanceInfo } from './overall-performance-widget-hook';
import { AdminDashboards } from '../../../../variables/constant';
import { useDashboardContext } from '../../admin-analytics-hook';

const OverallPerformanceWidget = () => {
  const {
    overallPerformanceDataLoading,
    overallPerformanceData,
    overallPerformanceDataError,
    sessionStartDate,
    sessionEndDate,
    currStartDate,
    currEndDate,
  } = useOverallPerformanceInfo();

  const { analyticsFilters } = useDashboardContext();
  const overallPerformanceDataGraphData: any = overallPerformanceData?.historical_score?.map((performance: any) => {
    return {
      date: performance?.date_timestamp,
      avgScore: performance?.average_score,
      totalPtScored: performance?.scored_pt_count,
      uniqueStudentCount: performance?.unique_student_count,
    };
  });

  return (
    <>
      <AdminDashboardPerformanceGraph
        loading={(overallPerformanceDataLoading || !overallPerformanceData) && !overallPerformanceDataError}
        sessionStartDate={sessionStartDate}
        sessionEndDate={sessionEndDate}
        currStartDate={currStartDate!}
        currEndDate={currEndDate!}
        title={`Overall ${analyticsFilters?.submission_score_type} Performance`}
        alignTitle
        tooltipTitle={AdminDashboards.performance.overallPerformance.tooltipText}
        currentScore={`${overallPerformanceData?.current_day_average_score || '-'}`}
        data={overallPerformanceDataGraphData}
        enableTooltip
      />
    </>
  );
};

export default OverallPerformanceWidget;
