import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { openSnackbar } from '../../../../components/Notifier';
import getErrorMessage from '../../../../utils/getErrorMessage';
import { NotifierType } from '../../../../variables/types';
import { useCreateSuperAdminMutation, useGetSuperAdminDetailsQuery } from '../../../../generated/graphql';
import SuperAdminIcon from '../../../../components/Icons/SuperAdminIcon';

type AdminFormData = {
  id: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  admin_id: string;
  title: string;
  email: string;
  phone_number: string;
  password: string;
};
interface RouteProp {
  id: string;
}

const useSuperAdminForm = () => {
  const [checked, setChecked] = useState(false);
  const { register, handleSubmit, errors, control, watch, reset } = useForm<AdminFormData>();
  const { id } = useParams<RouteProp>();
  const history = useHistory();
  const { data, loading: superAdminDetailsLoading } = useGetSuperAdminDetailsQuery({
    fetchPolicy: 'network-only',
    variables: {
      Id: id,
    },
    skip: !id,
  });
  const [superAdminForm, { loading: superAdminFormLoading }] = useCreateSuperAdminMutation();
  const superAdminDetails = data?.superAdminDetails;

  useEffect(() => {
    if (id) {
      register('id', { required: true });
    }
  }, [id, register]);

  useEffect(() => {
    if (superAdminDetails) {
      const details = {
        id: superAdminDetails?.id || undefined,
        title: superAdminDetails.title || undefined,
        first_name: superAdminDetails.first_name || undefined,
        middle_name: superAdminDetails.middle_name || undefined,
        last_name: superAdminDetails.last_name || undefined,
        admin_id: superAdminDetails.admin_id || undefined,
        email: superAdminDetails.email || undefined,
        phone_number: superAdminDetails.phone_number || undefined,
      };
      reset(details);
    }
  }, [id, reset, superAdminDetails]);

  const handleChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean) } }) => {
    setChecked(event.target.checked);
  };

  const createAdmin = async (adminForm: AdminFormData) => {
    const adminName = `${adminForm.first_name} ${adminForm.last_name}`;
    const successMessage = id ? `${adminName}  was  updated!` : `${adminName} was added!`;
    try {
      let adminDetailsPath: string;
      const response = await superAdminForm({
        variables: {
          ID: id,
          firstName: adminForm.first_name?.trim(),
          middleName: adminForm.middle_name?.trim(),
          lastName: adminForm.last_name?.trim(),
          adminID: adminForm.admin_id?.trim(),
          title: adminForm?.title?.trim() || '',
          email: adminForm.email?.trim().toLowerCase(),
          phoneNumber: adminForm.phone_number?.trim(),
          password: adminForm?.password?.trim(),
        },
      });

      adminDetailsPath = `/settings/super-admin/${response?.data?.upsertSuperAdmin}`;
      if (checked) {
        reset({
          id: '',
          title: '',
          first_name: '',
          middle_name: '',
          last_name: '',
          admin_id: '',
          email: '',
          phone_number: '',
          password: '',
        });
      } else {
        history.push(adminDetailsPath);
      }
      openSnackbar(
        {
          message: successMessage,
          customIcon: SuperAdminIcon,
          actionButtonText: 'View',
          onActionButtonClick: () => {
            history.push(adminDetailsPath);
          },
        },
        NotifierType.Success
      );
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };

  return {
    loading: superAdminFormLoading || superAdminDetailsLoading,
    register,
    handleSubmit,
    errors,
    control,
    adminID: id,
    watch,
    createAdmin,
    checked,
    handleChange,
    superAdminDetails,
  };
};

export default useSuperAdminForm;
