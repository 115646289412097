import React from 'react';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import CustomTooltip from './CustomTooltip';
import StyledCard from '../../StyledCard';
import LineChartLoader from '../../Loader/LineChartLoader';
import { palette } from '../../../theme/palette';
import { AxisDomain } from '../../../variables/types';
import AppTooltip from '../../AppTooltip';
import { Box, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { dateFormatter, formatSessionDate, getTicks } from '../CommonFunctions';
import NoResults from '../../NoResults';
import { AdminDashboards } from '../../../variables/constant';
import EmptyResults from '../../Icons/EmptyResults';

interface DataProps {
  date: number;
  avgScore: number;
  totalPtScored: number;
  uniqueStudentCount: number;
}

interface Props {
  loading: boolean;
  sessionStartDate: Date;
  sessionEndDate: Date;
  currStartDate: Date;
  currEndDate: Date;
  title?: string;
  currentScore?: string;
  data?: DataProps[];
  tooltipTitle?: string; // specifies the text used for Tooltip component.
  enableTooltip?: boolean; // to enable tooltip for this component.
  alignTitle?: boolean; // to specify alignment of the component title, default is center.
}

const styles = {
  headingTypo: {
    fontSize: '16px',
    fontWeight: 600,
    verticalAlign: 'super',
    color: palette.customBlack.dimmedBlack,
    marginLeft: '5px',
    display: 'inline',
  },
};

const fillTicksData = (_ticks: any[], data: DataProps[]) => {
  const ticks = [..._ticks];
  const filled = [];
  let currentTick = ticks.shift();
  let lastData = null;
  for (const it of data) {
    const itTmp = {
      date: it.date * 1000,
      avgScore: it.avgScore,
      totalPtScored: it.totalPtScored,
      uniqueStudentCount: it.uniqueStudentCount,
    };
    if (ticks.length && itTmp.date > currentTick && lastData) {
      filled.push(lastData);
      currentTick = ticks.shift();
    } else if (ticks.length && it.date === currentTick) {
      currentTick = ticks.shift();
    }
    filled.push(itTmp);
    lastData = itTmp;
  }
  return filled;
};

const AdminDashboardPerformanceGraph = ({
  loading,
  sessionStartDate,
  sessionEndDate,
  currStartDate,
  currEndDate,
  title,
  currentScore,
  data = [],
  tooltipTitle,
  enableTooltip,
  alignTitle,
}: Props) => {
  const ticks = getTicks(sessionStartDate, sessionEndDate);
  const filledData = fillTicksData(ticks, data!);
  const hasData = !loading && data && data?.length > 0;

  return (
    <Box id={AdminDashboards.performance.overallPerformance.name}>
      <StyledCard
        title={title}
        isToolTip={enableTooltip}
        columnSetting
        titleAlignment={alignTitle ? 'left' : 'center'}
        toolTipComponent={<AppTooltip toolTipText={tooltipTitle} />}
        contentAlignment
      >
        <Typography
          component="p"
          variant="secondaryLabel"
          sx={{ lineHeight: '31px', color: palette.customBlack.dimmedBlack } as SxProps}
        >
          {currStartDate && !loading && formatSessionDate(currStartDate)}
          {' - '}
          {currEndDate && !loading && formatSessionDate(currEndDate)}
        </Typography>
        {currentScore && hasData && (
          <Box sx={{ width: '100%', pb: 4, pl: 1.5 } as SxProps}>
            <Typography
              component="span"
              sx={{ fontSize: '40px', fontWeight: 800, color: palette.customBlue.toolTipIconBackground }}
            >
              {currentScore}
            </Typography>
            <Typography sx={{ ...(styles.headingTypo as SxProps) }}>Today</Typography>
          </Box>
        )}
        {loading ? (
          <LineChartLoader />
        ) : hasData ? (
          <ResponsiveContainer height={280} width="95%">
            <AreaChart
              data={filledData}
              margin={{
                top: 10,
                right: 10,
                bottom: 10,
                left: -25,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="date"
                scale="time"
                tickFormatter={dateFormatter}
                type="number"
                domain={[(dataMin: AxisDomain) => dataMin, () => sessionEndDate.getTime()]}
                ticks={ticks}
                axisLine={false}
                tickLine={false}
                dy={12}
                dx={15}
                style={{
                  fontSize: '14px',
                  fontWeight: 600,
                  color: palette.customBlack.fontBlack,
                  textTransform: 'uppercase',
                }}
              />
              <YAxis
                tickCount={5}
                axisLine={false}
                tickLine={false}
                domain={[1, 5]}
                style={{ fontSize: '14px', fontWeight: 600, color: palette.customBlack.fontBlack }}
              />
              <Tooltip
                content={<CustomTooltip />}
                cursor={{ strokeDasharray: '3 3', stroke: palette.customBlue.primaryBlue }}
                wrapperStyle={{ outline: 'none' }}
                allowEscapeViewBox={{ x: false, y: true }}
                position={{ y: -50 }}
              />
              <Area
                type="monotone"
                dataKey="avgScore"
                stroke={palette.customBlue.primaryBlue}
                strokeWidth={1.9}
                fill={palette.customBackground.selectedAreaBackground}
              />
            </AreaChart>
          </ResponsiveContainer>
        ) : (
          <NoResults
            description={AdminDashboards.performance.overallPerformance.insufficientData.description}
            svgComponent={<EmptyResults fillColor={palette.customBackground.disabled} />}
          />
        )}
      </StyledCard>
    </Box>
  );
};

export default AdminDashboardPerformanceGraph;
