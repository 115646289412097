import { TableCell, TableRow, Typography, useTheme } from '@mui/material';
import { TeacherBenchmarkDashboardStylesCSS } from '../../../../TeacherBenchmarksDashboard/teacherbenchmarkdashboard.styled';
import { DistrictWideComparisonsStylesCSS } from '../../../../AdminAnalytics/DistrictWideComparisons/DistrictWideComparisons.styled';
import { AdminBenchmarkScoresDataPoint } from '../../../../../generated/graphql';
import { StyledTableDataColumn } from '../../../../Admin/admin-tables-styled';
import { UnassignedStudents } from '../../../../../variables/constant';
import ArrowUpIcon from '../../../../../components/Icons/ArrowUpIcon';
import DownArrowIcon from '../../../../../components/Icons/DownArrow';
import NilIcon from '../../../../../components/Icons/NilIcon';
import { getColorsForScore, palette } from '../../../../../theme/palette';
import React from 'react';

const AdminBMSlideoutRow = ({ progressScore, slideOut, rowIndex }: any) => {
  const theme = useTheme();
  const styles = TeacherBenchmarkDashboardStylesCSS(theme);
  const stylesRow = DistrictWideComparisonsStylesCSS(theme);
  const speakingScore: AdminBenchmarkScoresDataPoint | undefined = progressScore?.benchmark_data_points?.find(
    (score: any) => score.submission_type === 'Speaking'
  );
  const writingScore: AdminBenchmarkScoresDataPoint | undefined = progressScore?.benchmark_data_points?.find(
    (score: any) => score.submission_type === 'Writing'
  );

  return (
    <TableRow key={rowIndex}>
      <TableCell sx={{ ...(slideOut === 'Teacher' ? stylesRow.width20 : stylesRow.width35), textAlign: 'left' }}>
        {!progressScore.name ? UnassignedStudents : progressScore.name}
      </TableCell>
      {slideOut === 'Teacher' && (
        <TableCell sx={{ ...stylesRow.width5, textAlign: 'center' }}>{progressScore.grade_name}</TableCell>
      )}
      <StyledTableDataColumn>
        <Typography component="span" sx={{ ...styles.tag, ...(getColorsForScore(speakingScore?.boy_score) || {}) }}>
          {speakingScore?.boy_score || '-'}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn>
        {speakingScore?.boy_to_moy_progress === 'up' ? (
          <ArrowUpIcon />
        ) : speakingScore?.boy_to_moy_progress === 'down' ? (
          <DownArrowIcon />
        ) : (
          <NilIcon />
        )}
      </StyledTableDataColumn>
      <StyledTableDataColumn>
        <Typography component="span" sx={{ ...styles.tag, ...getColorsForScore?.(speakingScore?.moy_score) }}>
          {speakingScore?.moy_score || '-'}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn>
        {speakingScore?.moy_to_eoy_progress === 'up' ? (
          <ArrowUpIcon />
        ) : speakingScore?.moy_to_eoy_progress === 'down' ? (
          <DownArrowIcon />
        ) : (
          <NilIcon />
        )}
      </StyledTableDataColumn>
      <StyledTableDataColumn>
        <Typography component="span" sx={{ ...styles.tag, ...(getColorsForScore(speakingScore?.eoy_score) || {}) }}>
          {speakingScore?.eoy_score || '-'}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn sx={{ borderRight: `2px solid ${palette.customGrey.iconGrade}` }}>
        <Typography
          sx={{
            color: theme.palette.customBlack.fontBlack,
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: 800,
            lineHeight: 'normal',
            letterSpacing: '1px',
          }}
        >
          {speakingScore?.ytd_change || <NilIcon />}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn>
        <Typography component="span" sx={{ ...styles.tag, ...getColorsForScore?.(writingScore?.boy_score) }}>
          {writingScore?.boy_score || '-'}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn>
        {writingScore?.boy_to_moy_progress === 'up' ? (
          <ArrowUpIcon />
        ) : writingScore?.boy_to_moy_progress === 'down' ? (
          <DownArrowIcon />
        ) : (
          <NilIcon />
        )}
      </StyledTableDataColumn>
      <StyledTableDataColumn>
        <Typography component="span" sx={{ ...styles.tag, ...getColorsForScore?.(writingScore?.moy_score) }}>
          {writingScore?.moy_score || '-'}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn>
        {writingScore?.moy_to_eoy_progress === 'up' ? (
          <ArrowUpIcon />
        ) : writingScore?.moy_to_eoy_progress === 'down' ? (
          <DownArrowIcon />
        ) : (
          <NilIcon />
        )}
      </StyledTableDataColumn>
      <StyledTableDataColumn>
        <Typography component="span" sx={{ ...styles.tag, ...getColorsForScore?.(writingScore?.eoy_score) }}>
          {writingScore?.eoy_score || '-'}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn>
        <Typography
          sx={{
            color: theme.palette.customBlack.fontBlack,
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: 800,
            lineHeight: 'normal',
            letterSpacing: '1px',
          }}
        >
          {' '}
          {writingScore?.ytd_change || <NilIcon />}
        </Typography>
      </StyledTableDataColumn>
    </TableRow>
  );
};

export default AdminBMSlideoutRow;
