import React from 'react';
import { Box, Typography } from '@mui/material';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { palette } from '../../../../theme/palette';
import YaxisCustomizeTick from '../../../../components/OverallBenchmarkIndicatorGraph/YaxisCustomizeTick';
import CustomLegend from '../../../../components/BenchmarkStudentGrowthReportChart/CustomLegend';
import { AdminDashboards, TeacherBenchmarkStudentGrowthReportLegendData } from '../../../../variables/constant';
import AppTooltip from '../../../../components/AppTooltip';
import NoResults from '../../../../components/NoResults';
import EmptyResults from '../../../../components/Icons/EmptyResults';
import StyledCard from '../../../../components/StyledCard';
import LineChartLoader from '../../../../components/Loader/LineChartLoader';
import { useDashboardContext } from '../../admin-analytics-hook';
import useScoreSchemeDomain from '../../../TeacherBenchmarksDashboard/scorescheme-domain-hook';
import { useOverallPerformanceInfo } from '../OverallPerformanceWidget/overall-performance-widget-hook';
import { dateFormatter, formatSessionDateWithDay, getTicks } from '../../../../components/AdminGraphs/CommonFunctions';
import { AxisDomain } from '../../../../variables/types';
import { SxProps } from '@mui/system';
import { OverallByMonthTooltip } from './OverallByMonthTooltip';

export function OverallByMonthWidget() {
  const { sessionStartDate, sessionEndDate, analyticsFilters } = useDashboardContext();
  const {
    overallPerformanceData,
    overallPerformanceDataLoading: loading,
    currStartDate,
    currEndDate,
  } = useOverallPerformanceInfo();
  const { yAxisDomain, yAxisTicks } = useScoreSchemeDomain();
  const hasData = overallPerformanceData?.historical_score_by_month.length;
  const xAxisTicks = getTicks(new Date(sessionStartDate.getFullYear(), 7, 1), sessionEndDate);

  return (
    <Box id={AdminDashboards.performance.overallByMonth.name} sx={{ height: '100%', width: '100%' }}>
      <StyledCard
        title={`Overall ${analyticsFilters?.submission_score_type} Scores`}
        columnSetting
        isToolTip
        titleAlignment={'left'}
        toolTipComponent={<AppTooltip toolTipText={AdminDashboards.performance.overallByMonth.tooltipText} />}
        fullHeight
        contentAlignment
        marginBottom="0px"
      >
        <Typography
          component="p"
          variant="secondaryLabel"
          sx={{ lineHeight: '31px', color: palette.customBlack.dimmedBlack } as SxProps}
        >
          {currStartDate && formatSessionDateWithDay(currStartDate)}
          {' - '}
          {currEndDate && formatSessionDateWithDay(currEndDate)}
        </Typography>
        {loading && <LineChartLoader />}
        {!loading && !hasData && (
          <NoResults
            description={AdminDashboards.performance.overallPerformance.insufficientData.description}
            svgComponent={<EmptyResults fillColor={palette.customBackground.disabled} />}
          />
        )}
        {!loading && hasData && (
          <ResponsiveContainer width="100%" height="100%" minHeight={300}>
            <LineChart
              width={630}
              margin={{ top: 24, left: -25, bottom: 5 }}
              height={400}
              data={overallPerformanceData?.historical_score_by_month}
            >
              <CartesianGrid vertical={false} />
              <Line
                type="monotone"
                dataKey="average_score"
                strokeWidth={3}
                stroke={
                  analyticsFilters?.submission_score_type === 'Speaking'
                    ? palette.customBlue.oldTheme
                    : palette.customBlue.lightSky
                }
                label={({ x, y, stroke, value }) => {
                  return (
                    <text x={x} y={y} dy={-14} fill={stroke} fontSize={14} textAnchor="middle">
                      {value.toFixed(1)}
                    </text>
                  );
                }}
                dot={{
                  r: 6,
                  fill:
                    analyticsFilters?.submission_score_type === 'Speaking'
                      ? palette.customBlue.oldTheme
                      : palette.customBlue.lightSky,
                }}
                activeDot={{
                  r: 8,
                  fill:
                    analyticsFilters?.submission_score_type === 'Speaking'
                      ? palette.customBlue.oldTheme
                      : palette.customBlue.lightSky,
                }}
                isAnimationActive={false}
              />
              <XAxis
                dataKey="date_timestamp"
                scale="time"
                tickFormatter={dateFormatter}
                type="number"
                domain={[(dataMin: AxisDomain) => dataMin, () => sessionEndDate.getTime()]}
                ticks={xAxisTicks}
                axisLine={false}
                tickLine={false}
                dy={12}
                style={{
                  fontSize: '14px',
                  fontWeight: 600,
                  textTransform: 'uppercase',
                }}
              />
              <YAxis
                tickLine={false}
                axisLine={false}
                stroke={palette.customGrey.lineGrey}
                strokeWidth={3}
                tick={<YaxisCustomizeTick />}
                domain={yAxisDomain}
                ticks={yAxisTicks}
              />
              <Tooltip
                content={<OverallByMonthTooltip />}
                cursor={{ strokeDasharray: '3 3', stroke: palette.customBlue.primaryBlue }}
                wrapperStyle={{ outline: 'none' }}
                allowEscapeViewBox={{ x: false, y: true }}
                position={{ y: -50 }}
              />
              <Legend
                content={
                  <CustomLegend
                    data={TeacherBenchmarkStudentGrowthReportLegendData.filter((x) =>
                      x.skillTitle.includes(analyticsFilters?.submission_score_type!)
                    )}
                    id={'student-growth-report-legend'}
                  />
                }
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </StyledCard>
    </Box>
  );
}
