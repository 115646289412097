import React from 'react';
import { AdminDashboards, SubmissionStatusColors, SubmissionStatusLabels } from '../../../../variables/constant';
import { useAssignmentStatusReport } from './assignment-status-report-hook';
import AppTooltip from '../../../../components/AppTooltip';
import StyledCard from '../../../../components/StyledCard';
import { Box, SxProps, Typography } from '@mui/material';
import { palette } from '../../../../theme/palette';
import { PerformanceWideStudentCompletionRateBar } from '../../../../generated/graphql';
import { AssignmentStatusReportTooltip } from './AssignmentStatusReportTooltip';
import { formatSessionDateWithDay } from '../../../../components/AdminGraphs/CommonFunctions';
import SimpleBarChart from '../../../../components/AdminGraphs/SimpleBarChart';
import OverallPerformanceDonutChart from '../../../../components/Charts/Graph/OverallPerformanceDonutChart';
import EmptyDonutChart from '../../../../components/Charts/Graph/EmptyDonutChart';

interface Props {
  enableTooltip?: boolean;
  alignTitle?: boolean;
}

export const AssignmentStatusReportWidget = ({ enableTooltip = true, alignTitle = true }: Props) => {
  const {
    assignmentCompletionRatesLoading,
    assignmentCompletionRateData,
    hasData,
    currStartDate,
    currEndDate,
    overallStatusTotals,
  } = useAssignmentStatusReport();

  const processData = (data: PerformanceWideStudentCompletionRateBar[]) => {
    return data?.map((eventData: PerformanceWideStudentCompletionRateBar) => ({
      ...eventData,
      remainingUnits: Math.abs(eventData?.pts_assigned! - eventData?.pts_submitted_and_graded!),
    }));
  };

  const processedData = assignmentCompletionRateData && processData(assignmentCompletionRateData);
  const hasSubmissionStatusData = overallStatusTotals !== undefined;
  const submissionStatusData = overallStatusTotals?.adminPerformanceSubmissionStatus.submission_line_items.map(
    ({ submission_count, submission_status }) => ({
      studentCount: submission_count,
      skillLabel: SubmissionStatusLabels?.[submission_status],
      color: SubmissionStatusColors?.[submission_status],
    })
  );

  const studentCounts = submissionStatusData?.map(({ studentCount }) => studentCount) ?? [];
  let submissionStatusCount = 0;
  if (studentCounts.length > 0) {
    submissionStatusCount = studentCounts.reduce((a, b) => a + b);
  }

  return (
    <Box id={AdminDashboards.performance.assignmentCompletionRatesWidget.name}>
      <StyledCard
        title="Assignment Status Report"
        columnSetting
        isToolTip={enableTooltip}
        titleAlignment={alignTitle ? 'left' : 'center'}
        toolTipComponent={
          <AppTooltip toolTipText={AdminDashboards.performance.assignmentCompletionRatesWidget.tooltipText} />
        }
        contentAlignment
      >
        <Typography
          component="p"
          variant="secondaryLabel"
          sx={{ lineHeight: '31px', color: palette.customBlack.dimmedBlack } as SxProps}
        >
          {formatSessionDateWithDay(currStartDate!)} - {formatSessionDateWithDay(currEndDate!)}
        </Typography>
        <Box display="flex">
          <Box width="80%">
            <SimpleBarChart
              barData={processedData}
              loading={assignmentCompletionRatesLoading}
              primaryLegend="Assignments Created"
              secondaryLegend="Assignments Submitted"
              tertiaryLegend="Assignments Graded"
              showData={hasData > 0}
              blueBar="pts_submitted_and_graded"
              greyBar="pts_assigned"
              greenBar="pts_graded"
              toolTipComponent={<AssignmentStatusReportTooltip />}
            />
          </Box>
          <Box>
            {hasSubmissionStatusData ? (
              <OverallPerformanceDonutChart
                data={submissionStatusData!}
                startAngle={90}
                endAngle={-270}
                boxWidth={410}
                chartWidth={390}
                chartHeight={310}
                boxHeight={330}
                dataKey="studentCount"
                totalCount={submissionStatusCount}
                label
                innerLabel="Total Assignments"
                paddingAngle={1.5}
                innerRadius={82}
              />
            ) : (
              <EmptyDonutChart dataKey="studentCount" />
            )}
          </Box>
        </Box>
      </StyledCard>
    </Box>
  );
};
