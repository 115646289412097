import React from 'react';
import { Box } from '@mui/material';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { StudentsIcon } from '../Icons';
import ToolBoxIcon from '../Icons/ToolBoxIcon';
import SolidPhotograph from '../Icons/SolidPhotograph';
import Loader from '../Loader/loader';
import useSidebarHook from './sidebar-hook';
import TreeViewNavigation from './TreeViewNavigation';
import BenchmarkIcon from '../Icons/BenchmarkIcon';
import TreeMapSelectedIcon from '../Icons/TreeMapSelectedIcon';
import NavigationChip from '../NavigationChip';
import RoutingPaths from '../../pages/RoutingPath';
import useLocalStorageState from '../../hooks/useLocalStorageState';
import ClassesIcon from '../Icons/ClassesIcon';
import { StyledChipBox } from '../Chip/ChipsCount.styled';
import { palette } from '../../theme/palette';
import NumberFormat from 'react-number-format';
import { StyledNavigationButton, StyledNavigationButtonBox, superAdminOptionStyles } from './Sidebar.styled';

const TeacherOptions = () => {
  const history = useHistory();

  const {
    loading,
    submittedSubmissionCount,
    sidenavPTSubmissionCountRefetch,
    sidenavBenchmarkGradeTileCount,
    teacherBenchmarksDataRefetch,
    sectionCount,
  } = useSidebarHook();

  const navigateTo = (path: string) => () => {
    sidenavPTSubmissionCountRefetch();
    teacherBenchmarksDataRefetch();
    history.push(path);
  };

  const [tbdTypeformSurvey, setTbdTypeformSurvey] = useLocalStorageState('TBD-typeform-survey');
  const isBenchmarkList = useRouteMatch({
    path: [
      RoutingPaths.TeacherBenchMarks,
      RoutingPaths.ScoreTaskBenchmarks,
      `${RoutingPaths.ScoreTaskBenchmarks}/:section`,
      RoutingPaths.StudentBenchmarkDashboard,
    ],
    exact: false,
  });
  const isBenchmarkGrade = useRouteMatch({
    path: [
      RoutingPaths.TeacherGradeBenchMarks,
      RoutingPaths.ScoreTaskBenchmarks,
      `${RoutingPaths.ScoreTaskBenchmarks}/:section`,
    ],
    exact: false,
  });
  const isBenchmarkData = useRouteMatch({
    path: [RoutingPaths.TeacherBenchMarksDashboard, RoutingPaths.StudentBenchmarkDashboard],
    exact: false,
  });
  const isAssignmentList = useRouteMatch({
    path: [RoutingPaths.Assignments, RoutingPaths.Analytics, RoutingPaths.Grade, RoutingPaths.ScoreTask],
    exact: false,
  });
  const isAssignmentData = useRouteMatch({ path: RoutingPaths.Analytics, exact: false });
  const isAssignmentGrade = useRouteMatch({ path: [RoutingPaths.Grade, RoutingPaths.ScoreTask], exact: false });
  const updateLocalStorage = () => () => {
    const path = history?.location?.pathname;
    if (path && path === RoutingPaths.TeacherBenchMarksDashboard) {
      setTbdTypeformSurvey((tbdTypeformSurvey as number) + 1);
    }
  };

  const benchmarkSidebar = {
    mainCategory: {
      indexId: '1',
      label: 'Benchmarks',
      linkToRedirect: RoutingPaths.TeacherBenchMarks,
      routeMatches: isBenchmarkList,
      icon: BenchmarkIcon,
    },
    subItems: [
      {
        indexId: '1.a',
        label: 'Grade',
        linkToRedirect: RoutingPaths.TeacherGradeBenchMarks,
        routeMatches: isBenchmarkGrade,
        icon: TreeMapSelectedIcon,
        counterChips: <NavigationChip dataCount={sidenavBenchmarkGradeTileCount} />,
      },
      {
        indexId: '1.b',
        label: 'Benchmark Data',
        linkToRedirect: RoutingPaths.TeacherBenchMarksDashboard,
        routeMatches: isBenchmarkData,
        icon: TreeMapSelectedIcon,
      },
    ],
  };
  const assignmentSidebar = {
    mainCategory: {
      indexId: '1',
      label: 'Assignments',
      linkToRedirect: RoutingPaths.Assignments,
      routeMatches: isAssignmentList,
      icon: SolidPhotograph,
    },
    subItems: [
      {
        indexId: '1.a',
        label: 'Grade',
        linkToRedirect: RoutingPaths.Grade,
        routeMatches: isAssignmentGrade,
        icon: TreeMapSelectedIcon,
        counterChips: <NavigationChip dataCount={submittedSubmissionCount} />,
      },
      {
        indexId: '1.a',
        label: 'Assignment Data',
        linkToRedirect: RoutingPaths.AnalyticsOverview,
        routeMatches: isAssignmentData,
        icon: TreeMapSelectedIcon,
      },
    ],
  };

  return (
    <Box width="100%">
      <Loader open={loading} />
      <StyledNavigationButton fullWidth onClick={() => sidenavPTSubmissionCountRefetch()}>
        <TreeViewNavigation
          mainCategoryItem={assignmentSidebar.mainCategory}
          subCategoryItems={assignmentSidebar.subItems}
        />
      </StyledNavigationButton>
      <StyledNavigationButton fullWidth onClick={updateLocalStorage()}>
        <TreeViewNavigation
          mainCategoryItem={benchmarkSidebar.mainCategory}
          subCategoryItems={benchmarkSidebar.subItems}
        />
      </StyledNavigationButton>
      <StyledNavigationButton fullWidth onClick={navigateTo(RoutingPaths.TeacherClasses)}>
        <StyledNavigationButtonBox
          sx={{ ...superAdminOptionStyles.buttonBox }}
          active={(!!useRouteMatch({
            path: [RoutingPaths.CreateClass, RoutingPaths.TeacherClasses],
            exact: false,
          }))?.toString()}
        >
          <ClassesIcon sx={{ mr: '12px' }} />
          Classes
          <StyledChipBox
            sx={{ backgroundColor: palette.customBackground.dragBackground }}
            hascolor={palette.customBlack.lightBlack}
            label={<NumberFormat displayType="text" value={sectionCount ?? '-'} thousandSeparator />}
          />
        </StyledNavigationButtonBox>
      </StyledNavigationButton>
      <StyledNavigationButton fullWidth onClick={navigateTo(RoutingPaths.Students)}>
        <StyledNavigationButtonBox
          active={(!!useRouteMatch({ path: [RoutingPaths.Students, RoutingPaths.Groups], exact: false }))?.toString()}
        >
          <StudentsIcon sx={{ mr: '5px' }} />
          Students
        </StyledNavigationButtonBox>
      </StyledNavigationButton>
      <StyledNavigationButton fullWidth onClick={navigateTo(RoutingPaths.ToolBox)}>
        <StyledNavigationButtonBox active={(!!useRouteMatch({ path: RoutingPaths.ToolBox, exact: false }))?.toString()}>
          <ToolBoxIcon sx={{ mr: '5px' }} />
          Toolbox
        </StyledNavigationButtonBox>
      </StyledNavigationButton>
    </Box>
  );
};

export default TeacherOptions;
