import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette } from '../../theme/palette';

const PointsAndReason = React.memo(({ width = 61, height = 46, ...props }: any) => (
  <SvgIcon fontSize="small" width={width} height={height} viewBox="0 0 61 46" fill="none" {...props}>
    <g>
      <circle
        cx="30.121"
        cy="10.953"
        r="9.24161"
        stroke={palette.fontColors.fontBlack}
        strokeWidth="3.42282"
        fill={palette.customWhite.main}
      />
      <circle
        cx="30.121"
        cy="36.8677"
        r="6.50336"
        stroke={palette.fontColors.fontBlack}
        strokeWidth="3.42282"
        fill={palette.customWhite.main}
      />
      <circle
        cx="52.0268"
        cy="28.6531"
        r="6.50336"
        stroke={palette.fontColors.fontBlack}
        strokeWidth="3.42282"
        fill={palette.customWhite.main}
      />
      <circle
        cx="8.21477"
        cy="28.6531"
        r="6.50336"
        stroke={palette.fontColors.fontBlack}
        strokeWidth="3.42282"
        fill={palette.customWhite.main}
      />
      <path
        d="M30.1206 20.4383V30.0222"
        stroke={palette.fontColors.fontBlack}
        strokeWidth="3.42282"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9062 17.7L13.6915 23.1765"
        stroke={palette.fontColors.fontBlack}
        strokeWidth="3.42282"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.3354 17.7L46.5502 23.1765"
        stroke={palette.fontColors.fontBlack}
        strokeWidth="3.42282"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </SvgIcon>
));

export default PointsAndReason;
