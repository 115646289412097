import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { WelcomeMessage } from './ErrorPage.styled';
import FlashlightIcon from '../Icons/FlashlightLogo';
import { palette } from '../../theme/palette';

const styles = {
  HLIcon: {
    width: '10rem',
    height: '2rem',
  },
};

const ErrorPage = () => {
  return (
    <Box
      bgcolor={palette.customBackground.disabled}
      height={172}
      display="grid"
      gridTemplateColumns="1fr 4fr 1fr"
      width="100%"
      alignItems="center"
      alignSelf="center"
      paddingX={4}
    >
      <FlashlightIcon sx={{ ...styles.HLIcon }} width={50} />
      <Box>
        <WelcomeMessage>Something Went Wrong</WelcomeMessage>
        <Typography>We’re not sure what went wrong. You can go back or visit the homepage.</Typography>
      </Box>
      <Button variant="contained" color="primary" href="/">
        Homepage
      </Button>
    </Box>
  );
};

export default ErrorPage;
