import React, { useMemo } from 'react';
import {
  Checkbox,
  FormControlLabel,
  TableCell,
  TableRow,
} from '@mui/material';
import {
  ManageClassUserFragmentFragment, useEvaluateFlagQuery,
} from '../../../generated/graphql';
import { palette  } from '../../../theme/palette';
import Typography from '@mui/material/Typography';

interface Props {
  student: ManageClassUserFragmentFragment,
  onChange: (studentId: string) => void,
  isSelected: (studentId: string) => boolean,
  disabled?: boolean,
}

const styles = {
  tableCell: {
    paddingLeft: '24px',
  },
  label: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: palette.fontColors.fontBlack,
  },
};

const ListRow = ({
  student,
  onChange,
  isSelected,
  disabled,
}: Props) => {
  const isSelectedStudent = useMemo(() => isSelected(student?.student?.id!), [isSelected, student]);
  const studentsDistrictIdValDefault = false;
  const { data: studentsDistrictIdVal } = useEvaluateFlagQuery({
    fetchPolicy: 'network-only',
    variables: { input: { defaultValue: studentsDistrictIdValDefault, flagKey: 'students-district-id-ui' } },
  });
  const showStudentsDistrictId =
    studentsDistrictIdVal?.evaluateFlag.on ?? studentsDistrictIdValDefault;
  return (
    <TableRow>
      <TableCell sx={{ ...styles.tableCell }}>
        <FormControlLabel
          sx={{ ...styles.label }}
          control={(
            <Checkbox
              color="primary"
              checked={isSelectedStudent}
              disabled={disabled}
              onChange={() => onChange(student?.student?.id!)}
            />
          )}
          label={`${student.first_name ?? ''} ${student.last_name ?? ''}`}
        />
      </TableCell>
      <TableCell><Typography variant='body2' sx={{ textAlign:'center' }}>{student?.student?.grade ?? '2'}</Typography></TableCell>
      <TableCell><Typography variant='body2' sx={{ textAlign:'center', wordBreak:'break-word' }}>{showStudentsDistrictId? student?.student?.students_district_id: student?.student?.student_number ?? student?.student?.id}</Typography></TableCell>
    </TableRow>
  );
};
export default ListRow;
