import { Button, styled } from '@mui/material';

export const StyledDeleteButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.customRed.deleteButton,
  color: theme.palette.customWhite.main,
  '&::after': {
    borderColor: theme.palette.customRed.deleteButton,
  },
  '&:hover': {
    color: theme.palette.customWhite.main,
    backgroundColor: theme.palette.customRed.deleteButton,
  },
}));

export const StyledCircleBtnIcon = styled(Button)<{ buttonHeight?: number; buttonWidth?: number; padding?: number }>(
  ({ buttonHeight, buttonWidth, padding }) => ({
    minWidth: 50,
    borderRadius: '50%',
    '&::after': {
      borderRadius: '50%',
    },
    height: buttonHeight ? buttonHeight : 50,
    width: buttonWidth ? buttonWidth : 50,
    ...(padding !== undefined ? { padding: padding } : {}),
    '&:active': {
      borderRadius: '50%',
    },
  })
);
