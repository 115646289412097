import React from 'react';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import { Link } from 'react-router-dom';
import TeacherOptions from './TeacherOptions';
import StudentOptions from './StudentOptions';
import FlashlightIcon from '../Icons/FlashlightLogo';
import SuperAdminOptions from './SuperAdminOptions';
import useRole from '../../hooks/useRole';
import zIndex from '../../theme/z-index';
import { palette } from '../../theme/palette';
import { Typography } from '@mui/material';

const Sidebar = () => {
  const { isTeacher, isSuperAdmin, isStudent, isSchoolAdmin, isDistrictAdmin } = useRole();
  const fontStyles = {
    color: palette.customOrange.flashlightIconColor,
    fontSize: '12px',
    fontWeight: '800',
  };
  return (
    <Box
      height="100%"
      width={isTeacher ? 230 : isStudent ? 210 : 300}
      bgcolor={palette.customWhite.main}
      position="relative"
      zIndex={zIndex.zIndex9999}
      borderRight={`2px solid ${palette.customBackground.disabled}`}
    >
      <Box
        flexGrow={1}
        flexDirection="column"
        display="flex"
        alignItems="space-between"
        justifyContent="center"
        height="100%"
        m={0}
      >
        <Box
          width="100%"
          m={0}
          p={0}
          flexGrow={1}
          flexDirection="column"
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Box display="flex" height={68}>
            <ButtonBase component={Link} to="/">
              <Box display="flex" flexDirection="column" alignItems={'center'}>
                <FlashlightIcon
                  sx={{
                    width: '10rem',
                    height: 'auto',
                  }}
                />
                {isSuperAdmin && <Typography sx={fontStyles}>SUPER ADMIN</Typography>}
              </Box>
            </ButtonBase>
          </Box>

          {isStudent && <StudentOptions />}
          {isTeacher && <TeacherOptions />}
          {(isSuperAdmin || isSchoolAdmin || isDistrictAdmin) && <SuperAdminOptions />}
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
