import React, { useRef, useState } from 'react';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { Box } from '@mui/material';
import { EventData, TooltipState } from '../../../variables/types';
import { CUSTOM_TOOLTIP_HEIGHT, CUSTOM_TOOLTIP_WIDTH } from '../../../variables/constant';
import { SingleBarHorizontalGraphTooltip } from '../Tooltips/SingleBarHorizontalGraphTooltip';
import zIndex from '../../../theme/z-index';
import { palette } from '../../../theme/palette';

interface Props {
  data: Record<string, string | number> | undefined;
  boxHeight?: number | string;
  boxWidth?: number | string;
  onTooltipLinkClick?: (data: any, skillScore?: string) => void;
  disableLink?: boolean;
  disableDesc?: boolean;
}

interface SingleBarTooltipState extends TooltipState {
  skillLevel?: string;
}

export function SingleBarGraphChart({
  data,
  boxHeight = 100,
  boxWidth = '100%',
  onTooltipLinkClick,
  disableLink,
  disableDesc,
}: Props) {
  const [state, setState] = useState<SingleBarTooltipState>({
    showTooltip: false,
    event: {
      pageX: 0,
      pageY: 0,
    },
    tooltipData: {},
    skillLevel: undefined,
  });
  const { showTooltip, event, tooltipData, skillLevel } = state;
  const tooltipTimeout = useRef<any>();
  const bodyWidth = document.querySelector('body')?.getBoundingClientRect().width ?? 0;
  const bodyHeight = document.querySelector('body')?.getBoundingClientRect().height ?? 0;

  const handleHover = (
    item: any,
    active: boolean,
    eventObject: EventData | null,
    actionCreator: string,
    skillLevelParam?: string
  ) => {
    if (active) {
      if (actionCreator === 'tooltip') {
        if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);
        setState((prevState) => ({
          ...prevState,
          showTooltip: true,
          skillLevel: skillLevelParam,
        }));
      } else if (actionCreator === 'cell') {
        if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);
        setState((prevState) => ({
          ...prevState,
          showTooltip: true,
          tooltipData: item?.data ?? item,
          event: eventObject,
          skillLevel: skillLevelParam,
        }));
      }
    } else {
      if (actionCreator === 'cell') {
        if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);
        tooltipTimeout.current = setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            showTooltip: false,
            event: eventObject,
            skillLevel: '',
          }));
        }, 200);
      }
      if (actionCreator === 'tooltip') {
        setState((prevState) => ({
          ...prevState,
          showTooltip: false,
          event: eventObject,
          tooltipData: {},
          skillLevel: '',
        }));
      }
    }
  };

  const maxValue = data
    ? Object.keys(data)?.reduce((prevValue: number, currentValue) => {
        if (currentValue?.endsWith('_key')) {
          return prevValue + parseInt(data[currentValue ?? '']?.toString(), 10);
        }
        return prevValue;
      }, 0)
    : 'auto';

  return (
    <Box width={boxWidth} height={boxHeight} display="flex" justifyContent="center" margin="0 auto">
      {showTooltip && (
        <div
          className="custom-tooltip-container"
          id="graph-tooltip"
          style={{
            zIndex: zIndex.zIndex1,
            position: 'fixed',
            left: event
              ? event?.pageX < bodyWidth - CUSTOM_TOOLTIP_WIDTH
                ? event?.pageX + 5
                : event?.pageX - CUSTOM_TOOLTIP_WIDTH
              : 0,
            top: event
              ? event?.pageY < bodyHeight - CUSTOM_TOOLTIP_HEIGHT
                ? event?.pageY - 50
                : event?.pageY - CUSTOM_TOOLTIP_HEIGHT / 2
              : 0,
          }}
          onMouseEnter={() => {
            handleHover({}, true, event, 'tooltip', skillLevel);
          }}
          onMouseLeave={() => {
            handleHover({}, false, null, 'tooltip', '');
          }}
        >
          <SingleBarHorizontalGraphTooltip
            data={tooltipData}
            onLinkClick={onTooltipLinkClick}
            skillLevel={skillLevel}
            disableLink={disableLink}
            disableDesc={disableDesc}
          />
        </div>
      )}
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          height={100}
          data={[data]}
          layout="vertical"
          style={{ stroke: palette.customWhite.main, strokeWidth: 3 }}
        >
          <XAxis hide type="number" domain={[0, maxValue]} />
          <YAxis type="category" hide dataKey="name" stroke={palette.customWhite.main} fontSize="12" />
          {data &&
            Object.keys(data)?.reduce((prevValue: any[], currentValue) => {
              if (currentValue?.endsWith('_key')) {
                prevValue.push(
                  <Bar
                    key={currentValue}
                    barSize={24}
                    dataKey={currentValue}
                    stackId="a"
                    radius={4}
                    fill={data?.[`${currentValue}_color`] as string}
                    onMouseEnter={(item: any, _, e: React.MouseEvent<SVGElement>) => {
                      handleHover(item, true, { pageX: e?.pageX, pageY: e?.pageY }, 'cell', currentValue);
                    }}
                    onMouseLeave={() => {
                      handleHover({}, false, null, 'cell', '');
                    }}
                  />
                );
              }
              return prevValue;
            }, [])}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}
