import {
  BenchmarkGradedBy,
  OrderBy,
  SectionCategory,
  SubmissionStatus,
  TeacherBenchmarkSubmissionListSortType,
  useBenchmarkGradesCountQuery,
  useSectionCountQuery,
  useSubmissionCountQuery,
  useTeacherBenchmarkGradesListQuery,
} from '../../generated/graphql';
import useRole from '../../hooks/useRole';
import { PAGE_SIZE } from '../../variables/constant';

const useSidebarHook = () => {
  const { isTeacher, isSuperAdmin } = useRole();

  const {
    data,
    loading,
    refetch: sidenavPTSubmissionCountRefetch,
  } = useSubmissionCountQuery({
    variables: {
      status: [SubmissionStatus.Submitted],
    },
    skip: !isTeacher,
    // pollInterval: 120000,
  });

  const {
    data: teacherBenchmarkGradesData,
    loading: teacherBenchmarksDataLoading,
    refetch: teacherBenchmarksDataRefetch,
  } = useTeacherBenchmarkGradesListQuery({
    fetchPolicy: 'network-only',
    skip: !isTeacher,
    variables: {
      grade_statuses: [SubmissionStatus.Submitted],
      limit: PAGE_SIZE,
      page: 1,
      sort: TeacherBenchmarkSubmissionListSortType.SubmittedAt,
      order_by: OrderBy.Desc,
      graded_by: [BenchmarkGradedBy.Teacher],
    },
  });

  const {
    data: adminBenchmarkCount,
    loading: adminBenchmarkSubmissionLoading,
    refetch: adminBenchmarkCountRefetch,
  } = useBenchmarkGradesCountQuery({
    fetchPolicy: 'network-only',
    skip: !isSuperAdmin,
    variables: {
      gradeStatus: [SubmissionStatus.Submitted],
    },
    // pollInterval: 120000,
  });

  const {
    data: sectionCountData,
    loading: sectionLoading,
    refetch: sectionCountRefetch,
  } = useSectionCountQuery({
    fetchPolicy: 'network-only',
    skip: !isTeacher,
    variables: {
      classTypes: [SectionCategory.CustomClass, SectionCategory.Class],
    },
  });

  return {
    loading: loading || teacherBenchmarksDataLoading || adminBenchmarkSubmissionLoading,
    submittedSubmissionCount: data?.submissionsCount ?? 0,
    sidenavPTSubmissionCountRefetch,
    sidenavBenchmarkGradeTileCount: isTeacher
      ? teacherBenchmarkGradesData?.teacherBenchmarkSubmissionList?.node_count ?? 0
      : adminBenchmarkCount?.adminBenchmarkSubmissionsCount ?? 0,
    teacherBenchmarksDataRefetch,
    adminBenchmarkCountRefetch,
    sectionCount: sectionCountData?.sectionCount ?? 0,
    sectionLoading,
    sectionCountRefetch,
  };
};

export default useSidebarHook;
