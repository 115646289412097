import React, { forwardRef, useEffect, useRef } from 'react';
import { Popover, PopupButton, Sidetab, SliderButton } from '@typeform/embed-react';
import { Box } from '@mui/material';

//Below props description closely relate to https://www.typeform.com/developers/embed/configuration
interface Props {
  formId: string;
  hiddenButton?: boolean;
  delay?: number;
  typeformLayout?: 'popup' | 'slider' | 'sidetab' | 'popover';
  buttonText?: string;
  onReadyHandler?: Function;
  onCloseHandler?: Function;
  onStartedHandler?: Function;
  onQuestionChangedHandler?: Function;
  onFormSubmittedHandler?: Function;
  notificationDays?: number;
  shareGaInstance?: string;
  keepSession?: boolean;
  hiddenFields?: any;
  height?: number;
  width?: number;
  buttonTextColor?: string;
  buttonColor?: string;
  customIcon?: string | any;
  autoTrigger?: boolean;
  position?: string;
  styleClass?: any;
}

const PopupButtonRef = forwardRef(
  ({ formId, hiddenButton, buttonText, buttonColor, closeHandler, submitHandler }: any, ref) => (
    <PopupButton
      ref={ref}
      id={formId}
      onClose={closeHandler}
      onSubmit={submitHandler}
      height={580}
      width={1150}
      style={{ fontSize: 20, visibility: hiddenButton ? 'hidden' : 'visible' }}
      className="typrform-button"
    >
      {buttonText}
    </PopupButton>
  )
);

const PopoverRef = forwardRef(
  ({ formId, hiddenButton, buttonText, buttonColor, closeHandler, submitHandler, hiddenFields }: any, ref) => (
    <Popover
      ref={ref}
      id={formId}
      height={460}
      style={{ fontSize: 20, visibility: hiddenButton ? 'hidden' : 'visible' }}
      onClose={closeHandler}
      onSubmit={submitHandler}
      hidden={hiddenFields}
      buttonColor={buttonColor}
      className="typrform-button"
    >
      {buttonText}
    </Popover>
  )
);

const SliderButtonRef = forwardRef(({ formId, buttonText, position, styleClass, customIcon }: any, ref) => (
  <SliderButton ref={ref} id={formId} style={styleClass} position={position} className="typrform-button">
    <Box display="flex" alignItems="center" justifyContent="space-evenly">
      <Box>{customIcon ?? customIcon}</Box>
      <Box>{buttonText}</Box>
    </Box>
  </SliderButton>
));

const SideTabButtonRef = forwardRef(({ formId, buttonText, buttonTextColor, buttonColor, customIcon }: any, ref) => (
  <Sidetab
    ref={ref}
    id={formId}
    buttonText={buttonText}
    buttonTextColor={buttonTextColor}
    width={700}
    height={800}
    customIcon={customIcon}
    buttonColor={buttonColor}
  ></Sidetab>
));

const TypeformEmbed = ({
  formId,
  hiddenButton,
  delay = 0,
  typeformLayout,
  buttonText,
  onCloseHandler,
  onFormSubmittedHandler,
  hiddenFields,
  buttonColor,
  buttonTextColor,
  customIcon,
  autoTrigger = true,
  position = 'right',
  styleClass,
}: Props) => {
  const ref = useRef();

  useEffect(() => {
    if (!autoTrigger) {
      return () => {};
    } else {
      const timer = setTimeout(() => ref?.current?.open(), delay);
      return () => clearTimeout(timer);
    }
  }, []);

  const renderForm = () => {
    switch (typeformLayout) {
      case 'popup':
        return (
          <PopupButtonRef
            ref={ref}
            formId={formId}
            buttonText={buttonText}
            buttonColor={buttonColor}
            hiddenButton={hiddenButton}
            closeHandler={onCloseHandler}
            submitHandler={onFormSubmittedHandler}
          />
        );
      case 'slider':
        return (
          <SliderButtonRef
            ref={ref}
            formId={formId}
            buttonText={buttonText}
            position={position}
            styleClass={styleClass}
            customIcon={customIcon}
            hiddenButton={hiddenButton}
          />
        );
      case 'popover':
        return (
          <PopoverRef
            ref={ref}
            formId={formId}
            buttonText={buttonText}
            hiddenButton={hiddenButton}
            closeHandler={onCloseHandler}
            submitHandler={onFormSubmittedHandler}
            buttonColor={buttonColor}
            hiddenFields={hiddenFields}
          />
        );
      case 'sidetab':
        return (
          <SideTabButtonRef
            ref={ref}
            formId={formId}
            buttonText={buttonText}
            buttonColor={buttonColor}
            buttonTextColor={buttonTextColor}
            autoTrigger={autoTrigger}
            customIcon={customIcon}
          />
        );
      default:
        return <PopupButtonRef ref={ref} formId={formId} buttonText={buttonText} hiddenButton={hiddenButton} />;
    }
  };

  return <Box>{renderForm()}</Box>;
};

export default TypeformEmbed;
