import React from 'react';
import { Cell, Label, Pie, PieChart, ResponsiveContainer, Text } from 'recharts';
import { Box } from '@mui/material';
import { palette } from '../../../theme/palette';

interface Props {
  data: any[];
  dataKey: string;
  boxHeight?: number | string;
  boxWidth?: number | string;
  chartHeight?: number;
  skillType?: string;
  chartWidth?: number;
  onLinkClick?: (data: any, skillType: string) => void;
  startAngle?: number;
  endAngle?: number;
  Icon?: React.ReactNode;
  tooltipType?: string;
  label?: boolean;
  innerRadius?: number;
  outerRadius?: number;
  totalCount?: number;
  hideTooltip?: boolean;
  hideGoalsLabel?: boolean;
  iconString?: string;
  innerLabel?: string;
  sectorLabel?: string;
  showPointerCursor?: boolean;
  paddingAngle?: number;
}

const styles = {
  averageScoreText: {
    width: '82.14px',
    fontSize: '34px',
    fontWeight: 700,
    fill: palette.customBlack.fontBlack,
    lineHeight: '46.38px',
  },
  totalGoal: {
    width: '82.14px',
    height: '77.45px',
    fontWeight: 800,
    fill: palette.customBlack.fontBlack,
    fontSize: '16px',
  },
  totalGoalText: {
    width: '119.81px',
    height: '24.84px',
    fontWeight: 400,
    fontSize: '14px',
    fill: palette.customBlack.fontBlack,
    letterSpacing: '0.0757853px',
  },
  labelText: {
    width: '65px',
    height: '20px',
    fontSize: '14px',
    maxWidth: '65px',
  },
  imageDimensions: {
    height: 55,
    width: 48,
  },
};

const DonutLabel = ({ viewBox, totalCount, innerLabel, onLinkClick }: any) => {
  const { cx, cy } = viewBox;
  return (
    <g textAnchor="middle" onClick={onLinkClick}>
      {totalCount && (
        <>
          <Text style={styles.averageScoreText} textAnchor="middle" x={cx} y={cy - 10} dy={8}>
            {Number(totalCount).toLocaleString()}
          </Text>

          <Text style={styles.totalGoalText} textAnchor="middle" x={cx} y={cy + 10} dy={8}>
            {innerLabel}
          </Text>
        </>
      )}
    </g>
  );
};

const OverallPerformanceDonutChart = ({
  data,
  dataKey,
  boxHeight = 300,
  boxWidth = 300,
  chartHeight = 300,
  chartWidth = 300,
  onLinkClick,
  startAngle = 210,
  endAngle = -30,
  tooltipType,
  label = false,
  innerRadius = 75,
  outerRadius = 110,
  totalCount,
  hideTooltip = false,
  hideGoalsLabel = false,
  iconString,
  innerLabel,
  sectorLabel,
  skillType,
  showPointerCursor = false,
  paddingAngle = 1,
}: Props) => {
  const conditionalStyles = {
    outlineStyles: {
      '.recharts-wrapper>svg>g': {
        outline: 'none !important',
        cursor: showPointerCursor ? 'pointer' : 'default',
      },
    },
  };

  /**
   * Track the hover on bar and capture the tooltip data
   * @param item - is data for an individual bar.
   * @param active - if the tooltip/hover is active.
   * @param eventObject: {EventData} - Captures the pageX and pageY to render the tooltip.
   * @param actionCreator - specifies if the current hove event is on tooltip or on the bar/cell.
   * */

  const renderCustomizedLabel = ({ midAngle, cx, cy, x, y, studentCount, skillLabel }: any) => {
    const RADIAN = Math.PI / 180;
    const cos = Math.cos(-RADIAN * midAngle);
    const sin = Math.sin(-RADIAN * midAngle);
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;

    return (
      <g>
        {studentCount > 0 && (
          <>
            <Text
              style={styles.totalGoal}
              textAnchor={'middle'}
              x={ex + (cos > 0 ? -5 : 8)}
              y={ey + (sin < 0 ? -10 : 10)}
            >
              {Number(studentCount).toLocaleString()}
            </Text>
            <Text
              style={styles.totalGoalText}
              textAnchor={'middle'}
              x={ex + (cos > 0 ? 2 : -2)}
              y={ey + (sin < 0 ? -10 : 10)}
              dy={18}
            >
              {sectorLabel ? (studentCount === 1 ? sectorLabel.slice(0, -1) : sectorLabel) : skillLabel}
            </Text>
          </>
        )}
      </g>
    );
  };

  return (
    <Box width={boxWidth} height={boxHeight} sx={{ ...conditionalStyles.outlineStyles }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={chartWidth} height={chartHeight}>
          <Pie
            data={data}
            cx={chartWidth / 2}
            cy={chartHeight / 2}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={palette.customWhite.main}
            dataKey={dataKey}
            cornerRadius={4}
            labelLine={label}
            label={renderCustomizedLabel}
            onClick={(data1: any) => {
              onLinkClick?.(data1, skillType || '');
            }}
            minAngle={20}
            paddingAngle={paddingAngle}
          >
            <Label
              content={
                <DonutLabel
                  iconString={iconString}
                  totalCount={totalCount}
                  innerLabel={innerLabel}
                  onLinkClick={() => onLinkClick?.({}, skillType!)}
                />
              }
            />
            {data?.map((item, index) => (
              <Cell style={{ outline: 'none' }} key={index} fill={item.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default OverallPerformanceDonutChart;
