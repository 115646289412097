import React from 'react';
import { Box, Typography } from '@mui/material';
import Layout from '../../../components/Layout';
import VideoTileBox from '../../../components/VideoTileBox';
import AppBreadcrumbs from '../../../components/AppBreadcrumbs';
import useVideos from './videos-hook';
import VideoModal from '../../../components/Header/VideoModal';
import { StyledStickyBreadcrumbBox } from '../TeacherToolbox.styled';

const breadcrumb = [
  {
    label: 'Toolbox',
    route: '/toolbox',
  },
  {
    label: 'Videos',
  },
];

const Videos = () => {
  const { videos, selectedVideo, openVideoModal, closeVideoModal } = useVideos();
  return (
    <Layout>
      <Box display="flex" flexDirection="column" width="100%">
        <StyledStickyBreadcrumbBox>
          <Typography variant="pageTitle">Videos</Typography>
          <AppBreadcrumbs breadcrumb={breadcrumb} />
        </StyledStickyBreadcrumbBox>
        <Box
          mt={3}
          display="grid"
          gridTemplateColumns="repeat(3, 360px)"
          sx={{
            gridGap: 32,
          }}
        >
          {videos.map((video) => (
            <VideoTileBox
              key={video.title}
              title={video.title}
              description={video.description}
              thumbnailURL={video.thumbnailURL}
              onClick={openVideoModal(video)}
            />
          ))}
        </Box>
      </Box>
      {Boolean(selectedVideo) && (
        <VideoModal
          open={Boolean(selectedVideo)}
          onClose={closeVideoModal}
          url={selectedVideo?.videoURL || ''}
          title={selectedVideo?.title}
          showCloseButton
        />
      )}
    </Layout>
  );
};

export default React.memo(Videos);
