import { useEffect, useState } from 'react';
import {
  BenchmarkType,
  SubmissionStatusDataInput,
  useBenchmarkSubmissionStatusQuery,
} from '../../../../generated/graphql';
import { useTeacherBenchmarksDashboardContext } from '../../teacherbenchmarks-dashboard-hook';
import {
  SubmissionStatusColors,
  SubmissionStatuses,
  SubmissionStatusLabels,
  TeacherBenchmarkDashBoard,
} from '../../../../variables/constant';
import { pieChartData } from '../OverallPerformanceWidget/overallperformance.hooks';
import { DashboardWidgetsDataStatusProps } from '../../../AdminAnalytics/admin-analytics-hook';
import { trackEvent } from '../../../../utils/trackevent';
import getErrorMessage from '../../../../utils/getErrorMessage';
import { openSnackbar } from '../../../../components/Notifier';
import { NotifierType } from '../../../../variables/types';

type BenchmarkSubmissionStatusProps = {
  selectedBenchmarkType?: BenchmarkType;
};

type PieChartData = {
  studentCount: number;
  color: string;
  skillLabel: string;
};

const useBenchmarkSubmissionStatus = ({ selectedBenchmarkType }: BenchmarkSubmissionStatusProps) => {
  const { selectedGrades, selectedClasses, selectedSchools, skipGraphQLRequest, updateDashboardWidgetsDataStatus } =
    useTeacherBenchmarksDashboardContext();
  const [submissionPieChartData, setSubmissionPieChartData] = useState<any>();
  const [totalSubmissions, setTotalSubmissions] = useState<number>(0);
  const [hasData, setHasData] = useState<boolean>(false);
  const [showFlyover, setShowFlyover] = useState(false);
  const [flyOverData, setFlyOverData] = useState<pieChartData>();
  const [totalSubmissionsCountByOption, setTotalSubmissionsCountByOption] = useState<number>(0);
  const toggleFlyover = () => {
    if (!showFlyover) {
      trackEvent('tbm_sub_slide', 'submission_status_slide_out');
    }
    setShowFlyover(!showFlyover);
  };
  const openFlyover = (data: any, title: string) => {
    setFlyOverData(data);
    setShowFlyover(!showFlyover);
  };
  const queryInput: SubmissionStatusDataInput = {
    benchmark_type: selectedBenchmarkType!,
    section_ids: selectedClasses || [],
    school_ids: selectedSchools || [],
    grade_ids: selectedGrades || [],
  };

  const {
    data: benchmarkSubmissionData,
    loading: benchmarkSubmissionLoading,
    error: benchmarkSubmissionErr,
  } = useBenchmarkSubmissionStatusQuery({
    fetchPolicy: 'network-only',
    skip: skipGraphQLRequest(),
    variables: { input: queryInput },
  });

  useEffect(() => {
    if (benchmarkSubmissionErr) {
      openSnackbar({ message: getErrorMessage(benchmarkSubmissionErr) }, NotifierType.Error);
    }
    const pieChartSubmissions: PieChartData[] = [];

    if (!benchmarkSubmissionLoading) {
      benchmarkSubmissionData?.submissionStatus?.submission_line_items?.forEach((item) => {
        const submissionStatus = SubmissionStatuses?.[item?.submission_status];
        pieChartSubmissions.push({
          studentCount: item?.student_count,
          skillLabel: SubmissionStatusLabels?.[submissionStatus],
          color: SubmissionStatusColors?.[submissionStatus],
        });
      });
      setSubmissionPieChartData(pieChartSubmissions);
      setTotalSubmissions(benchmarkSubmissionData?.submissionStatus?.total_submissions as number);
      setTotalSubmissionsCountByOption(benchmarkSubmissionData?.submissionStatus?.total_submissions as number);
    }
  }, [benchmarkSubmissionData, benchmarkSubmissionLoading, benchmarkSubmissionErr]);

  useEffect(() => {
    const dashboardWidgetStatus: DashboardWidgetsDataStatusProps = {
      widget: TeacherBenchmarkDashBoard.BenchmarkSummary.SubmissionStatusWidget.name,
      loading: benchmarkSubmissionLoading,
      dataMissing: submissionPieChartData?.length ?? 0 <= 0,
    };

    setHasData(submissionPieChartData?.length > 0);
    updateDashboardWidgetsDataStatus?.({ ...dashboardWidgetStatus });
  }, [submissionPieChartData]);

  return {
    submissionPieChartData,
    benchmarkSubmissionLoading,
    totalSubmissions,
    hasData,
    flyOverData,
    showFlyover,
    toggleFlyover,
    openFlyover,
    totalSubmissionsCountByOption,
    setTotalSubmissionsCountByOption,
  };
};
export default useBenchmarkSubmissionStatus;
