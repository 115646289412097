import { useEffect, useState } from 'react';
import { AdminDashboardFilterInput, useOverallIndicatorScoresWidgetQuery } from '../../../../generated/graphql';
import { AdminDashboards } from '../../../../variables/constant';
import { useDashboardContext } from '../../admin-analytics-hook';
import { getColorsForScore } from '../../../../theme/palette';

interface QueryInputProps {
  district_id?: string;
  school_id?: string;
  input?: AdminDashboardFilterInput;
}

type BarChartLineItem = {
  color: string;
  rubricTitle: string;
  rubricScoreAverage: number;
};

export const useOverallIndicatorScores = () => {
  const {
    isDistrictAdmin,
    myDistrictData,
    isSchoolAdmin,
    mySchoolData,
    skipGraphQLRequest,
    analyticsFilters: queryFilters,
    updateDashboardWidgetsDataStatus,
  } = useDashboardContext();

  const [hasData, setHasData] = useState(false);
  const queryInput: QueryInputProps = {
    input: queryFilters,
  };
  if (isSchoolAdmin && mySchoolData?.id) queryInput.school_id = mySchoolData?.id;
  if (isDistrictAdmin && myDistrictData) queryInput.district_id = myDistrictData?.id;
  const [barData, setBarData] = useState<BarChartLineItem[] | undefined>();

  const { data, loading } = useOverallIndicatorScoresWidgetQuery({
    fetchPolicy: 'network-only',
    skip: skipGraphQLRequest(),
    variables: { ...queryInput },
  });

  useEffect(() => {
    const barData = data?.adminPerformanceOverallAssignmentIndicator.rubric_line_items.map((lineItem) => ({
      color: getColorsForScore(lineItem.rubric_score_average).background!,
      rubricTitle: lineItem.rubric_title,
      rubricScoreAverage: lineItem.rubric_score_average,
    }));
    setBarData(barData);

    const dataMissing = barData === undefined || barData.length === 0;
    setHasData(!dataMissing);
    updateDashboardWidgetsDataStatus?.({
      widget: AdminDashboards.performance.overallIndicatorScoresWidget.name,
      loading: loading,
      dataMissing: dataMissing,
    });
  }, [loading, data]);

  return { barData, hasData, loading };
};
