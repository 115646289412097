import { SectionCategory, useClassesFilterQuery } from '../../generated/graphql';
import { useEffect, useState } from 'react';
import { FetchNFirstRecords } from '../../variables/constant';

interface Props {
  stateId?: string;
  districtId?: string;
  schoolId?: string | string[];
  teacherId?: string | string[];
  classTypes?: SectionCategory[];
}

const classVariables = ({ stateId, districtId, schoolId, teacherId, classTypes }: Props) => {
  let variables = {};
  if (stateId) {
    variables = { ...variables, stateID: stateId as string };
  }
  if (districtId) {
    variables = { ...variables, districtID: districtId as string };
  }
  if (schoolId) {
    variables = {
      ...variables,
      schoolIDs: typeof schoolId === 'string' ? ([schoolId] as [string]) : schoolId,
    };
  }
  if (teacherId) {
    variables = {
      ...variables,
      teacherID: typeof teacherId === 'string' ? ([teacherId] as [string]) : teacherId,
    };
  }
  if (classTypes) {
    variables = { ...variables, classTypes };
  }
  return variables;
};

const useClassesFilter = ({ stateId, districtId, schoolId, teacherId, classTypes }: Props) => {
  const [page, setPage] = useState(1);
  const [sections, setSections] = useState<any[]>([]);
  const [searchByName, setSearch] = useState<any>(undefined);

  const { data, loading } = useClassesFilterQuery({
    fetchPolicy: 'network-only',
    variables: {
      ...classVariables({
        stateId,
        districtId,
        schoolId,
        teacherId,
        classTypes,
      }),
      page,
      limit: FetchNFirstRecords,
      searchByName,
    },
  });

  useEffect(() => {
    if (!loading) {
      if (data?.sectionsFilterList?.nodes) {
        setSections(data?.sectionsFilterList?.nodes);
      }
    }
  }, [data, loading]);

  return {
    sections: sections ?? [],
    loading,
    setPage,
    setSearch,
    searchByName,
  };
};

export default useClassesFilter;
