import React from 'react';
import { Table, TableRow, TableBody, Box, TableCell, CircularProgress, TableHead, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import {
  StyledTableCellFlyover,
  StyledTableContainerFlyover,
  tableWithNoDataStyles,
} from '../../../../components/Common/Common.styled';
import NoResults from '../../../../components/NoResults';
import WavingLadyFrame from '../../../../components/Icons/WavingLadyFrame';
import BenchmarkStatus from '../../../../components/BenchmarkStatus';
import MarkCompleteIcon from '../../../../components/Icons/MarkCompleteIcon';
import { SubmissionStatuses, MAX_CHARS_SUBMITTED_STUDENT_NAME } from '../../../../variables/constant';
import { SubmissionStatus } from '../../../../generated/graphql';
import usePTStudentSlideout from './pt-student-slideout-hook';
import useSubmissionQueueHook from '../../../ScoreTask/submission-queue-hook';
import TruncateStudentName from '../../../AdminBenchmarks/List/StudentListSlideout/TruncateStudentName';

interface Props {
  assignId: string;
  setStudentCount: Function;
}

const Submitted = ({ assignId, setStudentCount }: Props) => {
  const history = useHistory();
  const { totalCount, slideoutStudents, loading } = usePTStudentSlideout({
    assignId: assignId,
    statusList: [SubmissionStatus.Submitted, SubmissionStatus.Reviewed],
  });
  setStudentCount(totalCount);

  const isTableEmpty = !loading && slideoutStudents?.length === 0;

  const { initializeSubmissionQueue } = useSubmissionQueueHook(false);

  const handleRedirection = (submissionID: string, status: string) => () => {
    const submissionQueueIDs: any[] =
      slideoutStudents
        ?.filter((student) => student.submission_status === status)
        ?.map((student) => student.submission_id) ?? [];
    initializeSubmissionQueue(submissionQueueIDs, submissionID);
    if (submissionID)
      history.push({
        pathname: `/tasks/${submissionID}/score/Speaking`,
        state: 'ptList',
      });
  };

  return (
    <Box>
      <StyledTableContainerFlyover>
        <Table
          stickyHeader
          aria-label="students submissions table"
          sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData) }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="right" colSpan={2}>
                <Typography variant="secondaryLabel" fontWeight="bold">
                  Assignment Graded
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {slideoutStudents &&
              slideoutStudents?.map((student) => {
                return (
                  <TableRow key={student.student_number}>
                    <StyledTableCellFlyover>
                      <TruncateStudentName
                        student={student}
                        maxChars={MAX_CHARS_SUBMITTED_STUDENT_NAME}
                        typographySx={{ color: 'primary.main', cursor: 'pointer' }}
                        handleRedirection={handleRedirection}
                      />
                    </StyledTableCellFlyover>
                    <StyledTableCellFlyover sx={{ textAlign: 'right' }}>
                      {student.submission_status === SubmissionStatuses.Reviewed && (
                        <BenchmarkStatus
                          iconPresent
                          abbreviation={<MarkCompleteIcon />}
                          tileStatus={SubmissionStatuses.Reviewed}
                        />
                      )}
                    </StyledTableCellFlyover>
                  </TableRow>
                );
              })}

            {isTableEmpty && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <NoResults
                    label="No Students"
                    description="No students at this step yet"
                    svgComponent={<WavingLadyFrame />}
                  />
                </TableCell>
              </TableRow>
            )}
            {loading && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress size={28} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </StyledTableContainerFlyover>
    </Box>
  );
};

export default Submitted;
