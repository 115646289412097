import React from 'react';
import { AdminDashboards } from '../../../../variables/constant';
import useStudentCompletionRate from './student-completion-rate-hook';
import AppTooltip from '../../../../components/AppTooltip';
import StyledCard from '../../../../components/StyledCard';
import StudentCompletionDonutChart from '../../../../components/AdminGraphs/StudentCompletionDonutChart';
import { Box, SxProps, Typography } from '@mui/material';
import { palette } from '../../../../theme/palette';
import { PerformanceWideStudentCompletionRateBar } from '../../../../generated/graphql';
import CustomTooltip from './CustomTooltip';
import { formatSessionDate } from '../../../../components/AdminGraphs/CommonFunctions';
import SimpleBarChart from '../../../../components/AdminGraphs/SimpleBarChart';

interface Props {
  enableTooltip?: boolean;
  alignTitle?: boolean;
}

const StudentCompletionRateWidget = ({ enableTooltip = true, alignTitle = true }: Props) => {
  const {
    studentCompletionRateLoading,
    studentCompletionRateData,
    title,
    pieValue,
    hasData,
    currStartDate,
    currEndDate,
  } = useStudentCompletionRate();

  const processData = (data: PerformanceWideStudentCompletionRateBar[]) => {
    return data?.map((eventData: PerformanceWideStudentCompletionRateBar) => ({
      ...eventData,
      remainingUnits: Math.abs(eventData?.pts_assigned! - eventData?.pts_submitted_and_graded!),
    }));
  };

  const processedData = studentCompletionRateData && processData(studentCompletionRateData);

  return (
    <Box id={AdminDashboards.performance.studentCompletionRatesWidget.name}>
      <StyledCard
        title={title}
        columnSetting
        isToolTip={enableTooltip}
        titleAlignment={alignTitle ? 'left' : 'center'}
        toolTipComponent={
          <AppTooltip toolTipText={AdminDashboards.performance.studentCompletionRatesWidget.tooltipText} />
        }
        contentAlignment
      >
        {!studentCompletionRateLoading && (
          <Typography
            component="p"
            variant="secondaryLabel"
            sx={{ lineHeight: '31px', color: palette.customBlack.dimmedBlack } as SxProps}
          >
            {formatSessionDate(currStartDate!)} - {formatSessionDate(currEndDate!)}
          </Typography>
        )}
        <Box display="flex">
          <Box width="80%">
            <SimpleBarChart
              barData={processedData}
              loading={studentCompletionRateLoading}
              primaryLegend="Assignments Created"
              secondaryLegend="Assignments Completed"
              showData={hasData! > 0}
              blueBar="pts_submitted_and_graded"
              greyBar="pts_assigned"
              toolTipComponent={<CustomTooltip />}
            />
          </Box>
          <Box>
            <StudentCompletionDonutChart loading={studentCompletionRateLoading} pieChartValue={pieValue} />
          </Box>
        </Box>
      </StyledCard>
    </Box>
  );
};

export default StudentCompletionRateWidget;
