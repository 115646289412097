import { Box, Button, styled } from '@mui/material';
import { palette } from '../../theme/palette';

export const StyledNavigationButton = styled(Button)({
  borderWidth: 0,
  borderRadius: 0,
  padding: '6px 8px',
  marginTop: 5,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:active': {
    borderWidth: 0,
    borderRadius: 0,
  },
  '&::after': {
    display: 'none',
  },
});

export const StyledNavigationButtonBox = styled(Box)<{ active?: string }>(({ theme, active }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: 8,
  border: '2px solid transparent',
  borderRadius: 12,
  fontSize: '18px',
  lineHeight: '25px',
  fontWeight: 600,
  textTransform: 'capitalize',
  color: active === 'true' ? theme.palette.customWhite.main : theme.palette.customBlack.lightBlack,
  boxSizing: active === 'true' ? 'border-box' : undefined,
  backgroundColor: active === 'true' ? theme.palette.primary.main : 'inherit',
}));

export const superAdminOptionStyles = {
  active: {
    color: palette.customWhite.main!,
    boxSizing: 'border-box',
    backgroundColor: palette.customBlue.primaryBlue!,
  },
  buttonBox: {
    display: 'grid !important',
    gridTemplateColumns: '20px 1fr 80px',
    justifyItems: 'flex-start',
    gridGap: 8,
  },
  buttonGridBox: {
    gridTemplateColumns: '20px 1fr 35px',
  },
  buttonSubNavBox: {
    display: 'grid !important',
    gridTemplateColumns: '20px 1fr 80px',
    justifyItems: 'flex-start',
    gridGap: 8,
  },
  buttonSubNavActive: {
    color: palette.customBlue.primaryBlue,
    boxSizing: 'border-box',
    backgroundColor: palette.grey?.A400,
  },
};
