import React from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { palette } from '../../theme/palette';
import { ImitatationUserType } from '../../variables/types';
import { useImitationProvider } from '../../core/imitationContext';
import useTeacher from '../../pages/Teachers/Teacher/teacher-hook';
import useStudent from '../../pages/AdminStudents/Student/student-hook';
import useRole from '../../hooks/useRole';
import { useDistrictAdminQuery, useSchoolAdminQuery } from '../../generated/graphql';

const styles = {
  imitationMessage: {
    fontSize: '18px',
    textDecoration: 'none',
    fontWeight: 700,
    color: palette.customWhite.main,
    minHeight: '60px',
    backgroundColor: palette.customBlue.primaryBlue,
  },
  logoutBtn: {
    '&.MuiButtonBase-root': {
      padding: '8px 16px',
      marginLeft: '24px',
    },
  },
};

interface BannerProps {
  imitationId?: string;
}

const TeacherBanner = ({ imitationId }: BannerProps) => {
  const { teacher, loading } = useTeacher({ teacherId: imitationId });

  return (
    <span>
      {!loading &&
        `Logged in as ${teacher?.first_name || ''} ${teacher?.last_name || ''}, ${teacher?.job_title || ''},
        at ${teacher?.district || ''}, ${teacher?.state_code || ''}`}
    </span>
  );
};

const StudentBanner = ({ imitationId }: BannerProps) => {
  const { student, loading } = useStudent({ studentId: imitationId });

  return (
    <span>
      {!loading &&
        `Logged in as ${student?.first_name || ''} ${student?.last_name || ''}, Student at
        ${student?.school_name || ''}`}
    </span>
  );
};

const AdminBanner = ({ imitationId }: BannerProps) => {
  const { isSchoolAdmin, isDistrictAdmin } = useRole();

  const useQuery: any = isSchoolAdmin ? useSchoolAdminQuery : useDistrictAdminQuery;

  const { data, loading } = useQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: imitationId!,
    },
  });
  const adminDetails: any = isDistrictAdmin ? data?.districtAdmin : isSchoolAdmin ? data?.schoolAdmin : undefined;

  return (
    <span>
      {!loading &&
        `Logged in as ${adminDetails?.first_name || ''} ${adminDetails?.last_name || ''}
        at ${isSchoolAdmin ? adminDetails?.schoolData[0]?.name : ''} ${adminDetails?.districtData?.name || ''},
        ${adminDetails?.districtData?.state?.state_id || ''}`}
    </span>
  );
};

export const ImitationBanner = React.memo(() => {
  const { getImitationUserId, getImitationUserType, getImitationId, handleImitationLogout } = useImitationProvider();
  const imitationUserId = getImitationUserId();
  const imitationUserType = getImitationUserType();
  const imitationId = getImitationId();

  function renderRoleAppropriateBanner(imitationUserType: string) {
    switch (imitationUserType) {
      case ImitatationUserType.Teacher:
        return <TeacherBanner imitationId={imitationId} />;
      case ImitatationUserType.Student:
        return <StudentBanner imitationId={imitationId} />;
      case ImitatationUserType.DistrictAdmin:
        return <AdminBanner imitationId={imitationId} />;
      case ImitatationUserType.SchoolAdmin:
        return <AdminBanner imitationId={imitationId} />;
      default:
        return '';
    }
  }

  return (
    <Box sx={{ ...styles.imitationMessage }} display="flex" justifyContent="center" alignItems="center">
      {imitationUserId && imitationUserType && imitationId && renderRoleAppropriateBanner(imitationUserType)}
      {imitationUserId && imitationUserType && imitationId && (
        <Button variant="outlined" color="primary" onClick={handleImitationLogout} sx={{ ...styles.logoutBtn }}>
          Log out
        </Button>
      )}
    </Box>
  );
});
