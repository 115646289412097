import React, { useState } from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  CircularProgress,
  Box,
  Typography,
  SxProps,
  Tooltip,
  Popover,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { StyledContainerRoot } from '../../Assignments/AssignmentsTable/List.styled';
import { AdminStudentsSort, PaginationProps } from '../../../variables/types';
import CustomPagination from '../../../components/CustomPagination';
import { ROWS_PER_PAGE } from '../../../variables/constant';
import NoResults from '../../../components/NoResults';
import WavingLadyFrame from '../../../components/Icons/WavingLadyFrame';
import {
  OrderBy,
  StudentDetailsFragmentFragment,
  TeacherInfo, useEvaluateFlagQuery,
} from '../../../generated/graphql';
import ArrowDownIcon from '../../../components/Icons/ArrowDownIcon';
import SuccessIcon from '../../../components/Icons/SuccessIcon';
import {
  StyledTableCell,
  StyledTableDataCell,
  StyledTableDataCellWidth10,
  StyledTableDataColumn,
} from '../../Admin/admin-tables-styled';
import {
  StyledTableSort,
  tableWithNoDataStyles,
} from '../../../components/Common/Common.styled';
import useRole from '../../../hooks/useRole';
import { palette } from '../../../theme/palette';
interface Props {
  isTableEmpty: boolean;
  pagination: PaginationProps,
  loading: boolean,
  totalCount: number,
  sort: AdminStudentsSort,
  order: OrderBy,
  students?: StudentDetailsFragmentFragment[],
  handlePageChange: (_: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>) => void | undefined,
  handleSort: ((column: AdminStudentsSort) => void) | Function,
  marginTop?: number,
  sxProps?: SxProps,
  rowsPerPage: number,
  setRowsPerPage: Function,
  isSourceId: boolean
}

const List = ({
  isTableEmpty,
  pagination,
  loading,
  totalCount,
  sort,
  order,
  handlePageChange,
  students,
  handleSort,
  marginTop = 2, sxProps,
  rowsPerPage,
  setRowsPerPage,
  isSourceId,
}: Props) => {
  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';
  const { isSchoolAdmin, isDistrictAdmin } = useRole();
  const [anchorEls, setAnchorEls] = useState<Record<string, HTMLElement | null>>({});
  const studentsDistrictIdValDefault = false;
  const { data: studentsDistrictIdVal } = useEvaluateFlagQuery({
    fetchPolicy: 'network-only',
    variables: { input: { defaultValue: studentsDistrictIdValDefault, flagKey: 'students-district-id-ui' } },
  });
  const showStudentsDistrictId =
    studentsDistrictIdVal?.evaluateFlag.on ?? studentsDistrictIdValDefault;

  const handlePopoverOpen = (event: React.MouseEvent, rowId: string) => {
    setAnchorEls((prevAnchorEls) => ({
      ...prevAnchorEls,
      [rowId]: event.currentTarget as HTMLElement,
    }));
  };

  const handlePopoverClose = (rowId: string) => {
    setAnchorEls((prevAnchorEls) => {
      const updatedAnchorEls = { ...prevAnchorEls };
      delete updatedAnchorEls[rowId];
      return updatedAnchorEls;
    });
  };
  const sortColumn = (title: string, sortType: AdminStudentsSort) => (
    <StyledTableSort
      direction={orderByIcon}
      onClick={handleSort(sortType)}
      active={sort === sortType}
      IconComponent={ArrowDownIcon}
    >
      { title }
    </StyledTableSort>
  );

  const getTeacher = (teachers?: Array<TeacherInfo | undefined | null>) => {
    teachers?.sort((t1, t2) => Number(!t1?.primary_teacher) - Number(!t2?.primary_teacher));
    return (teachers && teachers?.length > 0 ? `${teachers?.[0]?.teacher_name} ${teachers?.length > 1 ? `+${teachers?.length - 1}` : ''}` : '-');
  };

  return (
    <Box
      display="grid"
      width="100%"
      mt={marginTop}
    >
      <Box sx={{ overflowX: 'auto' }} >
        <StyledContainerRoot sx={{ ...(sxProps ?? {}), ...({ minWidth: 700 }) }}>
          <Table
            stickyHeader
            aria-label="students assignments table"
            sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData) }}
          >
            <TableHead>
              <TableRow>
                <StyledTableDataCell sx={{ textAlign: 'left' }}>
                  { sortColumn('Student', AdminStudentsSort.FirstName) }
                </StyledTableDataCell>
                <StyledTableDataCell>
                  { showStudentsDistrictId? sortColumn('Username', AdminStudentsSort.StudentID): sortColumn('Student Id', AdminStudentsSort.StudentID) }
                </StyledTableDataCell>
                {isSourceId && <StyledTableDataCell>
                  Source ID
                </StyledTableDataCell>}
                {showStudentsDistrictId && <StyledTableDataCell>
                  Student's District ID
                </StyledTableDataCell>}
                <StyledTableDataCellWidth10>
                  Active PT
                </StyledTableDataCellWidth10>
                <StyledTableDataCellWidth10>
                  Teacher
                </StyledTableDataCellWidth10>
                <StyledTableDataCell>
                  { sortColumn('Grades', AdminStudentsSort.Grade) }
                </StyledTableDataCell>
                {!showStudentsDistrictId && <StyledTableCell sx={{ width: '25%' }}>
                  { sortColumn('School', AdminStudentsSort.School) }
                </StyledTableCell>}
                {!showStudentsDistrictId && <StyledTableDataCell>
                  { sortColumn('District', AdminStudentsSort.District) }
                </StyledTableDataCell>}
                <StyledTableDataCellWidth10>
                  { sortColumn('Source', AdminStudentsSort.Source) }
                </StyledTableDataCellWidth10>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && students?.map((row: StudentDetailsFragmentFragment) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Typography
                      component={Link}
                      variant='redirectionLinks'
                      to={`/admin-students/${row.id}`}
                    >
                      {`${row?.first_name ? row?.first_name : ''} ${row?.last_name ? row?.last_name : ''}`}
                    </Typography>
                  </TableCell>
                  <StyledTableDataColumn>
                    {row?.student_id || '-'}
                  </StyledTableDataColumn>
                  {isSourceId && <StyledTableDataColumn>
                    { row?.source_id }
                  </StyledTableDataColumn>}
                  {showStudentsDistrictId && <StyledTableDataColumn>
                    {row?.students_district_id || '-'}
                  </StyledTableDataColumn>}
                  <StyledTableDataColumn>
                    {row?.is_active_submission ? <SuccessIcon /> : '-'}
                  </StyledTableDataColumn>
                  {row.teachers && row?.teachers?.length > 1 ?
                    <StyledTableDataColumn >
                      <Box  >
                        <Typography component="span" variant='redirectionLinks' onMouseOver={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => handlePopoverOpen(event, row?.id)} >
                          {getTeacher(row?.teachers)}
                        </Typography>
                        <Popover
                          anchorEl={anchorEls[row?.id]}
                          open={Boolean(anchorEls[row?.id])}
                          onClose={() => handlePopoverClose(row?.id)}
                          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                          transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                          sx={{
                            '&.MuiPopover-root .MuiPopover-paper': {
                              borderRadius: '8px',
                              border: '2px solid',
                              borderColor: palette.customGrey.adminButtonBorder,
                              padding: '8px',
                              maxHeight: '200px',
                            },
                          }}
                        >
                          <Box>
                            {row?.teachers?.map((teacher, i) =>
                              <Box padding={'8px'}   >
                                <Typography
                                  key={i * Math.random()}
                                  component={Link}
                                  variant='redirectionLinks'
                                  sx={{ mb: '6px' }}
                                  to={`/teachers/${teacher?.id}`}
                                  onClick={() => handlePopoverClose(row?.id)}
                                >
                                  {teacher?.primary_teacher && '*'}
                                  {teacher?.teacher_name}
                                </Typography>
                              </Box>)}
                          </Box>
                        </Popover>
                      </Box>
                    </StyledTableDataColumn>
                    :
                    <StyledTableDataColumn>
                      <Typography
                        component={Link}
                        variant='redirectionLinks'
                        to={`/teachers/${row?.teachers[0]?.id}`}
                      >
                        {getTeacher(row?.teachers)}
                      </Typography>
                    </StyledTableDataColumn>
                  }
                  <StyledTableDataColumn>
                    {row?.grade || '-'}
                  </StyledTableDataColumn>
                  {!showStudentsDistrictId && <StyledTableDataColumn sx={{ fontWeight: 800 }}>
                    <Typography
                      component={Link}
                      variant='redirectionLinks'
                      to={isSchoolAdmin ? '/schools/my-school' : `/schools/${row?.school_id}`}
                    >
                      {row?.school_name || '-'}
                    </Typography>
                  </StyledTableDataColumn>}
                  {!showStudentsDistrictId && <StyledTableDataColumn>
                    <Typography
                      component={Link}
                      variant='redirectionLinks'
                      to={isDistrictAdmin || isSchoolAdmin ? '/districts/my-district' : `/districts/${row?.district_id}`}
                    >
                      {row?.district_name || '-'}
                    </Typography>

                  </StyledTableDataColumn>}
                  <StyledTableDataColumn>
                    <Typography variant='capitalized'>
                      {row?.source}
                    </Typography>
                  </StyledTableDataColumn>
                </TableRow>
              ))}
              {isTableEmpty && (
                <TableRow>
                  <TableCell
                    colSpan={7}
                    align="center"
                  >
                    <NoResults
                      label="No Students"
                      description="There has yet to be a student created."
                      svgComponent={<WavingLadyFrame />}
                    />
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={7}
                    align="center"
                  >
                    <CircularProgress size={28} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledContainerRoot>
      </Box>
      <CustomPagination
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={pagination.page}
        onChange={handlePageChange}
        setRowsPerPage={setRowsPerPage}
      />
    </Box>
  );
};
export default List;
